import './articles.scss';
import React, { useState } from 'react';
import { Routes, Route, useLocation, useNavigate } from 'react-router-dom';

import Article from './article/Article';
import Work from './work/Work';
import ArticlesCollection from './collection/ArticleCollection';

export default function Articles() {
  const location = useLocation();
  const navigate = useNavigate();

  const [breadCrumbs, setBreadCrumbs] = useState([
    {
      name: 'Nyheter',
      href: '/nyheter',
    },
  ]);

  return (
    <div className="page-container articles-wrapper">
      <div className="articles-page">
        <nav>
          <ul>
            {breadCrumbs.map((crumb, index) => {
              return (
                <>
                  <li
                    key={index}
                    className={
                      location.pathname === crumb.href &&
                      'articles_crumb--active'
                    }
                    onClick={() =>
                      location.pathname !== crumb.href && navigate(crumb.href)
                    }
                  >
                    {crumb.name.slice(0, 27)}
                    {crumb.name.length > 27 && '...'}
                  </li>
                  {index < breadCrumbs.length - 1 && '/'}
                </>
              );
            })}
          </ul>
        </nav>
        <div className="articles_container">
          <Routes>
            <Route
              path="/"
              element={<ArticlesCollection setBreadCrumbs={setBreadCrumbs} />}
            />
            <Route
              path="/jobba"
              element={<Work setBreadCrumbs={setBreadCrumbs} />}
            />
            <Route
              path="/:id"
              element={<Article setBreadCrumbs={setBreadCrumbs} />}
            />
          </Routes>
        </div>
      </div>
    </div>
  );
}
