export function variableMapper(url, variable, value) {
  return url.replace(`:${variable}`, value);
}

export function manyVariableMapper(url, variables, values) {
  let mappedUrl = url;
  variables.forEach((variable, index) => {
    mappedUrl = mappedUrl.replace(`:${variable}`, values[index]);
  });
  return mappedUrl;
}

export const scrollTo = (id) => {
  const target = document.querySelector(`${id}`);
  if (target) {
    target.scrollIntoView({ behavior: 'smooth', block: 'center' });
  }
};

export const getPaginationSiblings = (width) => {
  if (width < 375) {
    return 0;
  } else if (width < 550) {
    return 0;
  } else if (width < 750) {
    return 0;
  } else {
    return 1;
  }
};
