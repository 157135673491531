import React, {
  useEffect,
  useState,
  useReducer,
  useContext,
  useRef,
  createContext,
  useMemo,
} from 'react';
import { useLocation } from 'react-router-dom';
import { motion, AnimatePresence } from 'framer-motion';

import MobileNavDrawer from '../components/drawers/mobile-nav-drawer/MobileNavDrawer';

const MotionMobileNavDrawer = motion(MobileNavDrawer, {
  forwardMotionProps: true,
});

const overlay = {
  open: {
    right: '0%',
    transition: { transistion: 'easeInOut' },
  },
  closed: {
    right: '-100%',
    transition: { transistion: 'easeInOut' },
  },
};

const defaultDrawer = {
  drawerIsOpen: false,
  drawerName: null,
  options: null,
  requestClose: null,
};

const DrawerContext = React.createContext();

export const useDrawer = () => {
  const { state, dispatch } = useContext(DrawerContext);
  // console.log(state);
  return {
    ...state,
    openDrawer: (drawerName, options) => {
      dispatch({
        type: 'OPEN_DRAWER',
        payload: {
          drawerName,
          options,
        },
      });
    },
    // closeDrawer: (drawerName, options) => {
    //   dispatch({
    //     type: 'CLOSE_DRAWER',
    //     payload: {
    //       drawerName,
    //       options,
    //     },
    //   });
    // },
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_DRAWER':
      return {
        ...state,
        drawerIsOpen: action.payload.drawerName,
        drawerName: action.payload.drawerName,
        options: action.payload.options,
      };
    default:
      throw new Error();
  }
};

const DrawerProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultDrawer);
  const location = useLocation();
  const drawerIsOpen = useMemo(() => (state.drawerIsOpen ? true : false));

  //Close the drawer if the url changes
  useEffect(() => {
    dispatch({
      type: 'OPEN_DRAWER',
      payload: {
        drawerName: null,
        options: null,
      },
    });
  }, [location]);

  //On drawer name change
  useEffect(() => {
    if (state.drawerName) {
      //Scrolling
      document.documentElement.style.overflow = 'hidden';
    } else {
      //Scrolling
      document.documentElement.style.overflow = 'initial';
    }
  }, [state.drawerName]);

  const drawerRef = useRef();

  // Helper functions
  function closeDrawer() {
    dispatch({
      type: 'OPEN_DRAWER',
      payload: {
        drawerName: null,
        options: null,
      },
    });
  }

  function openDrawer(drawerName, options) {
    dispatch({
      type: 'OPEN_DRAWER',
      payload: {
        drawerName: drawerName,
        options: options,
      },
    });
  }

  return (
    <DrawerContext.Provider value={{ state, dispatch }}>
      {children}

      <AnimatePresence mode="wait">
        {drawerIsOpen && (
          <motion.div
            className="drawer-overlay"
            variants={{
              hidden: { opacity: 0 },
              transition: { transistion: 'easeInOut' },
            }}
          >
            <div
              className="drawer-background-click"
              onClick={() => closeDrawer()}
            ></div>
            <MotionMobileNavDrawer
              variants={overlay}
              initial={'closed'}
              animate={'open'}
              exit={'closed'}
              openDrawer={openDrawer}
              closeDrawer={closeDrawer}
              {...state.options}
            ></MotionMobileNavDrawer>
          </motion.div>
        )}
      </AnimatePresence>
    </DrawerContext.Provider>
  );
};

export default DrawerProvider;
