import http from '../shared/http-common.js';
import { url } from '../../constants/endpoints.js';
import { variableMapper } from '../../utils/utils.js';
import authHeader from '../shared/auth-header.js';

export function getAllProducts() {
  return http.get(url.PRODUCTS_GET_ALL);
}

export function getProduct(id) {
  return http.get(variableMapper(url.PRODUCTS_GET_ONE_BY_ID, 'id', id));
}

export function searchProducts(query, page = 0) {
  const queryParams = {
    ...query,
    page: page,
  };
  return http.get(`/products`, { params: queryParams });
}

export function getAllCustomerProducts(id, page = 0) {
  const queryParams = {
    id: id,
    size: 10,
    page: page,
  };
  return http.get(`/products/customer`, {
    params: { ...queryParams },
    headers: authHeader(),
  });
}

export function getCategories() {
  return http.get('/products/categories');
}

export function getProductSales(id, page = 0) {
  return http.get(`/products/${id}/sales`, {
    params: { page: page },
    headers: authHeader(),
  });
}

export default {
  getAllProducts,
  getProduct,
  searchProducts,
  getAllCustomerProducts,
  getCategories,
  getProductSales,
};
