import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';
import { logout } from '../../../../../api/core/auth';
import useAnalytics from '../../../../../hooks/analytics';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUserCircle } from '@fortawesome/free-solid-svg-icons';
import useLocalPersistStore from '../../../../../store/local-persist.store';

const itemVariants = {
  open: {
    opacity: 1,
    y: 0,
    transition: { type: 'spring', stiffness: 300, damping: 24 },
  },
  closed: { opacity: 0, y: 20, transition: { duration: 0.2 } },
};

function UserButton() {
  const { eventTrackerGA } = useAnalytics({ category: 'Logged in menu' });

  const { userButtonOpen, setUserButtonOpen } = useLocalPersistStore();

  const dropdownRef = useRef();

  useEffect(() => {
    window.addEventListener('click', keyPressed);

    return () => window.removeEventListener('click', keyPressed);
  }, [keyPressed]);

  function keyPressed(e) {
    if (!userButtonOpen) return;
    if (!dropdownRef.current.contains(e.target)) {
      setUserButtonOpen(!userButtonOpen);
    }
  }

  return (
    <motion.div
      className="desktop-nav_login"
      initial={false}
      animate={userButtonOpen ? 'open' : 'closed'}
    >
      <FontAwesomeIcon
        onClick={() => setUserButtonOpen((prev) => !prev)}
        className="desktop-nav_login-icon "
        icon={faUserCircle}
      />
      <motion.ul
        ref={dropdownRef}
        variants={{
          open: {
            clipPath: 'inset(0% 0% 0% 0% round 8px)',
            transition: {
              type: 'spring',
              bounce: 0,
              duration: 0.7,
              delayChildren: 0.3,
              staggerChildren: 0.05,
            },
          },
          closed: {
            clipPath: 'inset(10% 50% 90% 50% round 8px)',
            transition: {
              type: 'spring',
              bounce: 0,
              duration: 0.3,
            },
          },
        }}
        style={{ pointerEvents: userButtonOpen ? 'auto' : 'none' }}
      >
        <motion.li variants={itemVariants}>
          <Link
            to={'/konto/produkter'}
            className=""
            onClick={() => {
              setUserButtonOpen(false);
              eventTrackerGA('Navigerar till inlämningar');
            }}
          >
            Inlämningar
          </Link>{' '}
        </motion.li>
        <motion.li variants={itemVariants}>
          <Link
            to={'/konto/utbetalningar'}
            onClick={() => {
              setUserButtonOpen(false);
              eventTrackerGA('Navigerar till utbetalningar');
            }}
          >
            Utbetalningar
          </Link>
        </motion.li>
        <motion.li
          variants={itemVariants}
          onClick={() => {
            setUserButtonOpen(false);
            eventTrackerGA('Navigerar till service');
          }}
        >
          <Link to={'/konto/verkstad'}>Service</Link>
        </motion.li>
        <motion.li
          variants={itemVariants}
          onClick={() => {
            setUserButtonOpen(false);
            eventTrackerGA('Navigerar till konto');
          }}
        >
          <Link to={'/konto'}>Konto</Link>
        </motion.li>
        <motion.li
          variants={itemVariants}
          onClick={() => {
            setUserButtonOpen(false);
            logout();
            eventTrackerGA('Loggar ut');
          }}
        >
          <button>Logga ut</button>
        </motion.li>
      </motion.ul>
    </motion.div>
  );
}

export default UserButton;
