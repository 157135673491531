import './cookies.scss';
import React, { useState, useEffect } from 'react';
import { motion, AnimatePresence } from 'framer-motion';
import useLocalStore from '../../store/local-persist.store';
import { usePreventBodyScroll } from '../../hooks/use-prevent-body-scroll';
import { usePopup } from '../../hooks/popup';

export function Cookies(props) {
  const { openPopup } = usePopup();

  const [accepted, setAccepted] = useState(false);

  const cookiesAccepted = useLocalStore((state) => state.cookiesAccepted);
  const setCookiesAccepted = useLocalStore((state) => state.setCookiesAccepted);

  const acceptCookies = () => {
    setCookiesAccepted(true);
    setAccepted(true);
  };

  if (cookiesAccepted) {
    if (!accepted) return null;
    else
      setTimeout(() => {
        return null;
      }, 3000);
  }

  return (
    <AnimatePresence mode="wait">
      {!accepted && (
        <motion.div
          className="cookies cookies-overlay"
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.75 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            className="cookies-box"
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.4 }}
            exit={{ opacity: 0, translateY: 50 }}
          >
            <p>
              Vi använder cookies för att ge dig den bästa upplevelsen av vår
              webbplats. Godkänner du att dela dina cookies?
            </p>
            <div className="cookies-box_buttons">
              <button
                onClick={() => acceptCookies()}
                className="btn btn-primary btn--full "
              >
                Acceptera
              </button>
              <button
                onClick={() => openPopup('TermsOfCookies')}
                className="btn btn-functional btn--full"
              >
                Läs mer
              </button>
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default Cookies;
