import './index.scss';
import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import useWindowSize from '../../../hooks/useWindowSize';

import ProductApi from '../../../api/core/product';

import { Icon, Icons } from '../../icon/Icon';
import PaginationMUI from '@mui/material/Pagination';
import { Tooltip } from '@mui/material';

import { getPaginationSiblings } from '../../../utils/utils';
import config from '../../../assets/config';
import { sanitizeProductName } from '../../../utils/format-text';

function ProductSellInfo(props) {
  const { itemId } = props;
  const [height, width] = useWindowSize();
  const paginationSiblings = getPaginationSiblings(width);

  const [page, setPage] = useState(0);
  const [imageError, setImageError] = useState(false);

  const { data: productData, isLoading: productLoading } = useQuery(
    ['product', itemId],
    () =>
      ProductApi.getProduct(itemId)
        .then((res) => res.data)
        .catch((error) => console.log(error)),
  );

  const { data, isLoading } = useQuery(['productSellInfo', itemId, page], () =>
    ProductApi.getProductSales(itemId, page)
      .then((res) => res.data)
      .catch((error) => {
        console.log(error);
      }),
  );

  const handlePaginationChange = async (e, value) => {
    setPage(value - 1);
  };

  if (isLoading && productLoading) {
    return <h2>Laddar</h2>;
  }

  return (
    <div className="popup product-sell-info">
      <button
        className="btn btn-icon popup-btn__close"
        onClick={() => props.closePopup()}
      >
        <Icon icon={Icons.faXmark} color={'#000'}></Icon>
      </button>
      <div className="popup-top flex flex-col xs:flex-row gap-2">
        <div className="popup-img__wrapper ">
          <img
            src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${itemId}.jpg`}
            alt={'Produktbild till ' + productData?.name}
            className="popup-img"
            onError={(e) => {
              setImageError(true);
              e.target.src =
                'https://sbb-bucket.fra1.digitaloceanspaces.com/logos/recycle-logo-new.svg';
              e.target.classList.add('popup-img--error');
            }}
          />
          {imageError && (
            <p className="text-center text-sm text-gray-500">Bild saknas</p>
          )}
        </div>
        <div className="popup-text__wrapper px-2">
          <div className="flex flex-col gap-1  items-start">
            <h1 className="max-w-80 order-2 flex gap-2">
              {sanitizeProductName(productData?.name)}
              {productData?.name.includes('VILLKOR -') && (
                <Tooltip
                  title="Varan har enligt villkor blivit bedömd som ej säljbar"
                  arrow
                  placement="top"
                >
                  <button className="">
                    <Icon icon={Icons.faCircleInfo}></Icon>
                  </button>
                </Tooltip>
              )}
              {productData?.name.includes('ÅTER -') && (
                <Tooltip
                  title="Varan har blivit returnerad och återlämnad"
                  arrow
                  placement="top"
                >
                  <button className="">
                    <Icon icon={Icons.faCircleInfo}></Icon>
                  </button>
                </Tooltip>
              )}
            </h1>
            <span
              className={`user-table_tag order-1 ${
                productData?.quantity > 0
                  ? 'user-table_tag--active'
                  : 'user-table_tag--inactive'
              }`}
            >
              {productData?.quantity > 0 ? 'I lager' : 'Såld'}
            </span>
          </div>
          <div className="flex flex-col gap-1 items-start md:flex-row  md:gap-3 md:items-center">
            <span>{productData?.unit_price}.00 kr</span>

            {productData?.size_colour.length > 0 && (
              <>
                <div className=" dot"></div>
                <span>{productData?.size_colour}</span>
              </>
            )}
            <div className=" dot"></div>
            <span>
              {productData?.stock_date?.substr(0, 10) ?? 'Inget datum'}
            </span>
          </div>
        </div>
      </div>
      <div className="popup-bottom ">
        {data?.items && data.items.length > 0 ? (
          <>
            <div className="user-table_container  ">
              <table className="user-table ">
                <thead>
                  <tr>
                    <th className="user-table_sticky-cell">Säljdatum</th>
                    <th>Antal sålda</th>
                    <th>Utbetalningsdatum</th>
                    <th>Utbetalat</th>
                    <th>Kontonummer</th>
                  </tr>
                </thead>
                <tbody className="">
                  {data.items.map((info) => {
                    const isReturn = info.quantity_purchased < 0;
                    return (
                      <tr>
                        <td className="user-table_sticky-cell">
                          {info.time.sale_time.substr(0, 10)}
                        </td>
                        <td>
                          {isReturn ? (
                            <Tooltip
                              title="Korrigering av total utbetalning"
                              arrow
                              placement="top"
                            >
                              <div className="bg-red-700 px-3 py-1 text-xs rounded-full text-white w-max ">
                                Korrigering
                              </div>
                            </Tooltip>
                          ) : (
                            info.quantity_purchased
                          )}
                        </td>
                        <td>
                          {info.payments?.transaction_date && !isReturn
                            ? info.payments.transaction_date
                            : 'Ej utbetald'}
                        </td>
                        <td>
                          {info.payments?.amount_payed && !isReturn
                            ? `${
                                info.payments.amount_payed *
                                info.quantity_purchased
                              }.00 kr`
                            : '-'}
                        </td>
                        <td>
                          {info.payments?.receiver_account.length === 0 &&
                          !isReturn
                            ? 'Inget konto'
                            : info.payments?.receiver_account.length > 0 &&
                              !isReturn
                            ? info.payments?.receiver_account
                            : '-'}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            {data?.totalPages > 1 && (
              <div className="pagination-container">
                <PaginationMUI
                  count={data.totalPages}
                  variant="outlined"
                  shape="rounded"
                  onChange={handlePaginationChange}
                  siblingCount={paginationSiblings}
                  size={width < 500 ? 'small' : ''}
                  page={page + 1}
                ></PaginationMUI>
              </div>
            )}
          </>
        ) : (
          <div
            style={{ height: '150px' }}
            className="relative flex items-center justify-center flex-col gap-2"
          >
            <img
              src={config.images.recycleLogoBlack}
              alt="img"
              className="max-h-16 opacity-50"
            />
            <p>Denna vara är aldrig såld</p>
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductSellInfo;
