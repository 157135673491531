import './adminheader.scss';
import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { logout } from '../../../../api/core/auth';
import logo from '../../../../assets/logos/sbb-helvit.png';

export default function AdminMobileNav({ showSidebar, toggleMobileSidebar }) {
  const location = useLocation();

  return (
    <div className="admin-mobile-nav min-h-16 flex items-center p-4">
      <nav className="flex w-full items-center gap-4">
        <Link to={'/'}>
          <img className="h-8" src={logo} alt="logo" />
        </Link>
        <ul className="flex gap-2">
          <li
            className={`transition hover:bg-blue-900 rounded-lg py-2 px-3 flex items-center ${
              location.pathname == '/admin' ? '' : ''
            }`}
          >
            <Link to={'/admin'} className={`text-sm `}>
              Dashboard
            </Link>
          </li>
          <li
            className={`transition hover:bg-blue-900 rounded-lg py-2 px-3 flex items-center ${
              location.pathname == '/admin/anvandare' ? '' : ''
            }`}
          >
            <Link to={'/admin/anvandare'} className={`text-sm `}>
              Användare
            </Link>
          </li>
          <li
            className={`transition hover:bg-blue-900 rounded-lg py-2 px-3 flex items-center ${
              location.pathname == '/admin/analytics' ? '' : ''
            }`}
          >
            <Link to={'/admin/analytics'} className={`text-sm `}>
              Analytics
            </Link>
          </li>
        </ul>
        <div className="ml-auto flex">
          <a href="/" className="btn btn-admin ml-auto">
            Till hemsida
          </a>
          <button className="btn btn-admin ml-2" onClick={() => logout()}>
            Logga ut
          </button>
        </div>
      </nav>
    </div>
  );
}
