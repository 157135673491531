import Kontonummer, { valid } from './bankaccount';

export function validateEmail(email) {
  if (email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)) return true;
  return false;
}

export const validateAccount = (account) => {
  try {
    const { sortingCode, accountNumber } = extractSortingAndAccount(account);

    const kontonummer = new Kontonummer(sortingCode, accountNumber, {
      mode: 'strict',
    });

    // If valid, format the account
    const formatted = kontonummer.format('human'); // Example format type

    console.log('The account number is valid!', formatted);
    return true;
  } catch (error) {
    console.log(`Validation failed: ${error}`);
  }
};

function extractSortingAndAccount(input) {
  const cleanedInput = input.replace(/[^\d]/g, ''); // Remove all non-digit characters

  if (cleanedInput.length < 5) {
    throw new Error(
      'Input too short to contain both sorting code and account number',
    );
  }

  const sortingCode = cleanedInput.startsWith('8')
    ? cleanedInput.substring(0, 5) // Swedbank uses 5-digit sorting codes starting with 8
    : cleanedInput.substring(0, 4); // Other banks use 4-digit sorting codes

  const accountNumber = cleanedInput.substring(sortingCode.length);

  return { sortingCode, accountNumber };
}
