import React, { useEffect, useState } from 'react';

import { debounce } from 'lodash';

import { useNotification } from '../../../../../hooks/useNotification';
import { usePopup } from '../../../../../hooks/popup';
import { useQuery } from '@tanstack/react-query';

import { getAllUsers } from '../../../../../api/core/admin';

import PaginationMUI from '@mui/material/Pagination';
import Spinner from '../../../../../components/loaders/spinner/Spinner';

export default function AllUsers() {
  // General
  const dispatch = useNotification();
  const { openPopup } = usePopup();

  // States
  const [searchQueries, setSearchQueries] = useState({
    size: 15,
    query: '',
    page: 0,
  });

  // Data
  const { data: users, isLoading } = useQuery(
    ['users', searchQueries],
    () => getAllUsers(searchQueries).then((res) => res.data),
    {
      onError: (error) => {
        dispatch({
          type: 'ERROR',
          message: error.response.data.message,
        });
      },
    },
  );

  // Helpers
  const handleUserSearch = debounce(async (e) => {
    const newQuery = {
      ...searchQueries,
      [e.target.name]: e.target.value,
    };

    setSearchQueries(newQuery);
  }, 350);

  const handlePaginationChange = (event, value) => {
    const updatedQueries = {
      ...searchQueries,
      page: value - 1,
    };

    setSearchQueries(updatedQueries);
  };

  return (
    <section className="section-container section-admin  admin-allusers">
      <div className="admin-allusers_heading-container">
        <h2>Sök bland alla användare</h2>
      </div>
      <div className="section-admin_heading-container flex-row">
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="input-label-container">
            <input
              type="text"
              name="query"
              id="query"
              value={searchQueries.name}
              placeholder="Namn, epost, telefon eller kundnummer"
              onChange={handleUserSearch}
            />
          </div>
        </form>
        {users && (
          <div className="admin_pagination">
            <div>
              <p>
                Visar{' '}
                {Math.min(users.currentPage === 0 ? 1 : users.currentPage * 15)}{' '}
                - {Math.min((users.currentPage + 1) * 15, users.totalItems)} av{' '}
                {users.totalItems}
              </p>
            </div>
            {users.totalPages > 1 && (
              <PaginationMUI
                count={users.totalPages}
                variant="outlined"
                shape="rounded"
                onChange={handlePaginationChange}
                page={searchQueries.page + 1}
              ></PaginationMUI>
            )}
          </div>
        )}
      </div>

      <div className="user-table_container">
        <table className="admin-table">
          <thead>
            <tr>
              <th className="user-table_sticky-cell">Kundnummer</th>
              <th className="user-table_sticky-cell">Namn</th>
              <th className="user-table_sticky-cell">Epost</th>
              <th>Telefon</th>
              <th>Skapad</th>
            </tr>
          </thead>
          <tbody>
            {users?.items?.length > 0 ? (
              users.items.map((user) => {
                return (
                  <tr
                    key={user.person_id}
                    onClick={() =>
                      openPopup('EditUser', { userId: user.person_id })
                    }
                    className="group cursor-pointer"
                  >
                    <td className="group-hover:bg-gray-200 transition">
                      {user.person_id}
                    </td>
                    <td className="group-hover:bg-gray-200 transition">
                      {user.first_name + ' ' + user.last_name}
                    </td>
                    <td className="admin-table_bold user-table_sticky-cell group-hover:bg-gray-200 transition">
                      {user.email}
                    </td>
                    <td className="group-hover:bg-gray-200 transition">
                      {user.phone_number}
                    </td>
                    <td className="group-hover:bg-gray-200 transition">
                      {user.createdAt.substr(0, 10)}
                    </td>
                  </tr>
                );
              })
            ) : isLoading ? (
              <th className="admin-table_message" colspan="7">
                <div className="min-h-56 flex items-center justify-content">
                  <Spinner></Spinner>
                </div>
              </th>
            ) : (
              <th colspan="7" className="admin-table_message">
                <h3>Ingen användare hittades</h3>
              </th>
            )}
          </tbody>
        </table>
      </div>
    </section>
  );
}
