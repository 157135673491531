import getSortingCodeInfo from './banks.js';
import { KontonummerError } from './errors.js';
import validateCheckDigit, { mod10 } from './validate.js';
import formatter from './format.js';

class Kontonummer {
  constructor(
    sortingCodeWithOrWithoutAccountNumber,
    accountOrOptions,
    optionsArg,
  ) {
    this.bankName = null;
    this.sortingCode = null;
    this.accountNumber = null;
    this.type = null;
    this.comment = null;
    this.valid = false; // Only relevant in `lax` mode

    let accountNumber;
    let options = { mode: 'strict' };

    // Parse parameters
    const input = `${sortingCodeWithOrWithoutAccountNumber}`.replace(
      /[^\d]/g,
      '',
    );

    const sortingCode = input.substring(0, input.startsWith('8') ? 5 : 4);

    if (typeof accountOrOptions === 'object') {
      options = accountOrOptions;
      accountNumber = input.substring(input.startsWith('8') ? 5 : 4);
    } else if (
      typeof accountOrOptions === 'string' ||
      typeof accountOrOptions === 'number'
    ) {
      accountNumber = `${accountOrOptions}`.replace(/[^\d]/g, '');
    } else {
      accountNumber = input.substring(input.startsWith('8') ? 5 : 4);
    }

    if (typeof optionsArg === 'object') {
      options = optionsArg;
    }

    // Validate arguments
    if (
      sortingCode.length < 4 ||
      (sortingCode.length > 4 && !mod10(sortingCode))
    ) {
      throw new KontonummerError('Invalid sorting code');
    }

    if (accountNumber.length < 2) {
      throw new KontonummerError('Invalid account number');
    }

    const bank = Kontonummer.getSortingCodeInfo(sortingCode);

    const isValid = validateCheckDigit(
      bank.type,
      bank.comment,
      sortingCode,
      accountNumber,
    );

    if (!isValid && options.mode === 'strict')
      throw new KontonummerError('Invalid account number');
    if (!isValid && bank.type === 1 && options.mode === 'semi')
      throw new KontonummerError('Invalid account number');

    this.bankName = bank.bankName;
    this.type = bank.type;
    this.comment = bank.comment;
    this.sortingCode = sortingCode;
    this.accountNumber = accountNumber;
    this.valid = isValid;
  }

  format(format) {
    return formatter(
      this.sortingCode,
      this.accountNumber,
      Kontonummer.getSortingCodeInfo(this.sortingCode),
      format,
    );
  }

  static parse(
    sortingCodeWithOrWithoutAccountNumber,
    accountOrOptions,
    options,
  ) {
    if (
      typeof accountOrOptions === 'string' ||
      typeof accountOrOptions === 'number'
    ) {
      return new Kontonummer(
        sortingCodeWithOrWithoutAccountNumber,
        accountOrOptions,
        options,
      );
    }
    return new Kontonummer(
      sortingCodeWithOrWithoutAccountNumber,
      accountOrOptions,
    );
  }

  static valid(sortingCodeWithOrWithoutAccountNumber, accountNumber) {
    if (
      accountNumber &&
      typeof accountNumber !== 'string' &&
      typeof accountNumber !== 'number'
    ) {
      throw new KontonummerError(
        'Kontonummer.valid() does not accept an options argument',
      );
    }
    try {
      if (accountNumber) {
        new Kontonummer(sortingCodeWithOrWithoutAccountNumber, accountNumber); // eslint-disable-line no-new
      } else {
        new Kontonummer(sortingCodeWithOrWithoutAccountNumber); // eslint-disable-line no-new
      }
      return true;
    } catch {
      return false;
    }
  }

  static getSortingCodeInfo(sortingCode) {
    const bank = getSortingCodeInfo(sortingCode);
    if (!bank) {
      throw new KontonummerError(
        `No Bank found with sorting code ${sortingCode}`,
      );
    }
    return bank;
  }

  toJSON() {
    return {
      bankName: this.bankName,
      sortingCode: this.sortingCode,
      accountNumber: this.accountNumber,
      type: this.type,
      comment: this.comment,
      valid: this.valid,
    };
  }

  [Symbol.for('nodejs.util.inspect.custom')]() {
    return this.toJSON();
  }
}

export const parse = Kontonummer.parse;
export const valid = Kontonummer.valid;
export default Kontonummer;
