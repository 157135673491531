export function firstLetterCapital(text) {
  if (typeof text !== 'string') {
    return text;
  }

  return text.substr(0, 1).toUpperCase() + text.substr(1, text.length - 1);
}

export function ellipsisText(text, maxLength) {
  if (typeof text !== 'string') {
    return text;
  }

  if (text.length <= maxLength) {
    return text;
  } else {
    return text.substring(0, maxLength - 3) + '...';
  }
}

export function capitalizeFirstLetter(string) {
  const words = string.split(' ');

  for (let i = 0; i < words.length; i++) {
    if (words[i][0] == undefined) continue;
    words[i] = words[i][0].toUpperCase() + words[i].substr(1).toLowerCase();
  }

  const newString = words.join(' ');

  return newString;
}

export function sanitizeProductName(productName) {
  if (typeof productName !== 'string') {
    return productName;
  }
  return productName.replace('*', '');
  // .replace('VILLKOR', '')
  // .replace('VILLKOR', '')
  // .replace(/[^a-zA-Z0-9\s]/g, '');
}

export const swedishCurrencyFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
});
