import { useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { useNotification } from '../../../../hooks/useNotification';

import { resetPasswordFromRequest } from '../../../../api/core/auth';

export default function ChangePassword({ token }) {
  const dispatch = useNotification();
  const navigate = useNavigate();

  const [loading, setLoading] = useState(false);
  const [password, setPassword] = useState({});

  const handleUpdatePasswordSubmit = async (e) => {
    e.preventDefault();
    if (loading) return;

    setLoading(true);
    try {
      await resetPasswordFromRequest(token, password.password);
      setLoading(false);
      dispatch({
        type: 'SUCCESS',
        message: 'Ditt lösenord har uppdaterats.',
      });
      navigate('/logga-in');
    } catch (error) {
      dispatch({
        type: 'ERROR',
        message: error.response.data.message,
      });
      setLoading(false);
    }
  };

  const handlePasswordChange = (e) => {
    const value = e.target.value;

    setPassword({
      ...password,
      [e.target.name]: value,
    });
  };

  const inputs = [
    {
      id: 1,
      name: 'password',
      type: 'password',
      placeholder: 'Nytt lösenord',
      label: 'Lösenord:',
      required: true,
    },
    {
      id: 2,
      name: 'passwordRepeat',
      type: 'password',
      placeholder: 'Upprepa lösenord',
      label: 'Upprepa lösenord:',
      errorMessage: 'Lösenorden måste vara likadana',
      required: true,
      pattern: password.password ?? '',
    },
  ];

  return (
    <>
      <form className="login-card_form" onSubmit={handleUpdatePasswordSubmit}>
        {inputs.map((input) => {
          return (
            <input
              key={input.id}
              {...input}
              value={password[input.name]}
              onChange={handlePasswordChange}
              className="mb-2"
            />
          );
        })}
        <input
          type="submit"
          value={loading ? 'Återställer...' : 'Återställ lösenord'}
          className="btn btn-secondary btn--full"
        />
      </form>
    </>
  );
}
