import './edit-user.scss';
import React, { useState, forwardRef } from 'react';

import { useMutation, useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { useNotification } from '../../../hooks/useNotification';

import {
  getUserAudit,
  getUserById,
  getUserQuickAnalytics,
} from '../../../api/core/admin';
import { getAllCustomerProducts } from '../../../api/core/product';
import { updateCustomer } from '../../../api/core/user';
import { requestPasswordReset } from '../../../api/core/auth';
import { swedishCurrencyFormatter } from '../../../utils/format-text';
import { validateAccount } from '../../../utils/validation';

import Spinner from '../../loaders/spinner/Spinner';
import XIcon from '../../../assets/icons/close-icon-naked-3.png';
import SkeletonBox from '../../loaders/SkeletonBox';

const registerOptions = {
  email: {
    required: 'Epost är obligatoriskt',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Måste vara en giltig epost',
    },
  },
  phone_number: {
    required: 'Telefon är obligatoriskt',
    pattern: {
      value: /^[0-9]{3}[0-9]{3}[0-9]{4}$/,
      message: 'Måste vara ett giltig telefonnummer',
    },
  },
  first_name: { required: 'Förnamn är obligatoriskt' },
  last_name: { required: 'Efternamn är obligatoriskt' },
  address_1: { required: 'Adress är obligatoriskt' },
  address_2: {},
  city: {},
  state: {},
  zip: {
    required: 'Postnummer är obligatoriskt',
    pattern: {
      value: /^[0-9]{3} ?[0-9]{2}$/,
      message: 'Måste vara ett giltig postnummer',
    },
  },
  country: {},
};

export const PopupEditUser = forwardRef((props, ref) => {
  const { userId, openPopup, closePopup } = props;
  // General
  const dispatch = useNotification();

  // States
  const [hasInventory, setHasInventory] = useState(false);
  const [tabs, setTabs] = useState({
    activeTab: 'history',
    tabs: [
      {
        name: 'history',
        title: 'Tidslinje',
      },
      {
        name: 'actions',
        title: 'Hantera',
      },
      {
        name: 'settings',
        title: 'Redigera',
      },
    ],
  });

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors, isLoading: formIsLoading, isDirty },
    getValues,
  } = useForm({
    mode: 'onBlur',
    defaultValues: () =>
      getUserById(userId).then((res) => {
        console.log(res.data);
        checkUserInventory(res.data.customer.person_id);
        return res.data.customer;
      }),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (values) => updateCustomer(values.person_id, values),
    onSuccess: () => {
      dispatch({
        type: 'SUCCESS',
        message: 'Kontouppgifter sparade',
      });
    },
    onError: () => {
      dispatch({
        type: 'ERROR',
        message: 'Kunde inte spara kontouppgifter',
      });
    },
  });

  const { mutate: mutatePasswordReset } = useMutation({
    mutationFn: (values) => requestPasswordReset(values.person_id),
    onSuccess: () => {
      dispatch({
        type: 'SUCCESS',
        message: 'Lösenordsåterställning skickad',
      });
    },
    onError: () => {
      dispatch({
        type: 'ERROR',
        message: 'Kunde inte skicka lösenordsåterställning',
      });
    },
  });

  const { data: userAudit, isLoading: userAuditLoading } = useQuery(
    ['userAudit', userId],
    () => getUserAudit(userId).then((res) => res.data),
  );

  const { data: userAnalytics, isLoading: userAnalyticsLoading } = useQuery(
    ['userAnalytics', userId],
    () => getUserQuickAnalytics(userId).then((res) => res.data),
  );

  // console.log(userAnalytics);

  async function onSubmit(values) {
    mutate(values);
  }

  function checkUserInventory(customerId) {
    getAllCustomerProducts(customerId)
      .then((data) => {
        // Bank account is required if customer has inventory
        if (data.data.totalItems > 0) {
          setHasInventory(true);
        } else {
          setHasInventory(false);
        }
      })
      .catch((error) => {
        setHasInventory(false);
        console.log(error);
      });
  }

  return (
    <section
      className="popup popup-edit-user min-h-56 "
      key={`edituser-${userId}`}
    >
      <button
        onClick={() => closePopup()}
        className="h-4 w-4 absolute right-4 top-4"
      >
        <img src={XIcon} alt="" srcset="" className="object-contain" />
      </button>
      {formIsLoading ? (
        <SkeletonBox height={32} width={300}></SkeletonBox>
      ) : (
        <h2 className="text-lg xs:text-2xl capitalize">
          {`${getValues('first_name')} ${getValues('last_name')}`}{' '}
          <span className="text-gray-400">({getValues('person_id')})</span>
        </h2>
      )}

      <div className="flex justify-around my-4">
        <div className="grid place-items-center">
          {userAnalyticsLoading ? (
            <SkeletonBox height={28} width={'100%'}></SkeletonBox>
          ) : (
            <p className="font-semibold text-md xs:text-lg">
              {swedishCurrencyFormatter.format(userAnalytics?.totalSpent)}
            </p>
          )}
          <h3 className="text-xs xs:text-base ">Totalt spenderat</h3>
        </div>
        <div className="grid justify-items-center">
          {userAnalyticsLoading ? (
            <SkeletonBox height={28} width={'100%'}></SkeletonBox>
          ) : (
            <p className="font-semibold text-md xs:text-lg">
              {userAnalytics?.totalReceivedItems} st
            </p>
          )}
          <h3 className="text-xs xs:text-base ">Inlämnade varor</h3>
        </div>
        <div className="grid justify-items-center">
          {userAnalyticsLoading ? (
            <SkeletonBox height={28} width={'100%'}></SkeletonBox>
          ) : (
            <p className="font-semibold text-md xs:text-lg">
              {swedishCurrencyFormatter.format(userAnalytics?.totalSales)}
            </p>
          )}
          <h3 className="text-xs xs:text-base ">Totalt utbetalat</h3>
        </div>
      </div>
      <div className="flex ">
        {tabs.tabs.map((tab) => (
          <button
            key={tab.name}
            className={`w-full text-sm xs:text-md relative after:transition after:duration-300	 after:w-full after:bg-gray-300 after:h-0.5 after:absolute after:bottom-0 after:left-0 p-2 ${
              tabs.activeTab === tab.name && 'after:bg-gray-800'
            }`}
            onClick={() => setTabs({ ...tabs, activeTab: tab.name })}
          >
            {tab.title}
          </button>
        ))}
      </div>
      {tabs.activeTab === 'settings' &&
        (formIsLoading ? (
          <div className="my-12">
            <Spinner></Spinner>
          </div>
        ) : (
          <div className="pt-4">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col gap-4 "
            >
              <div className="grid gap-2">
                <h3 className=" font-bold">Kontouppgifter</h3>
                <div className="flex flex-col gap-1">
                  <label htmlFor="user-email" className="text-sm">
                    Epost:
                  </label>
                  <input
                    id="user-email"
                    type="text"
                    name="email"
                    placeholder="Epost"
                    className={`rounded ${
                      errors?.email ? '!border-red-500' : ''
                    }`}
                    {...register('email', registerOptions.email)}
                  />
                  <small className="text-red-600">
                    {errors?.email && errors.email.message}
                  </small>
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="user-phone" className="text-sm">
                    Telefon:
                  </label>
                  <input
                    id="user-phone"
                    type="text"
                    name="phone"
                    placeholder="Ex. 0712345678"
                    className={errors?.phone_number && '!border-red-500'}
                    {...register('phone_number', registerOptions.phone_number)}
                  />
                  <small className="text-red-600">
                    {errors?.phone_number && errors.phone_number.message}
                  </small>
                </div>
              </div>

              <div className="grid gap-2">
                <h3 className=" font-bold">Personuppgifter</h3>
                <div className="flex flex-col gap-1">
                  <label htmlFor="user-first-name" className="text-sm">
                    Förnamn:
                  </label>
                  <input
                    id="user-first-name"
                    type="text"
                    name="first_name"
                    className={errors?.first_name && '!border-red-500'}
                    {...register('first_name', registerOptions.first_name)}
                  />
                  <small className="text-red-600">
                    {errors?.first_name && errors.first_name.message}
                  </small>
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="user-last-name" className="text-sm">
                    Efternamn:
                  </label>
                  <input
                    id="user-last-name"
                    type="text"
                    name="last_name"
                    className={errors?.last_name && '!border-red-500'}
                    {...register('last_name', registerOptions.last_name)}
                  />
                  <small className="text-red-600">
                    {errors?.last_name && errors.last_name.message}
                  </small>
                </div>
              </div>

              <div className="grid gap-2">
                <h3 className=" font-bold">Adress</h3>
                <div className="flex flex-col gap-1">
                  <label htmlFor="user-address" className="text-sm">
                    Gatuadress:
                  </label>
                  <input
                    id="user-address"
                    type="text"
                    name="address_1"
                    className={errors?.address_1 && '!border-red-500'}
                    {...register('address_1', registerOptions.address_1)}
                  />
                  <small className="text-red-600">
                    {errors?.address_1 && errors.address_1.message}
                  </small>
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="user-address_2" className="text-sm">
                    Gatuadress 2:
                  </label>
                  <input
                    id="user-address_2"
                    type="text"
                    name="address_2"
                    className={errors?.address_2 && '!border-red-500'}
                    {...register('address_2', registerOptions.address_2)}
                  />
                  <small className="text-red-600">
                    {errors?.address_2 && errors.address_2.message}
                  </small>
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="user-city" className="text-sm">
                    Stad:
                  </label>
                  <input
                    id="user-city"
                    type="text"
                    name="address_2"
                    className={errors?.city && '!border-red-500'}
                    {...register('city', registerOptions.city)}
                  />
                  <small className="text-red-600">
                    {errors?.city && errors.city.message}
                  </small>
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="state" className="text-sm">
                    Ort:
                  </label>
                  <input
                    id="state"
                    type="text"
                    name="state"
                    className={errors?.state && '!border-red-500'}
                    {...register('state', registerOptions.state)}
                  />
                  <small className="text-red-600">
                    {errors?.state && errors.state.message}
                  </small>
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="user-zip" className="text-sm">
                    Postnummer:
                  </label>
                  <input
                    id="user-zip"
                    type="text"
                    name="zip"
                    className={errors?.zip && '!border-red-500'}
                    {...register('zip', registerOptions.zip)}
                  />
                  <small className="text-red-600">
                    {errors?.zip && errors.zip.message}
                  </small>
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="country" className="text-sm">
                    Land:
                  </label>
                  <input
                    id="country"
                    type="text"
                    name="country"
                    className={errors?.country && '!border-red-500'}
                    {...register('country', registerOptions.country)}
                  />
                  <small className="text-red-600">
                    {errors?.country && errors.country.message}
                  </small>
                </div>
              </div>

              <div className="grid gap-2">
                <h3 className=" font-bold">Bankuppgifter</h3>
                <div className="flex flex-col gap-1">
                  <label htmlFor="user-bank" className="text-sm">
                    Bank:
                  </label>
                  <input
                    id="user-bank"
                    type="text"
                    name="bank"
                    className={errors?.bank && '!border-red-500'}
                    {...register('bank', {
                      ...(hasInventory && {
                        required: 'Bank är obligatoriskt',
                      }),
                    })}
                  />
                  <small className="text-red-600">
                    {errors?.bank && errors.bank.message}
                  </small>
                </div>
                <div className="flex flex-col gap-1">
                  <label htmlFor="user-bankaccount" className="text-sm">
                    Kontonummer med clearing:
                  </label>
                  <input
                    id="user-bankaccount"
                    type="text"
                    name="bankaccount"
                    className={errors?.bankaccount && '!border-red-500'}
                    {...register('bankaccount', {
                      ...(hasInventory && {
                        required: 'Kontonummer är obligatoriskt',
                        validate: (value) =>
                          validateAccount(value) ||
                          'Måste vara ett giltigt kontonummer',
                      }),
                    })}
                  />
                  <small
                    className={
                      errors?.bankaccount ? `text-red-600` : 'text-gray-500'
                    }
                  >
                    {errors?.bankaccount
                      ? errors.bankaccount.message
                      : 'För Nordea personkonto använd 3300 som clearing plus 10 siffror'}
                  </small>
                </div>
              </div>
              <input
                type="submit"
                value="Spara användare"
                className={`btn btn-secondary btn--full ${
                  !isDirty && 'opacity-50'
                }`}
                disabled={!isDirty}
              />
            </form>
          </div>
        ))}
      {tabs.activeTab === 'history' && (
        <div className="mt-4 max-h-96 overflow-scroll pl-1">
          <ol class="relative border-s border-gray-200">
            {!userAuditLoading &&
              userAudit.length > 0 &&
              userAudit.map((auditLog) => (
                <li class="mb-4 ms-4">
                  <div class="absolute w-3 h-3 bg-gray-200 rounded-full mt-1.5 -start-1.5 border border-white "></div>
                  <time class="mb-1 text-xs font-normal leading-none text-gray-400 ">
                    {new Date(auditLog.createdAt).toLocaleString()}
                  </time>
                  <h3 class="text-md font-semibold text-gray-900 capitalize ">
                    {auditLog.type === 'activity' ? 'Aktivitet' : 'Ändring'}
                  </h3>
                  {auditLog.type === 'activity' && (
                    <>
                      <p class="mb-4 text-sm font-normal text-gray-900 ">
                        {auditLog.activity === 'sign_in' &&
                          'Användaren loggade in'}
                        {auditLog.activity === 'sign_up' &&
                          'Konto skapat för användaren'}
                        {auditLog.activity === 'sign_up_existing' &&
                          'Kund har begärt åtkomst till konto'}
                        {auditLog.activity === 'password_reset_request' &&
                          'Lösenordsåterställning begärd'}
                        {auditLog.activity === 'password_change' &&
                          'Lösenord ändrat'}
                        {auditLog.activity === 'password_reset' &&
                          'Lösenord återställt'}
                      </p>
                      {auditLog.activity === 'sign_up_existing' && (
                        <button className="btn btn--xs btn-functional ">
                          Skicka igen
                        </button>
                      )}
                    </>
                  )}
                  {auditLog.type === 'audit' && (
                    <div className="grid gap-2">
                      <p class="text-sm font-normal text-gray-900 ">
                        <span className="capitalize">
                          {auditLog.field_updated}
                        </span>{' '}
                        ändrades från{' '}
                        <span className="bold">{auditLog.old_value}</span> till{' '}
                        <span className="bold">{auditLog.new_value}</span>
                      </p>
                      <p className="text-xs text-gray-500">
                        Ändrad av {auditLog.updated_by.first_name}{' '}
                        {auditLog.updated_by.last_name} (
                        {auditLog.updated_by.person_id})
                      </p>
                    </div>
                  )}
                </li>
              ))}
            {!userAuditLoading && userAudit.length === 0 && (
              <p className="text-gray-500 text-center">
                Ingen historik hittades
              </p>
            )}
            {userAuditLoading && <Spinner></Spinner>}
          </ol>
        </div>
      )}
      {tabs.activeTab === 'actions' && (
        <div className="mt-4 grid gap-8">
          <div className="">
            <h3 className="text-lg font-bold">Återställ lösenord</h3>
            <p className="text-gray-700 mb-4">
              En länk skickas med epost till användaren för att återställa
            </p>
            <button
              onClick={() => mutatePasswordReset({ person_id: userId })}
              type="button"
              className="btn btn-secondary btn--full"
            >
              Skicka länk
            </button>
          </div>
          <div className="">
            <h3 className=" font-bold">Ta bort kundprofil</h3>
            <p className="text-gray-700 mb-4">
              Detta är en permanent åtgärd som inte går att ångra. Både
              webbåtkomst och kunddata från kassan kommer att raderas.
            </p>
            <button
              onClick={() =>
                openPopup('DeleteUser', { userId: userId, isAdmin: true })
              }
              type="button"
              className="btn btn-danger btn--full"
            >
              Ta bort
            </button>
          </div>
        </div>
      )}
    </section>
  );
});

export default PopupEditUser;
