import './hero.scss';
import React from 'react';
import { Link } from 'react-router-dom';

import { Cursor, useTypewriter } from 'react-simple-typewriter';
import { motion } from 'framer-motion';

import { Icon, Icons } from '../icon/Icon';

export default function Hero() {
  const [text, count] = useTypewriter({
    words: ['sportutrustning', 'idrottsutrustning'],
    loop: true,
    delaySpeed: 2500,
  });

  return (
    <section className="hero" data-scroll data-scroll-section>
      <div className="hero_column">
        <div className="hero_column-text">
          <h1 className="">
            <p>Ge din</p>
            <p>
              {text}
              <Cursor />
            </p>
            <p>en andra chans</p>
          </h1>
          <p className="hero_column-text--description">
            Sportbytarboden är ett enkelt och bekvämt sätt för dig att sälja och
            köpa.
          </p>
          <Link to={'/salj'} className="btn btn-medium btn-primary">
            Läs mer om att sälja
            <Icon icon={Icons.faChevronRight} />
          </Link>
        </div>
        <div className="hero_column-imgs">
          <motion.div
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.4 },
            }}
            viewport={{ once: true }}
            className="hero_img hero_img-top-left"
          />
          <motion.div
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.4, delay: 0.1 },
            }}
            viewport={{ once: true }}
            className="hero_img hero_img-bottom-left"
          />
          <motion.div
            initial={{
              opacity: 0,
              y: 100,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
              transition: { duration: 0.4, delay: 0.05 },
            }}
            viewport={{ once: true }}
            className="hero_img hero_img-right"
          />
        </div>
      </div>
    </section>
  );
}
