import React, { forwardRef } from 'react';
import './mobile-nav-drawer.scss';
import { Icon, Icons } from '../../icon/Icon';
import useAuth from '../../../hooks/auth';

export const MobileNavDrawer = forwardRef(({ closeDrawer }, ref) => {
  const { user } = useAuth();

  return (
    <aside className="drawer drawer-mobile-nav" ref={ref}>
      <div className="drawer-header">
        <button
          onClick={() => closeDrawer()}
          className="absolute right-4 top-4"
        >
          <Icon icon={Icons.faX} size={'xs'}></Icon>
        </button>
      </div>
      <div className="drawer-middle">
        <nav className="">
          <ul className="drawer-middle-nav">
            <li>
              <a href="/salj">
                <div className="drawer-icon">
                  <Icon icon={Icons.faTag} size={'lg'} color={'#0aca60'}></Icon>
                </div>
                Sälj
              </a>
            </li>
            <li>
              <a href="/produkter">
                <div className="drawer-icon">
                  <Icon
                    icon={Icons.faBasketShopping}
                    size={'lg'}
                    color={'#0aca60'}
                  ></Icon>
                </div>
                Köp
              </a>
            </li>
            <li>
              <a href="/butik">
                <div className="drawer-icon">
                  <Icon
                    icon={Icons.faShop}
                    size={'lg'}
                    color={'#0aca60'}
                  ></Icon>
                </div>
                Butiken
              </a>
            </li>
            <li>
              <a href="/verkstad">
                <div className="drawer-icon">
                  <Icon
                    icon={Icons.faScrewdriverWrench}
                    size={'lg'}
                    color={'#0aca60'}
                  ></Icon>
                </div>
                Verkstad
              </a>
            </li>
          </ul>
        </nav>
        {!user && (
          <nav className="drawer-footer">
            <ul className="flex flex-col gap-1.5">
              <li>
                <a href="/registera" className="btn btn-primary btn--full">
                  Skapa kundkonto
                </a>
              </li>
              <li>
                <a href="/logga-in" className="btn btn-functional btn--full">
                  Logga in
                </a>
              </li>
            </ul>
          </nav>
        )}
        <nav className="">
          <ul className="flex items-center justify-center">
            <li className="">
              <a
                href="https://www.facebook.com/sportbytarboden"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  icon={Icons.faInstagram}
                  size={'2xl'}
                  // color={'#0aca60'}
                ></Icon>
              </a>
            </li>
            <li className=" ">
              <a
                href="https://www.instagram.com/sportbytarboden"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Icon
                  icon={Icons.faFacebook}
                  size={'2xl'}
                  // color={'#0aca60'}
                ></Icon>
              </a>
            </li>
          </ul>
        </nav>
      </div>
    </aside>
  );
});

export default MobileNavDrawer;
