import './index.scss';
import React, { useState } from 'react';

function SellCalculator() {
  const [price, setPrice] = useState(500);

  const commission = Math.ceil(Math.min(price * 0.4, 1600));
  const netPercentage = Math.round(((price - commission) / price) * 100);

  return (
    <div className="sell-calculator">
      <h2>Räkna på vad du får tillbaka per vara</h2>
      <div className="sell-calculator_price-wrapper">
        <p className="sell-calculator_price"> {price} SEK</p>
        <h3>Vi säljer varan för</h3>
      </div>
      <input
        type="range"
        name=""
        id=""
        min={50}
        max={10000}
        value={price}
        step="50"
        onChange={(e) => setPrice(e.target.value)}
      />
      <div className="sell-calculator_content">
        <div className="sell-calculator_item">
          <p>{price - commission}</p>
          <h3>Du får tillbaka (SEK)</h3>
        </div>
        <div className="sell-calculator_item">
          <p>{netPercentage}</p>
          <h3>Du får tillbaka (%)</h3>
        </div>
      </div>
    </div>
  );
}

export default SellCalculator;
