import './about.css';
import React from 'react';
import SEO from '../../components/seo/SEO';
import InfoText from '../../components/info-text/Infotext';

export default function About() {
  return (
    <div className="about">
      <SEO title="Om oss - Sportbytarboden | Din mötesplats för begagnad sportutrustning" />

      <InfoText
        heading={'Om Sportbytarboden'}
        description={
          'Sportbytarboden.se är ett familjedrivet företag som startade hösten 2009. Sedan dess har över 30.000 kunder använt vår förmedlingstjänst.'
        }
      ></InfoText>
      <div className="about-container section-container">
        <p>
          Vi som driver verksamheten har alla lång erfarenhet av olika idrotter,
          men framförallt från skidåkning - både som skidlärare och
          tävlingsåkare.
        </p>
        <p>
          Under högsäsong betalar vi nu ut ersättning för sålda varor till över
          2.000 nöjda kunder varje månad.
        </p>
        <p>
          Idrottsutrusting är ofta dyr och barnen växer i en ”plånboksovänlig
          takt”. Genom att återanvända sakerna bidrar vi dessutom till ett
          hållbarare samhälle{' '}
        </p>
        <p>
          Vi har låg provision och vårt automatiserade system för utbetalning
          gör oss unika. Enklare än att sälja via inernetbaserade tjänster och
          definitivt enklare att köpa.{' '}
        </p>
        <p>Har du frågot om något så tveka inte att kontakta oss!</p>
      </div>
    </div>
  );
}
