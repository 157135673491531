import './overlay-button.scss';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';

export default function OverlayButton() {
  return (
    <Link
      to="admin"
      target="_blank"
      rel="noopener noreferrer"
      className="overlay-button"
    >
      <FontAwesomeIcon
        className="admin-sidebar_icon"
        icon={faArrowUpRightFromSquare}
      />
      <h4>Till Adminpanel</h4>
    </Link>
  );
}
