import authHeader from '../shared/auth-header.js';
import http from '../shared/http-common.js';
import { url } from '../../constants/endpoints.js';
import { variableMapper } from '../../utils/utils.js';

export function getUserPayments(userId, page = 0) {
  return http.get(variableMapper(url.USER_GET_ALL_PAYMENTS, 'id', userId), {
    params: { page },
    headers: authHeader(),
  });
}

export function updateUser(id, data) {
  const queryParams = {
    id: id,
  };
  return http.put('/users/user', data, {
    params: { ...queryParams },
    headers: authHeader(),
  });
}

export function subscribeToNewsletter(email) {
  return http.post(url.NEWSLETTER_SUBSCRIBE, { email });
}

export function getInventoryStatistics(data) {
  const query = {
    startDate: data.startDate,
    endDate: data.endDate,
    userId: data.userId,
  };
  console.log(query);
  return http.get(url.STATISTICS_GET_USER_INVENTORY, {
    params: { ...query },
    headers: authHeader(),
  });
}

export function getServiceOrdersFromCustomerId(userId, page = 0) {
  return http.get(variableMapper(url.USER_GET_WORKSHOP_ORDERS, 'id', userId), {
    params: { id: userId, page },
    headers: authHeader(),
  });
}

// NOT CHECKED
// CUSTOMER
export function getCustomerByPersonId(personId) {
  const queryParams = {
    person_id: personId,
  };
  return http.get(`/customer/person_id`, {
    params: { ...queryParams },
    headers: authHeader(),
  });
}

export function getAllCustomers(query) {
  return http.get(`/customer`, {
    params: { query },
    headers: authHeader(),
  });
}

export function updateCustomer(id, data) {
  const queryParams = {
    id: id,
  };
  return http.put(url.CUSTOMER_UPDATE, data, {
    params: { ...queryParams },
    headers: authHeader(),
  });
}

export function customerPayments(id) {
  return http.get('/payments/customer', {
    params: { id: id },
    headers: authHeader(),
  });
}

export function deleteUser(userId) {
  return http.delete(variableMapper(url.USER_DELETE, 'id', userId), {
    headers: authHeader(),
  });
}

export default {
  getServiceOrdersFromCustomerId,
  // addToEmailList,
  getAllCustomers,
  getCustomerByPersonId,
  updateCustomer,
  customerPayments,
  updateUser,
};
