import './article.scss';
import React, { useEffect } from 'react';

import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';

import { fetchArticleById } from '../../../api/contentful/contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer'; // Import the renderer

import { Icon, Icons } from '../../../components/icon/Icon';
import config from '../../../assets/config';
import SEO from '../../../components/seo/SEO';

export default function Article({ setBreadCrumbs }) {
  const { id } = useParams();

  const { data: article } = useQuery(['article'], () => fetchArticleById(id));

  useEffect(() => {
    setBreadCrumbs([
      {
        name: 'Nyheter',
        href: '/nyheter',
      },
      ...(article?.category === 'work'
        ? [
            {
              name: 'Jobba',
              href: `/nyheter/jobba`,
            },
            {
              name: article?.title,
              href: `/nyheter/${id}`,
            },
          ]
        : [
            {
              name: article?.title ?? 'Nyhet',
              href: `/nyheter/${id}`,
            },
          ]),
    ]);

    return () => {
      setBreadCrumbs([]);
    };
  }, [setBreadCrumbs, article]);

  return (
    <section className="page-container article">
      <SEO
        title={`${
          article?.title ?? 'Köp och sälj sportutrustning'
        } - Sportbytarboden | Guide för skidor, cyklar och mer`}
      />

      <article className="article-content">
        <div className="article-image-container">
          <img
            src={
              article?.coverImage
                ? `${article.coverImage.url}`
                : config.images.recycleLogoBlack
            }
            alt="article image"
            className="article-image"
            onError={(e) => {
              e.target.src = config.images.recycleLogoBlack;
            }}
          />
          <h1>{article?.title && article.title}</h1>
        </div>
        <div className="p-4 md:p-8 min-h-48 flex flex-col">
          {article?.text && (
            <div className="contenful-textbox">
              {documentToReactComponents(article.text.json)}
            </div>
          )}
          {article?.category === 'work' && (
            <a
              href="mailto:info@sportbytarboden.se"
              className="btn btn-secondary mt-auto"
            >
              Kontakta oss
              <Icon icon={Icons.faChevronRight} size={'md'}></Icon>
            </a>
          )}
          {article?.sys && (
            <div className="text-xs mt-12 text-gray-600">
              <p className="">Publicerad</p>
              <p>{new Date(article.sys.publishedAt).toLocaleString()}</p>
            </div>
          )}
        </div>
      </article>
    </section>
  );
}
