import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import useAnalytics from '../../../hooks/analytics';

import { requestCustomerId } from '../../../api/core/auth';
import { useNotification } from '../../../hooks/useNotification';
import SEO from '../../../components/seo/SEO';

const inputs = [
  {
    id: 1,
    name: 'email',
    type: 'text',
    placeholder: 'Epost',
    label: 'Epost:',
    errorMessage: 'Måste vara en giltig e-post',
    pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$|',
    required: true,
  },
];

function RequestCustomerDetails() {
  const navigate = useNavigate();
  const dispatch = useNotification();
  const { eventTrackerGA } = useAnalytics({
    category: 'Request customer details',
  });

  const [values, setValues] = useState({});

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (customerId) => requestCustomerId(customerId),
    onSuccess: () => {
      setTimeout(() => navigate('/logga-in/begar'), 10000);
    },
    onError: (error) => {
      //   console.log(error);
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    eventTrackerGA('Request customer details');
    mutate(values);
  };

  const handleOnChange = (e) => {
    const value = e.target.value;

    setValues({ ...values, [e.target.name]: value });
  };
  return (
    <div className="login">
      <SEO title="Hämta ditt kundnummer - Sportbytarboden | Återfå tillgång till ditt konto enkelt" />

      <section className="login-card">
        <div className="login-card_header flex flex-col items-center gap-2">
          <h1 className="">Få ditt kundnummer via e-post</h1>
          <small className="max-w-[90%] text-center">
            Fyll i din e-postadress, så skickar vi ditt kundnummer direkt till
            din inkorg.
          </small>
        </div>
        {!isSuccess ? (
          <form className="login-card_form gap-1.5" onSubmit={handleSubmit}>
            {inputs.map((input) => {
              return (
                <input
                  className="mb-1"
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={handleOnChange}
                  errorMessage="Måste vara en giltig e-post"
                  pattern="[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$|"
                />
              );
            })}
            <input
              type="submit"
              className="btn btn-secondary btn--full"
              value={isLoading ? 'Skickar...' : 'Skicka kundnummer'}
            />
            <button
              onClick={() => navigate(-1)}
              className="btn btn-functional btn--full"
              type="button"
            >
              Tillbaka
            </button>
          </form>
        ) : (
          <div className="p-4 grid gap-6 ">
            <h3 className="text-center">
              Ett e-postmeddelande har skickats till dig med ditt kundnummer.
            </h3>
            <div className="grid gap-1.5">
              <a href="/logga-in" className="btn btn-secondary btn--full">
                Logga in
              </a>
              <button
                onClick={() => navigate(-1)}
                className="btn btn-functional btn--full"
                type="button"
              >
                Tillbaka
              </button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}

export default RequestCustomerDetails;
