import './header.scss';
import React, { useState, useEffect } from 'react';

import Alertbar from './alertbar/Alertbar';
import Navigation from './navigation/Navigation';

export default function Header() {
  const [fixed, setFixed] = useState(false);

  useEffect(() => {
    window.addEventListener('scroll', toggleFixed);

    return () => window.removeEventListener('scroll', toggleFixed);
  }, []);

  const toggleFixed = () => {
    if (window.scrollY >= 54) {
      setFixed(true);
    } else {
      setFixed(false);
    }
  };

  return (
    <div className="header-container">
      <Alertbar></Alertbar>
      <header className={`${fixed ? 'header--fixed' : ''}`}>
        <Navigation></Navigation>
      </header>
    </div>
  );
}
