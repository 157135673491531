import './index.scss';
import React, { forwardRef } from 'react';

export const TermsOfCookiesPopup = forwardRef(
  ({ openPopup, closePopup }, ref) => {
    const handleModalClose = (event) => {
      closePopup();
    };

    const handleChildElementClick = (e) => {
      e.stopPropagation();
    };

    return (
      <div
        className="product-modal"
        onClick={(e) => handleChildElementClick(e)}
        ref={ref}
      >
        <div className="product-modal_header">
          <div className="product-modal_close" onClick={handleModalClose}></div>
        </div>
        <div className="xs:px-4 xs:pb-4 px-8 pb-8">
          <div className="max-w-3xl mx-auto rounded-lg mb-8">
            <h1 className="text-2xl font-bold text-gray-800 mb-4">
              Cookiepolicy
            </h1>
            <p className="text-gray-600 mb-4">
              Uppdaterad: <span className="font-semibold">10/01/2025</span>
            </p>

            <p className="text-gray-700 mb-4">
              Sportbytarboden AB använder cookies för att förbättra din
              upplevelse på vår webbplats, analysera användarbeteenden och ge
              dig relevant innehåll och annonser. Denna policy förklarar vad
              cookies är, hur vi använder dem och hur du kan hantera dina
              inställningar.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mb-3">
              Vad är cookies?
            </h2>
            <p className="text-gray-700 mb-4">
              Cookies är små textfiler som lagras på din enhet när du besöker en
              webbplats. De används för att samla in information om hur
              webbplatsen används, vilket hjälper oss att förbättra våra
              tjänster och anpassa innehållet efter dina behov.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mb-3">
              Vilka cookies använder vi?
            </h2>
            <ul className="list-disc list-inside text-gray-700 mb-4">
              <li>
                <strong>Nödvändiga cookies:</strong> Cookies som krävs för att
                webbplatsen ska fungera korrekt.
              </li>
              <li>
                <strong>Analytiska cookies:</strong> Vi använder{' '}
                <strong>Google Analytics</strong> för att samla in anonym
                användardata för att förbättra upplevelsen.
              </li>
              <li>
                <strong>Marknadsföringscookies:</strong> Cookies som används för
                att visa relevant annonsering.
              </li>
            </ul>

            <h2 className="text-xl font-semibold text-gray-800 mb-3">
              Tredjepartscookies
            </h2>
            <p className="text-gray-700 mb-4">
              Förutom våra egna cookies använder vi tredjepartscookies, såsom{' '}
              <strong>Google Analytics</strong>, för att analysera trafik och
              förbättra våra tjänster. För mer information, läs Googles{' '}
              <a
                href="https://policies.google.com/privacy"
                className="text-blue-500 hover:underline"
                target="_blank"
                rel="noopener noreferrer"
              >
                integritetspolicy
              </a>
              .
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mb-3">
              Hur hanterar jag cookies?
            </h2>
            <p className="text-gray-700 mb-4">
              Du kan när som helst hantera eller blockera cookies via
              inställningarna i din webbläsare. Observera att vissa delar av
              webbplatsen kanske inte fungerar som avsett om cookies blockeras.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mb-3">
              Ändringar i vår cookiepolicy
            </h2>
            <p className="text-gray-700 mb-4">
              Sportbytarboden AB kan uppdatera denna cookiepolicy. Kontrollera
              denna sida regelbundet för att hålla dig informerad.
            </p>

            <h2 className="text-xl font-semibold text-gray-800 mb-3">
              Kontakt
            </h2>
            <p className="text-gray-700">
              Om du har frågor om vår användning av cookies, vänligen kontakta
              oss
            </p>
          </div>
          <button
            className="btn btn--full btn-secondary "
            onClick={handleModalClose}
          >
            Stäng
          </button>
        </div>
      </div>
    );
  },
);

export default TermsOfCookiesPopup;
