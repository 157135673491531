import './alreadycustomer.scss';
import React from 'react';
import TennisImg from '../../../../assets/imgs/devices.png';
import { Link } from 'react-router-dom';
import { Icon, Icons } from '../../../../components/icon/Icon';
import useAnalytics from '../../../../hooks/analytics';

export default function AlreadyCustomer() {
  const { eventTrackerGA } = useAnalytics({
    category: 'Already customer section',
  });

  return (
    <section className="section-signup">
      <div className="section-signup-wrapper">
        <div className="section-signup-left">
          <h2>Redan kund i butiken?</h2>
          <p>
            Nu kan du enkelt få tillgång till ditt kundkonto och ta del av
            information gällande dina försäljningar.
            <br />
          </p>
          <Link
            to="/logga-in/begar"
            className="btn"
            onClick={() => eventTrackerGA('Få till gång till kundkonto')}
          >
            Få tillgång till ditt konto
            <Icon icon={Icons.faChevronRight} />
          </Link>
        </div>
        <img className="section-signup-right" src={TennisImg} alt="" />
      </div>
    </section>
  );
}
