import React, { useState, useEffect, useContext, useRef } from 'react';
import './navigation.scss';
import { Link, useLocation, useSearchParams } from 'react-router-dom';

import { motion } from 'framer-motion';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faUserCircle,
  faMagnifyingGlass,
} from '@fortawesome/free-solid-svg-icons';
import logo from '../../../../assets/logos/gronsvart-1.svg';

import useSearchProducts from '../../../../hooks/search-products';
import useAuth from '../../../../hooks/auth';
import { useDrawer } from '../../../../hooks/drawer';
import UserButton from '../components/user-button/UserButton';

const links = [
  {
    url: '/salj',
    title: 'Sälj',
  },
  {
    url: '/produkter',
    title: 'Produkter',
  },
  {
    url: '/butik',
    title: 'Butiken',
  },
  {
    url: '/verkstad',
    title: 'Verkstad',
  },
];

export default function Navigation() {
  // General
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { handleProductSearch, refetch } = useSearchProducts();
  const { user } = useAuth();
  const { openDrawer } = useDrawer();

  // Refs
  const searchBar = useRef();

  const handleMobileNav = () => {
    openDrawer('Drawer', {});
  };

  return (
    <div className={`header_navigation-container `}>
      <nav>
        <Link to={'/'}>
          <img className="header_navigation-logo" src={logo} alt="logo" />
        </Link>
        <form
          className="input-icon-wrapper header_search"
          onSubmit={(e) => e.preventDefault()}
        >
          <FontAwesomeIcon
            className="header_search-input-icon"
            icon={faMagnifyingGlass}
          />
          <input
            onChange={handleProductSearch}
            className={`header_search-input`}
            type="text"
            name="name"
            id="productsearch"
            defaultValue={searchParams.get('name')}
            ref={searchBar}
            placeholder="Sök efter produkt eller storlek"
          />
          <label className="sr-only" htmlFor="productsearch">
            Sök bland produkter
          </label>
          <input type="submit" value="Sök " className="sr-only" />
        </form>
        <div className="ml-auto flex items-center gap-4 h-full navigation">
          <ul className="desktop-nav">
            {links.map((link) => (
              <li
                key={link.title}
                className={`nav-link nav-link_sidebar ${
                  location.pathname === link.url
                    ? 'nav-link_sidebar--active'
                    : ''
                }`}
              >
                <Link to={link.url}>{link.title}</Link>
              </li>
            ))}
            <div className="desktop-nav_login">
              {!user && (
                <Link to={'/logga-in'} className="nav-link">
                  Logga in
                </Link>
              )}
            </div>
          </ul>
          {user && <UserButton></UserButton>}
          <div className="mobile-nav navigation-mobile">
            <div className="hamburger" onClick={handleMobileNav}>
              <div className="line"></div>
              <div className="line"></div>
              <div className="line"></div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
}
