import { useState, useEffect } from 'react';
import ReactGA from 'react-ga4';
import { hotjar } from 'react-hotjar';
import useLocalPersistStore from '../store/local-persist.store';

export function useAnalytics({ HotjarTracking, GATrackingId, category }) {
  const { cookiesAccepted } = useLocalPersistStore();
  const [analyticsInitialized, setAnalyticsInitialized] = useState(false);

  useEffect(() => {
    if (cookiesAccepted) {
      if (!analyticsInitialized) {
        initGA();
        initHotjar();
        setAnalyticsInitialized(true);
      }
    }
  }, [cookiesAccepted]);

  const initHotjar = () => {
    if (!HotjarTracking) return;

    hotjar.initialize(HotjarTracking.hjid, HotjarTracking.hjsv);
  };

  const initGA = () => {
    if (!GATrackingId) return;

    ReactGA.initialize(GATrackingId);
  };

  const pageViewGA = (pathname) => {
    if (!GATrackingId) return;

    ReactGA.send({ hitType: 'pageview', page: pathname });
  };

  const pageViewHotjar = (pathname) => {
    if (!HotjarTracking) return;
    hotjar.stateChange(pathname);
  };

  const eventTrackerGA = (action = 'test action', label = 'test label') => {
    ReactGA.event({ category, action, label });
  };

  return {
    initGA,
    pageViewGA,
    initHotjar,
    pageViewHotjar,
    eventTrackerGA,
    analyticsInitialized,
  };
}

export default useAnalytics;
