import React, { useEffect, useReducer, useContext, useRef } from 'react';
import { useLocation } from 'react-router-dom';

import { AnimatePresence, motion } from 'framer-motion';

import ProductPopup from '../components/popups/product/product-popup';
import ProductSellInfo from '../components/popups/product-sell-info/product-sell-info';
import AddUser from '../components/popups/addUser/AddUser';
import PopupEditUser from '../components/popups/editUser/EditUser';
import { TermsOfCookiesPopup } from '../components/popups/terms-popup/terms-popup';
import NewsletterPopup from '../components/popups/newsletter/NewsletterPopup';
import SignupSuccessPopup from '../components/popups/signup-success/SignupSuccessPopup';
import DeleteUserPopup from '../components/popups/delete-user/DeleteUser';

const MotionProductPopup = motion(ProductPopup, { forwardMotionProps: true });
const MotionProductSellInfo = motion(ProductSellInfo, {
  forwardMotionProps: true,
});
const MotionAddUser = motion(AddUser, {
  forwardMotionProps: true,
});
const MotionEditUser = motion(PopupEditUser, {
  forwardMotionProps: true,
});
const MotionTermsOfCookies = motion(TermsOfCookiesPopup, {
  forwardMotionProps: true,
});
const MotionNewsletter = motion(NewsletterPopup, {
  forwardMotionProps: true,
});
const MotionSignupSuccess = motion(SignupSuccessPopup, {
  forwardMotionProps: true,
});
const MotionDeleteUser = motion(DeleteUserPopup, {
  forwardMotionProps: true,
});

const defaultPopup = {
  popupIsOpen: false,
  popupName: null,
  options: null,
  requestClose: null,
};

const PopupContext = React.createContext();

export const usePopup = () => {
  const dispatch = useContext(PopupContext);

  return {
    // ...state,
    openPopup: (popupName, options) => {
      dispatch({
        type: 'OPEN_POPUP',
        payload: {
          popupName,
          options,
        },
      });
    },
  };
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_POPUP':
      return {
        ...state,
        popupIsOpen: action.payload.popupName ? true : false,
        popupName: action.payload.popupName,
        options: action.payload.options,
        data: action.payload.data,
      };
    default:
      throw new Error();
  }
};

const PopupProvider = ({ init, children, brand }) => {
  //Hooks
  const [state, dispatch] = useReducer(reducer, defaultPopup);
  const location = useLocation();

  //Close the popup if the url changes
  useEffect(() => {
    dispatch({
      type: 'OPEN_POPUP',
      payload: {
        popupName: null,
        options: null,
      },
    });
  }, [location]);

  //On popup name change
  useEffect(() => {
    if (state.popupName) {
      //Scrolling
      document.documentElement.style.overflow = 'hidden';
      document.addEventListener('keydown', handleKeyDown);
    } else {
      //Scrolling
      document.documentElement.style.overflow = 'initial';
    }
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [state.popupName]);

  //Refs
  const scrollRef = useRef();
  const popupRef = useRef();

  //Helper function
  function closePopup() {
    dispatch({
      type: 'OPEN_POPUP',
      payload: {
        popupName: null,
        options: null,
      },
    });
  }

  function openPopup(popupName, options) {
    dispatch({
      type: 'OPEN_POPUP',
      payload: {
        popupName: popupName,
        options: options,
      },
    });
  }

  const handleKeyDown = (event) => {
    if (event.key === 'Escape') {
      closePopup();
    }
  };

  return (
    <PopupContext.Provider value={dispatch}>
      {children}
      <AnimatePresence>
        {state.popupName && (
          <motion.div
            className="popup-overlay"
            key="popup"
            initial={{ opacity: 0.7 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
          >
            <div
              className="popup-background-click"
              onClick={(e) => {
                if (popupRef.current && popupRef.current.contains(e.target)) {
                  return;
                }

                if (state.options && state.options.disableClose) {
                  return;
                }

                closePopup();
              }}
            />
            <motion.div
              ref={popupRef}
              className={`popup-card popup-card-positioning ${
                state.popupName === 'ProductSellInfo' ||
                state.popupName === 'Product' ||
                state.popupName === 'WorkshopModifier'
                  ? 'popup-card-large'
                  : ''
              }`}
              initial={{ opacity: 0.7, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: 20 }}
            >
              {state.popupName === 'Product' && (
                <MotionProductPopup
                  openPopup={openPopup}
                  closePopup={closePopup}
                  {...state.options}
                ></MotionProductPopup>
              )}
              {state.popupName === 'ProductSellInfo' && (
                <MotionProductSellInfo
                  openPopup={openPopup}
                  closePopup={closePopup}
                  {...state.options}
                ></MotionProductSellInfo>
              )}
              {state.popupName === 'AddUser' && (
                <MotionAddUser
                  openPopup={openPopup}
                  closePopup={closePopup}
                  {...state.options}
                ></MotionAddUser>
              )}
              {state.popupName === 'EditUser' && (
                <MotionEditUser
                  openPopup={openPopup}
                  closePopup={closePopup}
                  {...state.options}
                ></MotionEditUser>
              )}
              {state.popupName === 'TermsOfCookies' && (
                <MotionTermsOfCookies
                  openPopup={openPopup}
                  closePopup={closePopup}
                  {...state.options}
                ></MotionTermsOfCookies>
              )}
              {state.popupName === 'Newsletter' && (
                <MotionNewsletter
                  openPopup={openPopup}
                  closePopup={closePopup}
                  {...state.options}
                ></MotionNewsletter>
              )}
              {state.popupName === 'SignupSuccess' && (
                <MotionSignupSuccess
                  openPopup={openPopup}
                  closePopup={closePopup}
                  {...state.options}
                ></MotionSignupSuccess>
              )}
              {state.popupName === 'DeleteUser' && (
                <MotionDeleteUser
                  openPopup={openPopup}
                  closePopup={closePopup}
                  {...state.options}
                ></MotionDeleteUser>
              )}
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </PopupContext.Provider>
  );
};

export default PopupProvider;
