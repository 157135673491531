export const url = {
  // AUTH
  AUTH_SIGNUP: '/auth/signup',
  AUTH_SIGNUP_EXISTING_CUSTOMER: '/auth/signup/existing-customer',
  AUTH_LOGIN: '/auth/signin',
  AUTH_LOGOUT: '/auth/signout',
  AUTH_RESET_PASSWORD: '/auth/password/change',
  AUTH_REQUEST_RESET_PASSWORD: '/auth/password/request-reset',
  AUTH_RESET_PASSWORD_FROM_LINK: '/auth/password/reset',
  AUTH_REQUEST_CUSTOMER_ID: '/auth/customer/request-id',

  // PRODUCTS
  PRODUCTS_GET_ALL: '/products',
  PRODUCTS_GET_ONE_BY_ID: '/products/:id',
  PRODUCTS_SEARCH: '/products',

  GET_CUSTOMER_PRODUCTS: '/products/customer',
  GET_PRODUCT_CATEGORIES: '/products/categories',

  NEWSLETTER_SUBSCRIBE: '/users/newsletter/subscribe',
  NEWSLETTER_SUBSCRIBE_MAILCHIMP:
    'https://billings.us14.list-manage.com/subscribe/post-json?u=323ce5d659026b00b4f5b6906&amp;id=335cdf0c74&amp;f_id=0076a7e0f0',

  // USER
  USER_GET_ALL_PAYMENTS: '/users/:id/payments',
  USER_GET_WORKSHOP_ORDERS: '/users/:id/workshop-orders',
  USER_DELETE: '/users/:id',

  // CUSTOMER
  CUSTOMER_UPDATE: '/customer/update',

  // CUSTOMER & USER UNCHECKED
  GET_ALL_CUSTOMERS: '/customer',
  GET_CUSTOMER_BY_EMAIL: '/customer/email',
  GET_CUSTOMER_BY_PERSON_ID: '/customer/person_id',
  REGISTER_CUSTOMER: '/customer/register',
  UPDATE_CUSTOMER: '/customer/update',
  CUSTOMER_PAYMENTS: '/payments/customer',
  CUSTOMER_SERVICE: '/workshop-service',

  // ADMIN
  ADMIN_GET_ALL_USERS: '/admin/users',
  ADMIN_GET_USER_BY_ID: '/admin/users/:id',
  ADMIN_CREATE_USER: '/admin/users',
  ADMIN_GET_ALL_SIGNUPS: '/admin/users/signup',
  ADMIN_GET_ALL_SIGNINS: '/admin/users/signin',
  ADMIN_GET_USER_AUDIT: '/admin/users/:id/audit',
  ADMIN_GET_USER_ANALYTICS: '/admin/users/:id/analytics',

  // ANALYTICS
  ANALYTICS_GET_USER_INVENTORY: '/analytics/user/inventory',
  ANALYTICS_NEW_USERS_ADMIN: '/analytics/users',
  ANALYTICS_INVENTORY_ADMIN: '/analytics/inventory',
  ANALYTICS_SALES_ADMIN: '/analytics/sales',
  ANALYTICS_SALE_ITEMS_ADMIN: '/analytics/sale-items',
  ANALYTICS_PURCHASE_ADMIN: '/analytics/purchase',
  ANALYTICS_PAYMENTS_ADMIN: '/analytics/payments',
  ANALYTICS_RECEIVING_ITEMS_ADMIN: '/analytics/receiving-items',
  ANALYTICS_RECEIVINGS_ADMIN: '/analytics/receivings',

  // GENERAL
  HEALTH_CHECK: '/auth/health-check',
};
