import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload,
  faCamera,
  faImage,
  faUser,
  faKey,
  faLock,
  faUnlock,
  faEye,
  faEyeSlash,
  faSliders,
  faGears,
  faGear,
  faUsers,
  faPersonWalking,
  faTrophy,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faMagnifyingGlass,
  faEllipsisVertical,
  faPlus,
  faMinus,
  faArrowRightFromBracket,
  faUserGroup,
  faUserXmark,
  faChessKing,
  faCircleUser as faCircleUserFilled,
  faAddressCard as faAddressCardFilled,
  faCircleExclamation,
  faCheck,
  faXmark,
  faX,
  faListUl,
  faArrowRight,
  faArrowLeft,
  faLightbulb as faLightbulbFill,
  faMapPin,
  faPhone,
  faMailBulk,
  faEnvelope,
  faLocationDot,
  faTag,
  faCircleCheck,
  faBasketShopping,
  faShop,
  faScrewdriverWrench,
  faCircleInfo,
} from '@fortawesome/free-solid-svg-icons';

import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

import {
  faCircleUser,
  faAddressCard,
  faLightbulb,
} from '@fortawesome/free-regular-svg-icons';

export function Icon({ icon, size, iconClass, onClick, color }) {
  return (
    <FontAwesomeIcon
      onClick={onClick}
      className={`icon ${size ? `icon--${size}` : ''} ${iconClass}`}
      icon={icon}
      size={size}
      aria-hidden="true"
      color={color}
    />
  );
}

export const Icons = {
  faDownload,
  faCamera,
  faImage,
  faUser,
  faKey,
  faLock,
  faUnlock,
  faEye,
  faEyeSlash,
  faFacebook,
  faInstagram,
  faSliders,
  faGears,
  faGear,
  faUsers,
  faPersonWalking,
  faTrophy,
  faChevronLeft,
  faChevronDown,
  faChevronUp,
  faChevronRight,
  faMagnifyingGlass,
  faEllipsisVertical,
  faPlus,
  faMinus,
  faArrowRightFromBracket,
  faUserGroup,
  faUserXmark,
  faChessKing,
  faCircleUser,
  faAddressCard,
  faCircleUserFilled,
  faAddressCardFilled,
  faCircleExclamation,
  faCheck,
  faXmark,
  faX,
  faListUl,
  faArrowRight,
  faArrowLeft,
  faLightbulb,
  faLightbulbFill,
  faMapPin,
  faPhone,
  faMailBulk,
  faEnvelope,
  faLocationDot,
  faCircleCheck,
  faTag,
  faBasketShopping,
  faShop,
  faScrewdriverWrench,
  faCircleInfo,
};
