import './login.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import useAuth from '../../hooks/auth';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import useAnalytics from '../../hooks/analytics';

import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import SEO from '../../components/seo/SEO';

export default function Login() {
  // General
  const { login } = useAuth();
  const { eventTrackerGA } = useAnalytics({ category: 'Login' });

  // States
  const [passwordShown, setPasswordShown] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  // Data
  const { mutate, isLoading, error } = useMutation({
    mutationFn: ({ loginDetail, password }) => login(loginDetail, password),
  });

  // Helpers
  const onSumbit = async (values) => {
    mutate({ loginDetail: values.login_detail, password: values.password });
  };

  return (
    <div className="login">
      <SEO title="Logga in - Sportbytarboden | Hantera din sportutrustning enkelt" />
      <section className="login-card">
        <div className="login-card_header">
          <h1>Logga in eller bli kund</h1>
        </div>
        <form
          className="login-card_form gap-3"
          onSubmit={handleSubmit(onSumbit)}
        >
          <div className="flex flex-col gap-1">
            <div className="flex justify-between">
              <label htmlFor="user-email" className="text-sm">
                Kundnummer:
              </label>
              <Link
                className="text-sm underline"
                to={'/logga-in/kundnummer'}
                onClick={() => eventTrackerGA('Forgot password', 'Loginpage')}
              >
                Glömt ditt kundnummer?
              </Link>
            </div>
            <input
              id="login_detailLogin"
              type="text"
              name="login_detail"
              className={errors?.login_detail && '!border-red-500'}
              {...register('login_detail', {
                required: 'Fältet är obligatoriskt',
              })}
            />
          </div>
          <div className="flex flex-col gap-1">
            <div className="flex justify-between">
              <label htmlFor="user-password" className="text-sm">
                Lösenord:
              </label>
              <Link
                className="text-sm underline"
                to={'/logga-in/aterstall'}
                onClick={() => eventTrackerGA('Forgot password', 'Loginpage')}
              >
                Glömt lösenord?
              </Link>
            </div>
            <div className="relative w-full">
              <input
                id="user-password"
                type={passwordShown ? 'text' : 'password'}
                name="password"
                placeholder=""
                autoComplete="false"
                className={`w-full ${
                  errors?.password ? '!border-red-500' : ''
                }`}
                {...register('password', {
                  required: 'Fältet är obligatoriskt',
                })}
              />
              {!passwordShown ? (
                <AiFillEye
                  className={'admin-adduser_password-icon'}
                  onClick={() => setPasswordShown(!passwordShown)}
                ></AiFillEye>
              ) : (
                <AiFillEyeInvisible
                  className={'admin-adduser_password-icon'}
                  onClick={() => setPasswordShown(!passwordShown)}
                ></AiFillEyeInvisible>
              )}
            </div>
          </div>
          <input
            type="submit"
            className="btn btn-secondary btn--full"
            value={isLoading ? 'Loggar in...' : 'Logga in'}
          />
          {error && <p>{error}</p>}
        </form>
        <div className="relative w-[100%] px-3 flex items-center justify-center">
          <div className="border-b w-[100%]  absolute top-1/2 translate-y-1/2"></div>
          <span className="text-sm z-10 bg-[#fafafa] xs:bg-white p-1 px-4">
            Eller bli kund
          </span>
        </div>
        <hr className="login-card_hr" />
        <nav className="login-card_nav mt-4">
          <Link
            to={'/registera'}
            onClick={() =>
              eventTrackerGA('Navigate to Signup - Loginpage', 'Loginpage')
            }
            className="btn btn-functional btn--full"
          >
            Skapa ett konto
          </Link>
          <ul className="mt-6">
            <li className="text-sm">
              Är du redan kund i butiken?{' '}
              <Link
                to={'/logga-in/begar'}
                onClick={() =>
                  eventTrackerGA(
                    'Create account already customer - Loginpage',
                    'Loginpage',
                  )
                }
              >
                Begär inloggningsuppgifter
              </Link>
            </li>
          </ul>
        </nav>
      </section>
    </div>
  );
}
