import Helmet from 'react-helmet'

function SEO({
  title,
  description,
  type = 'website',
  imageUrl,
  websiteUrl,
  companyName,
  imageAlt,
}) {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />

      <meta property="og:title" content={title} />
      <meta property="og:type" content={type} />
      <meta property="og:image" content={imageUrl} />
      <meta property="og:url" content={websiteUrl} />
      <meta property="og:description" content={description} />
      <meta property="og:site_name" content={companyName} />

      <meta name="twitter:title" content={title} />
      <meta name="twitter:card" content={'summary_large_image'} />
      <meta name="twitter:image:alt" content={imageAlt} />
      <meta name="twitter:url" content={imageAlt} />
    </Helmet>
  )
}

export default SEO
