import './dashboard.scss';
import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';

import {
  getSalesAnalytics,
  getUsersAnalytics,
} from '../../../../api/core/analytics';
import { getWeekIntervalDates } from '../../../../utils/date';
import { swedishCurrencyFormatter } from '../../../../utils/format-text';

import { LineChart } from '@mui/x-charts/LineChart';
import GraphWithTitle from '../../../../components/graphs/GraphWithTitle';
import { Icon, Icons } from '../../../../components/icon/Icon';
import SkeletonBox from '../../../../components/loaders/SkeletonBox';

import ContentfulLogo from '../../../../assets/logos/contentful-logo.png';
import BrevoLogo from '../../../../assets/logos/brevo-logo.png';

export default function Dashboard() {
  const [userDateInterval, setUserDateInterval] = useState({
    startDate: getWeekIntervalDates().startDate,
    endDate: getWeekIntervalDates().endDate,
    query: 'week',
  });
  const [salesDateInterval, setSalesDateInterval] = useState({
    startDate: getWeekIntervalDates().startDate,
    endDate: getWeekIntervalDates().endDate,
    query: 'week',
  });

  const {
    data: salesData,
    isLoading: salesLoading,
    isSuccess: salesSuccess,
  } = useQuery(['salesAnalytics', salesDateInterval], () =>
    getSalesAnalytics({
      startDate: salesDateInterval.startDate,
      endDate: salesDateInterval.endDate,
    }).then((res) => res.data),
  );
  const {
    data: userData,
    isLoading: userLoading,
    isSuccess: userSuccess,
  } = useQuery(['userAnalytics', userDateInterval], () =>
    getUsersAnalytics({
      startDate: userDateInterval.startDate,
      endDate: userDateInterval.endDate,
    }).then((res) => res.data),
  );

  function formatDataXAxis(data) {
    const dates = [];
    data.forEach((item) => {
      dates.push(item.timestamp);
    });

    return dates;
  }

  function formatDataYAxis(data) {
    const sales = [];
    data.forEach((item) => {
      sales.push(Math.ceil(item.total_amount));
    });

    return sales;
  }

  return (
    <section className="dashboard w-full max-w-screen-xl">
      <h1>Dashboard</h1>

      <section className="mb-8 flex flex-col gap-2">
        <div className="flex justify-between items-center">
          <h2 className="text-xl">Analys</h2>
          <Link to={'/admin/analytics'} className="btn btn-functional">
            Mer analyser
            <Icon icon={Icons.faChevronRight} />
          </Link>
        </div>
        <div className="grid grid-cols-2 gap-2">
          <div className="bg-white rounded-lg border w-full p-4">
            <div className="flex  justify-between mb-2">
              <div className="">
                <h3 className="text-md font-semibold">Försäljning</h3>
                {!salesLoading && salesSuccess ? (
                  <p>
                    {swedishCurrencyFormatter.format(salesData?.totalAmount)}
                  </p>
                ) : (
                  <SkeletonBox height={24} className={'rounded min-w-48'} />
                )}
              </div>
              <div className="flex gap-1 items-center">
                <button
                  className={`btn  btn--xs ${
                    salesDateInterval.query === 'year'
                      ? 'btn-admin'
                      : 'btn-functional'
                  }`}
                  onClick={() => {
                    const currentYear = new Date().getFullYear();
                    const startDate = `${currentYear}-01-01`;
                    const endDate = `${currentYear}-12-31`;

                    setSalesDateInterval({
                      startDate: startDate,
                      endDate: endDate,
                      query: 'year',
                    });
                  }}
                >
                  År
                </button>
                <button
                  className={`btn btn--xs ${
                    salesDateInterval.query === 'month'
                      ? 'btn-admin'
                      : 'btn-functional'
                  }`}
                  onClick={() => {
                    const today = new Date();
                    const currentYear = today.getFullYear();
                    const currentMonth = today.getMonth() + 1; // Months are 0-indexed in JS

                    const startDate = `${currentYear}-${currentMonth
                      .toString()
                      .padStart(2, '0')}-01`;
                    const endDate = new Date(currentYear, currentMonth, 0)
                      .toISOString()
                      .split('T')[0]; // Last day of the month

                    setSalesDateInterval({
                      startDate: startDate,
                      endDate: endDate,
                      query: 'month',
                    });
                  }}
                >
                  Månad
                </button>{' '}
                <button
                  className={`btn btn--xs ${
                    salesDateInterval.query === 'week'
                      ? 'btn-admin'
                      : 'btn-functional'
                  }`}
                  onClick={() => {
                    const today = new Date();
                    const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
                    const diff =
                      today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
                    const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
                    const endOfWeek = new Date(
                      today.setDate(startOfWeek.getDate() + 6),
                    ); // End of the week (Sunday)

                    const startDate = startOfWeek.toISOString().split('T')[0];
                    const endDate = endOfWeek.toISOString().split('T')[0];

                    setSalesDateInterval({
                      startDate: startDate,
                      endDate: endDate,
                      query: 'week',
                    });
                  }}
                >
                  Vecka
                </button>
                <button
                  className={`btn btn--xs ${
                    salesDateInterval.query === 'day'
                      ? 'btn-admin'
                      : 'btn-functional'
                  }`}
                  onClick={() => {
                    const startDate = new Date(
                      new Date().setDate(new Date().getDate()),
                    );
                    startDate.setHours(0, 0, 0, 0);

                    const endDate = new Date(
                      new Date().setDate(new Date().getDate()),
                    );
                    endDate.setHours(23, 59, 59, 59);

                    setSalesDateInterval({
                      startDate: startDate,
                      endDate: endDate,
                      query: 'day',
                    });
                  }}
                >
                  Dag
                </button>
              </div>
            </div>
            {!salesLoading ? (
              <LineChart
                xAxis={[
                  {
                    data: formatDataXAxis(salesData.data),
                    scaleType: 'point',
                  },
                ]}
                yAxis={[
                  {
                    tickNumber: 10,
                    valueFormatter: (value) => {
                      return swedishCurrencyFormatter.format(value);
                    },
                  },
                ]}
                series={[
                  {
                    data: formatDataYAxis(salesData.data),
                  },
                ]}
                height={300}
                sx={{
                  '& .MuiChartsAxis-line': {
                    // display: 'none',
                  },
                  '& .MuiChartsAxis-tick': {
                    // display: 'none',
                  },
                  '& .MuiChartsAxis-tickLabel': {
                    fill: '#8c8c8c',
                  },
                }}
                //   margin={{ left: 35, right: 20, top: 15, bottom: 30 }}
                margin={{ left: 84, right: 35, top: 15, bottom: 30 }}
              />
            ) : (
              <SkeletonBox height={300} className={'rounded'} />
            )}
          </div>

          <GraphWithTitle
            title="Nya användare"
            description={`${userData?.totalAmount ?? 0} st`}
            activeInterval={userDateInterval.query}
            setInterval={setUserDateInterval}
            XAxisData={userSuccess ? formatDataXAxis(userData.data) : []}
            YAxisData={userSuccess ? formatDataYAxis(userData.data) : []}
            loading={userLoading}
          ></GraphWithTitle>
        </div>
      </section>

      <div className="grid gap-6">
        <div className="min-h-36 border bg-white rounded-xl flex   p-4 gap-1">
          <div className="h-full w-36">
            <img
              src={ContentfulLogo}
              alt=""
              srcset=""
              className="object-contain"
            />
          </div>
          <div className="flex flex-col  justify-between">
            <div className="grid gap-2">
              <h2 className="text-xl">Contentful</h2>
              <p className="max-w-prose text-sm">
                Här kan du redigera innehållet på hemsidan. Du kan ändra
                öppettider, butiksinfo, jobb- och nyhetsartiklar.
              </p>
            </div>
            <a
              href="https://app.contentful.com/spaces/yjo0ckl5ujfo/"
              className="btn btn--small btn-admin"
              target="_blank"
              rel="noreferrer"
            >
              Till Contantful
            </a>
          </div>
        </div>
        <div className="min-h-36 border bg-white rounded-xl flex   p-4 gap-1">
          <div className="h-full w-36 p-4">
            <img src={BrevoLogo} alt="" srcset="" className="object-contain" />
          </div>
          <div className="flex flex-col  justify-between">
            <div className="grid gap-2">
              <h2 className="text-xl">Brevo</h2>
              <p className="max-w-prose text-sm">
                Här kan du skicka ut nyhetsbrev till dina kunder. Du kan skapa
                och skicka ut nyhetsbrev.
              </p>
            </div>
            <a
              href="https://app.brevo.com/"
              className="btn btn--small btn-admin"
              target="_blank"
              rel="noreferrer"
            >
              Till Brevo
            </a>
          </div>
        </div>
        <div className="min-h-36 border bg-white rounded-xl flex   p-4 gap-1">
          <div className="h-full w-36 p-4">
            <img
              src={
                'https://www.gstatic.com/analytics-suite/header/suite/v2/ic_analytics.svg'
              }
              alt=""
              srcset=""
              className="object-contain"
            />
          </div>
          <div className="flex flex-col  justify-between">
            <div className="grid gap-2">
              <h2 className="text-xl">Google Analytics</h2>
              <p className="max-w-prose text-sm">
                Här kan du se statistik över hemsidan. Du kan se hur många
                besökare som besökt hemsidan, hur länge de varit på sidan och
                mycket mer.
              </p>
            </div>
            <a
              href="https://analytics.google.com/analytics/web/"
              className="btn btn--small btn-admin"
              target="_blank"
              rel="noreferrer"
            >
              Till Google Analytics
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
