import './spinner.scss';
import React from 'react';

const Spinner = ({ width = 20, height = 20 }) => {
  return (
    <div
      id="loading"
      style={{
        width,
        height,
      }}
    ></div>
  );
};

export default Spinner;
