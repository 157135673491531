import './signup.scss';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import { useNotification } from '../../hooks/useNotification';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import useAuth from '../../hooks/auth';

import { userRegisterOptions } from '../../constants/form';

import SEO from '../../components/seo/SEO';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

export default function Signup() {
  // General
  const { signupNewCustomer } = useAuth();
  const dispatch = useNotification();

  const [bankDetails, setBankDetails] = useState(true);
  const [passwordShown, setPasswordShown] = useState(false);

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    getValues,
    setError,
  } = useForm({
    mode: 'onBlur',
  });

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (values) => signupNewCustomer(values),
    onError: (error) => {
      if (
        error.response.data.message ===
        'En användare hittades med den e-postadressen eller telefonnummret.'
      ) {
        setError('email', {
          type: 'manual',
          message: error.response.data.message,
        });
        setError('phone_number', {
          type: 'manual',
          message: error.response.data.message,
        });
      } else {
        dispatch({
          type: 'ERROR',
          message: error.response.data.message || 'Kunde inte skapa konto',
        });
      }
      window.scrollTo(0, 0);
    },
  });

  function onSubmit(data) {
    if (isLoading) return;
    mutate(data);
  }

  function toggleBankDetails() {
    setBankDetails(!bankDetails);
    if (bankDetails === true) {
      setValue('comments', 'Bankuppgifter registreras senare');
      setValue('bank', '');
      setValue('bankaccount', '');
    } else {
      setValue('comments', '');
    }
  }

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  return (
    <>
      <SEO title="Bli kund hos Sportbytarboden | Sälj och köp begagnad sportutrustning smidigt" />

      <div className="signup">
        <section className="signup_modal">
          <div className="signup_modal-container">
            <h1 className="text-xl sm: text-2xl">
              {isSuccess ? 'Ditt konto är nu skapat!' : 'Bli kund hos oss'}
            </h1>
            <p
              style={{ marginBottom: '3rem', marginTop: '.5rem' }}
              className="signup_modal-section-desc"
            >
              {isSuccess
                ? 'Nu kan du se dina inlämnade varor och utbetalningar.'
                : 'Vid registering av kund så kommer du finnas tillgänglig i vårt kassasystem för att handla och sälja.'}
            </p>

            {isSuccess && (
              <div className="">
                <div class="success-checkmark">
                  <div class="check-icon">
                    <span class="icon-line line-tip"></span>
                    <span class="icon-line line-long"></span>
                    <div class="icon-circle"></div>
                    <div class="icon-fix"></div>
                  </div>
                </div>
                <a href="/konto" className="btn btn-primary btn--full">
                  Till konto
                </a>
              </div>
            )}

            {!isSuccess && (
              <form
                onSubmit={handleSubmit(onSubmit)}
                className="signup_modal-form flex flex-col gap-6"
                autoComplete="off"
              >
                <div className="grid gap-1">
                  <h2 className="mb-2">Kontaktuppgifter</h2>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="user-email" className="text-sm">
                      Epost:
                    </label>
                    <input
                      id="user-email"
                      type="text"
                      name="email"
                      placeholder="Epost"
                      className={`rounded ${
                        errors?.email ? '!border-red-500' : ''
                      }`}
                      {...register('email', userRegisterOptions.email)}
                    />
                    <small className="text-red-600">
                      {errors?.email && errors.email.message}
                    </small>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="user-phone" className="text-sm">
                      Telefon:
                    </label>
                    <input
                      id="user-phone"
                      type="text"
                      name="phone"
                      placeholder="Ex. 0712345678"
                      className={errors?.phone_number && '!border-red-500'}
                      {...register(
                        'phone_number',
                        userRegisterOptions.phone_number,
                      )}
                    />
                    <small className="text-red-600">
                      {errors?.phone_number && errors.phone_number.message}
                    </small>
                  </div>
                </div>
                <div className="">
                  <h2 className="mb-2">Lösenord</h2>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="user-password" className="text-sm">
                      Lösenord:
                    </label>
                    <div className="relative w-full">
                      <input
                        id="user-password"
                        type={passwordShown ? 'text' : 'password'}
                        name="password"
                        placeholder="Minst 8 tecken"
                        autoComplete="false"
                        className={`w-full ${
                          errors?.password ? '!border-red-500' : ''
                        }`}
                        {...register('password', userRegisterOptions.password)}
                      />
                      {!passwordShown ? (
                        <AiFillEye
                          className={'admin-adduser_password-icon'}
                          onClick={togglePassword}
                        ></AiFillEye>
                      ) : (
                        <AiFillEyeInvisible
                          className={'admin-adduser_password-icon'}
                          onClick={togglePassword}
                        ></AiFillEyeInvisible>
                      )}
                    </div>
                    <small className="text-red-600">
                      {errors?.password && errors.password.message}
                    </small>
                  </div>
                </div>
                <div className="grid gap-1">
                  <div>
                    <h2>Personuppgifter</h2>
                    <small className="signup_modal-section-desc">
                      Behövs vid handel och försäljning av begagnande varor.{' '}
                    </small>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="user-first-name" className="text-sm">
                      Förnamn:
                    </label>
                    <input
                      id="user-first-name"
                      type="text"
                      name="first_name"
                      className={errors?.first_name && '!border-red-500'}
                      {...register(
                        'first_name',
                        userRegisterOptions.first_name,
                      )}
                    />
                    <small className="text-red-600">
                      {errors?.first_name && errors.first_name.message}
                    </small>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="user-last-name" className="text-sm">
                      Efternamn:
                    </label>
                    <input
                      id="user-last-name"
                      type="text"
                      name="last_name"
                      className={errors?.last_name && '!border-red-500'}
                      {...register('last_name', userRegisterOptions.last_name)}
                    />
                    <small className="text-red-600">
                      {errors?.last_name && errors.last_name.message}
                    </small>
                  </div>

                  <div className="flex flex-col gap-1">
                    <label htmlFor="user-address" className="text-sm">
                      Adress:
                    </label>
                    <input
                      id="user-address"
                      type="text"
                      name="address_1"
                      className={errors?.address_1 && '!border-red-500'}
                      {...register('address_1', userRegisterOptions.address_1)}
                    />
                    <small className="text-red-600">
                      {errors?.address_1 && errors.address_1.message}
                    </small>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="user-address_2" className="text-sm">
                      Adress 2:
                    </label>
                    <input
                      id="user-address_2"
                      type="text"
                      name="address_2"
                      className={errors?.address_2 && '!border-red-500'}
                      {...register('address_2', userRegisterOptions.address_2)}
                    />
                    <small className="text-red-600">
                      {errors?.address_2 && errors.address_2.message}
                    </small>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="user-city" className="text-sm">
                      Stad:
                    </label>
                    <input
                      id="user-city"
                      type="text"
                      name="address_2"
                      className={errors?.city && '!border-red-500'}
                      {...register('city', userRegisterOptions.city)}
                    />
                    <small className="text-red-600">
                      {errors?.city && errors.city.message}
                    </small>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="state" className="text-sm">
                      Ort:
                    </label>
                    <input
                      id="state"
                      type="text"
                      name="state"
                      className={errors?.state && '!border-red-500'}
                      {...register('state', userRegisterOptions.state)}
                    />
                    <small className="text-red-600">
                      {errors?.state && errors.state.message}
                    </small>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="user-zip" className="text-sm">
                      Postnummer:
                    </label>
                    <input
                      id="user-zip"
                      type="text"
                      name="zip"
                      className={errors?.zip && '!border-red-500'}
                      {...register('zip', userRegisterOptions.zip)}
                    />
                    <small className="text-red-600">
                      {errors?.zip && errors.zip.message}
                    </small>
                  </div>
                  <div className="flex flex-col gap-1">
                    <label htmlFor="country" className="text-sm">
                      Land:
                    </label>
                    <input
                      id="country"
                      type="text"
                      name="country"
                      className={errors?.country && '!border-red-500'}
                      {...register('country', userRegisterOptions.country)}
                    />
                    <small className="text-red-600">
                      {errors?.country && errors.country.message}
                    </small>
                  </div>
                </div>
                <div className="grid gap-1">
                  <div className="mb-1">
                    <h2>Bankuppgifter</h2>
                    <small className="">
                      Vid försäljning av varor så betalar vi ut direkt till ditt
                      bankkonto, personkonto eller bankgiro.
                    </small>
                  </div>
                  <div className="toggle-label-container">
                    <input
                      type="checkbox"
                      name="bank-later"
                      id="bank-later-signup"
                      value={true}
                      onChange={toggleBankDetails}
                    />
                    <label htmlFor="bank-later-signup" className="text-sm">
                      Registera bankuppgifter senare
                    </label>
                  </div>
                  {bankDetails && (
                    <>
                      <div className="flex flex-col gap-1">
                        <label htmlFor="user-bank" className="text-sm">
                          Bank:
                        </label>
                        <input
                          id="user-bank"
                          type="text"
                          name="bank"
                          className={errors?.bank && '!border-red-500'}
                          {...register('bank', userRegisterOptions.bank)}
                        />
                        <small className="text-red-600">
                          {errors?.bank && errors.bank.message}
                        </small>
                      </div>
                      <div className="flex flex-col gap-1">
                        <label htmlFor="user-bankaccount" className="text-sm">
                          Kontonummer med clearing:
                        </label>
                        <input
                          id="user-bankaccount"
                          type="text"
                          name="bankaccount"
                          className={errors?.bankaccount && '!border-red-500'}
                          {...register(
                            'bankaccount',
                            userRegisterOptions.bankaccount,
                          )}
                        />
                        <small
                          className={
                            errors?.bankaccount
                              ? `text-red-600`
                              : 'text-gray-500'
                          }
                        >
                          {errors?.bankaccount
                            ? errors.bankaccount.message
                            : 'För Nordea personkonto använd 3300 som clearing plus 10 siffror'}
                        </small>
                      </div>
                    </>
                  )}
                </div>

                <div className="grid gap-1.5">
                  <button
                    type="submit"
                    className="btn btn-primary btn--full"
                    disabled={isLoading}
                  >
                    Skapa konto
                    {isLoading && (
                      <div role="status">
                        <svg
                          aria-hidden="true"
                          class="inline w-4 h-4 text-gray-200 animate-spin dark:text-gray-600 fill-green-500"
                          viewBox="0 0 100 101"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                            fill="currentColor"
                          />
                          <path
                            d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                            fill="currentFill"
                          />
                        </svg>
                        <span class="sr-only">Loading...</span>
                      </div>
                    )}
                  </button>

                  <div className="">
                    <p className="text-sm text-gray-500">
                      Genom att registrera dig godkänner du våra{' '}
                      <a
                        href="/villkor"
                        className=" underline hover:no-underline"
                      >
                        villkor
                      </a>
                      .
                    </p>
                  </div>

                  <p className="text-sm mt-2">
                    Är du redan kund i butiken?{' '}
                    <Link className="underline" to={'/logga-in/begar'}>
                      Begär inloggningsuppgifter
                    </Link>
                  </p>
                </div>
              </form>
            )}
          </div>
        </section>
      </div>
    </>
  );
}
