import React from 'react';
import { LineChart } from '@mui/x-charts/LineChart';
import SkeletonBox from '../loaders/SkeletonBox';

function GraphWithTitle({
  title,
  description,
  activeInterval,
  setInterval,
  XAxisData,
  YAxisData,
  loading,
}) {
  return (
    <div className="bg-white rounded border w-full p-4">
      <div className="flex  justify-between">
        <div className="">
          <h3 className="text-md font-semibold">{title}</h3>
          <p>{description}</p>
        </div>
        <div className="flex gap-1 items-center">
          <button
            className={`btn  btn--xs ${
              activeInterval === 'year' ? 'btn-admin' : 'btn-functional'
            }`}
            onClick={() => {
              const currentYear = new Date().getFullYear();
              const startDate = `${currentYear}-01-01`;
              const endDate = `${currentYear}-12-31`;

              setInterval({
                startDate: startDate,
                endDate: endDate,
                query: 'year',
              });
            }}
          >
            År
          </button>
          <button
            className={`btn btn--xs ${
              activeInterval === 'month' ? 'btn-admin' : 'btn-functional'
            }`}
            onClick={() => {
              const today = new Date();
              const currentYear = today.getFullYear();
              const currentMonth = today.getMonth() + 1; // Months are 0-indexed in JS

              const startDate = `${currentYear}-${currentMonth
                .toString()
                .padStart(2, '0')}-01`;
              const endDate = new Date(currentYear, currentMonth, 0)
                .toISOString()
                .split('T')[0]; // Last day of the month

              setInterval({
                startDate: startDate,
                endDate: endDate,
                query: 'month',
              });
            }}
          >
            Månad
          </button>{' '}
          <button
            className={`btn btn--xs ${
              activeInterval === 'week' ? 'btn-admin' : 'btn-functional'
            }`}
            onClick={() => {
              const today = new Date();
              const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
              const diff =
                today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
              const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
              const endOfWeek = new Date(
                today.setDate(startOfWeek.getDate() + 6),
              ); // End of the week (Sunday)

              const startDate = startOfWeek.toISOString().split('T')[0];
              const endDate = endOfWeek.toISOString().split('T')[0];

              setInterval({
                startDate: startDate,
                endDate: endDate,
                query: 'week',
              });
            }}
          >
            Vecka
          </button>
          <button
            className={`btn btn--xs ${
              activeInterval === 'day' ? 'btn-admin' : 'btn-functional'
            }`}
            onClick={() => {
              const startDate = new Date(
                new Date().setDate(new Date().getDate()),
              );
              startDate.setHours(0, 0, 0, 0);

              const endDate = new Date(
                new Date().setDate(new Date().getDate()),
              );
              endDate.setHours(23, 59, 59, 59);

              // console.log(startDate);
              // console.log(endDate);
              // console.log(startDate.toISOString().split('T')[0]);
              // console.log(endDate.toISOString().split('T')[0]);

              setInterval({
                startDate: startDate,
                endDate: endDate,
                query: 'day',
              });
            }}
          >
            Dag
          </button>
        </div>
      </div>
      {!loading ? (
        <LineChart
          xAxis={[
            {
              data: XAxisData,
              scaleType: 'point',
            },
          ]}
          yAxis={[
            {
              tickNumber: 1,
            },
          ]}
          series={[
            {
              data: YAxisData,
            },
          ]}
          height={300}
          sx={{
            '& .MuiChartsAxis-line': {
              // display: 'none',
            },
            '& .MuiChartsAxis-tick': {
              // display: 'none',
            },
            '& .MuiChartsAxis-tickLabel': {
              fill: '#8c8c8c',
            },
          }}
          margin={{ left: 35, right: 20, top: 15, bottom: 30 }}
        />
      ) : (
        <SkeletonBox height={300} className={'rounded'} />
      )}
    </div>
  );
}

export default GraphWithTitle;
