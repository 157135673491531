import './productfilter.scss';
import React, { useState, useEffect } from 'react';

import { useSearchParams } from 'react-router-dom';
import useAnalytics from '../../../hooks/analytics';
import useSearchProducts from '../../../hooks/search-products';

export default function ProductFilter() {
  // General
  const { eventTrackerGA } = useAnalytics({ category: 'Product filter' });
  const [searchParams, setSearchParams] = useSearchParams();
  const { refetch, filters, isLoadingFilters, isSuccessFilters } =
    useSearchProducts();

  // States
  const [activeFilters, setActiveFilters] = useState({});

  const handleDropdown = (e) => {
    const value = e.target.value;
    const newValues = {
      ...activeFilters,
      [e.target.name]: value,
    };
    handleApplyChanges(newValues);
  };

  const handleCategory = (value) => {
    const newValues = {
      ...Object.fromEntries([...searchParams]),
      category: value,
    };

    eventTrackerGA('Produkt filter', `Kategori: ${value}`);

    if (value === activeFilters.category) {
      delete newValues.category;
    }

    handleApplyChanges(newValues);
  };

  function handleApplyChanges(newValues) {
    setActiveFilters(newValues);
    setSearchParams(newValues, { replace: true });
    setTimeout(() => refetch(), 0);
  }

  return (
    <div className="product-filter">
      <div className="product-filter_container">
        <ul className="product-filter_tags">
          {isSuccessFilters &&
            filters.map((category) => {
              return (
                <li
                  key={category.category}
                  onClick={() => handleCategory(category.category)}
                  className={`product-filter_tag ${
                    category.category === searchParams.get('category')
                      ? 'product-filter_tag--active'
                      : ''
                  }`}
                >
                  {category.category}
                </li>
              );
            })}
        </ul>
        <form className="product-filter_form hidden">
          <select
            name="category"
            onChange={handleDropdown}
            value={activeFilters.category}
            className="hidden"
          >
            <option value="">Välj kategori</option>
            {isSuccessFilters &&
              filters.map((category) => {
                return (
                  <option key={category.category} value={category.category}>
                    {category.category}
                  </option>
                );
              })}
          </select>
        </form>
      </div>
    </div>
  );
}
