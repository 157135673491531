import './user.scss';
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import ErrorLayout from '../../components/layout/error-layout';

import Profile from './profile/Profile';
import Payouts from './payouts/Payouts';
import Inventory from './inventory/Inventory';
import Workshop from './workshop/Workshop';
import HealthCheck from '../../shared/health-check/health-check';

export default function Account() {
  const [content, setContent] = useState({
    heading: '',
    text: '',
  });

  return (
    <section className="user">
      <div className="user_container">
        <div className="user_header">
          <h1>{content.heading}</h1>
          <p>{content.text}</p>
        </div>
        <div className="user_content">
          <HealthCheck>
            <Routes>
              <Route path="/" element={<Profile setContent={setContent} />} />
              <Route
                path="/utbetalningar"
                element={<Payouts setContent={setContent} />}
              />
              <Route
                path="/produkter"
                element={<Inventory setContent={setContent} />}
              />
              <Route
                path="/verkstad"
                element={<Workshop setContent={setContent} />}
              />
              <Route path="/*" element={<ErrorLayout></ErrorLayout>} />
            </Routes>
          </HealthCheck>
        </div>
      </div>
    </section>
  );
}
