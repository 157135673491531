import { validateAccount } from '../utils/validation';

export const userRegisterOptions = {
  email: {
    required: 'Epost är obligatoriskt',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Måste vara en giltig epost',
    },
  },
  phone_number: {
    required: 'Telefon är obligatoriskt',
    pattern: {
      value: /^[0-9]{3}[0-9]{3}[0-9]{4}$/,
      message: 'Måste vara ett giltig telefonnummer',
    },
  },
  first_name: { required: 'Förnamn är obligatoriskt' },
  last_name: { required: 'Efternamn är obligatoriskt' },
  address_1: { required: 'Adress är obligatoriskt' },
  address_2: {},
  city: {},
  state: {},
  zip: {
    required: 'Postnummer är obligatoriskt',
    pattern: {
      value: /^[0-9]{3} ?[0-9]{2}$/,
      message: 'Måste vara ett giltig postnummer',
    },
  },
  country: {},
  password: {
    required: 'Lösenord är obligatoriskt',
    minLength: {
      value: 8,
      message: 'Lösenordet måste vara minst 8 tecken',
    },
  },
  // passwordRepeat: {
  //   required: 'Lösenord är obligatoriskt',
  //   validate: (value, values) => {
  //     if (value !== values.password) {
  //       return 'Lösenorden matchar inte';
  //     }
  //   },
  // },
  bank: {
    pattern: {
      value: /^[a-zA-Z ]+$/,
      message: 'Måste vara enbart alfabetiska tecken',
    },
  },
  bankaccount: {
    // minLength: {
    //   value: 10,
    //   message: 'Kontonumret måste vara minst 10 tecken',
    // },
    validate: (value) =>
      validateAccount(value) || 'Måste vara ett giltigt kontonummer',
  },
};
