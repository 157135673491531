import './faq.css';
import React from 'react';

import { motion } from 'framer-motion';

import FaqItem from './faq-item/FaqItem';
import faqJson from './Faq-list.json';
import InfoText from '../../components/info-text/Infotext';
import SEO from '../../components/seo/SEO';

const MotionFaqItem = motion(FaqItem, { forwardMotionProps: true });

export default function Faq() {
  return (
    <div>
      <SEO title="Vanliga frågor - Sportbytarboden | Allt du behöver veta om köp och försäljning" />

      <InfoText heading={'Vanliga frågor'}></InfoText>
      <section className="faq-questions">
        {faqJson.map((item) => {
          return (
            <MotionFaqItem
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                transition: { duration: 0.2 },
              }}
              viewport={{ once: true }}
              question={item.q}
              answer={item.a}
            ></MotionFaqItem>
          );
        })}
      </section>
    </div>
  );
}
