import React, { useEffect, useState, useRef } from 'react';

import useScrollOnDrag from 'react-scroll-ondrag';
import useWindowSize from '../../../hooks/useWindowSize';
import { usePopup } from '../../../hooks/popup';
import useNotification from '../../../hooks/useNotification';
import useAuth from '../../../hooks/auth';
import { useQuery } from '@tanstack/react-query';

import ProductApi from '../../../api/core/product';
import { getInventoryStatistics } from '../../../api/core/user';

import { getPaginationSiblings } from '../../../utils/utils';
import { sanitizeProductName } from '../../../utils/format-text';

import PaginationMUI from '@mui/material/Pagination';
import recycleLogo from '../../../assets/logos/recylce-black.png';
import { Icon, Icons } from '../../../components';
import { Tooltip } from '@mui/material';
import config from '../../../assets/config';

function InventoryTable({ data }) {
  const tableRef = useRef();
  const { events } = useScrollOnDrag(tableRef);

  return (
    <div className="user-table_container " ref={tableRef} {...events}>
      <table className="user-table">
        <thead>
          <tr>
            <th className="user-table_sticky-cell">Vara</th>
            <th>Kategori</th>
            <th>Pris</th>
            <th>Antal</th>
            <th>Inlämnad</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((item) => {
            return <TableItem item={item} key={item.item_id}></TableItem>;
          })}
        </tbody>
      </table>
    </div>
  );
}

function TableItem({ item }) {
  const { openPopup } = usePopup();

  return (
    <tr key={item.item_id}>
      <td className="user-table_bold user-table_sticky-cell flex gap-2">
        {sanitizeProductName(item.name)}
        {item.name.includes('VILLKOR -') && (
          <Tooltip
            title="Varan har enligt villkor blivit bedömd som ej säljbar"
            arrow
            placement="top"
          >
            <button className="">
              <Icon icon={Icons.faCircleInfo}></Icon>
            </button>
          </Tooltip>
        )}
        {item.name.includes('ÅTER -') && (
          <Tooltip
            title="Varan har blivit returnerad och återlämnad"
            arrow
            placement="top"
          >
            <button className="">
              <Icon icon={Icons.faCircleInfo}></Icon>
            </button>
          </Tooltip>
        )}
      </td>
      <td>{item.category}</td>
      <td className="user-table_bold">{item.unit_price + '.00 kr'}</td>
      <td>{item.quantity}</td>
      <td>
        {item?.stock_date
          ? new Date(item.stock_date).toLocaleDateString()
          : 'Inget datum'}
      </td>
      <td>
        <span
          className={`
          user-table_tag 
          ${
            item.quantity > 0
              ? 'user-table_tag--active'
              : 'user-table_tag--inactive'
          }
      `}
        >
          {item.quantity > 0 ? 'I lager' : 'Såld'}
        </span>
      </td>
      <td>
        <button
          className="user-table_item-button underline"
          onClick={() => openPopup('ProductSellInfo', { itemId: item.item_id })}
        >
          Mer info
        </button>
      </td>
    </tr>
  );
}

export default function UserInventory({ setContent }) {
  // General
  const [height, width] = useWindowSize();
  const paginationSiblings = getPaginationSiblings(width);
  const dispatch = useNotification();
  const { user } = useAuth();

  // const { data, error, isLoading } = useQuery(['userInventoryStatistics'], () =>
  //   getInventoryStatistics({
  //     startDate: '2017-09-24',
  //     endDate: '2024-12-31',
  //     userId: user.customerId,
  //   }),
  // );

  // console.log(data, error, isLoading);

  const [page, setPage] = useState(0);

  const {
    data: inventory,
    isLoading,
    isInitialLoading,
  } = useQuery(['accountInventory', page], () =>
    ProductApi.getAllCustomerProducts(user.customerId, page)
      .then((res) => res.data)
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          message: error.response.data.message,
        });
      }),
  );

  useEffect(() => {
    setContent({
      heading: 'Inlämningshistorik',
      text: 'Här kan du se alla dina inlämnade varor och dess status',
    });
  }, []);

  const handlePaginationChange = async (e, value) => {
    // Page starts at 0 and pagination starts at 1
    setPage(value - 1);
  };

  return (
    <section className="user_section-container user-inventory">
      {isInitialLoading && (
        <div className="h-72 flex justify-center flex-col items-center gap-4">
          <div className="h-1/2  max-h-24">
            <img
              src={config.images.recycleLogoBlack}
              alt=""
              className="object-cover h-full"
            />
          </div>
          <p className="max-w-64 text-center">Laddar inlämningar...</p>
        </div>
      )}
      {inventory && inventory.items.length > 0 && (
        <InventoryTable data={inventory}></InventoryTable>
      )}
      {inventory && inventory.items.length === 0 && (
        <div className="h-72 flex justify-center flex-col items-center gap-4">
          <div className="h-1/2 max-h-24">
            <img
              src={config.images.recycleLogoBlack}
              alt=""
              className="object-cover h-full"
            />
          </div>
          <p className="max-w-64 text-center">
            Vi hittade inga inlämningar kopplat till ditt konto
          </p>
        </div>
      )}
      {inventory?.totalPages > 1 && (
        <div className="pagination-container">
          <PaginationMUI
            count={inventory.totalPages}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
            siblingCount={paginationSiblings}
            size={width < 500 ? 'small' : ''}
            page={page + 1}
          ></PaginationMUI>
        </div>
      )}
    </section>
  );
}
