import React from 'react';
import './users.scss';

import { usePopup } from '../../../../hooks/popup';
import { useQuery } from '@tanstack/react-query';
import { useNotification } from '../../../../hooks/useNotification';

import { getUserSignIns, getUserSignUps } from '../../../../api/core/admin';

import AllUsers from './components/AllUsers';
import Spinner from '../../../../components/loaders/spinner/Spinner';

export default function AdminUsers() {
  const { openPopup } = usePopup();
  const dispatch = useNotification();

  const {
    data: recentSignIns,
    isLoading: recentSignInsLoading,
    isSuccess: recentSignInsSuccess,
  } = useQuery(
    ['recentSignIns'],
    () => getUserSignIns().then((res) => res.data),
    {
      onError: (error) => {
        dispatch({
          type: 'ERROR',
          message: error.response.data.message,
        });
      },
    },
  );
  const {
    data: recentSignUps,
    isLoading: recentSignUpsLoading,
    isSuccess: recentSignUpsSuccess,
  } = useQuery(
    ['recentSignUps'],
    () => getUserSignUps().then((res) => res.data),
    {
      onError: (error) => {
        dispatch({
          type: 'ERROR',
          message: error.response.data.message,
        });
      },
    },
  );

  return (
    <div className="users max-w-screen-xl">
      <section className="flex items-center mb-2 ">
        <h1 className="!mb-0">Användare</h1>

        <button
          className="btn btn-admin btn-small ml-auto"
          onClick={() => openPopup('AddUser', {})}
        >
          Lägg till
        </button>
      </section>
      <section className="mb-4 grid grid-cols-1 md:grid-cols-2 gap-4 min-h-96">
        <section className="bg-white px-4 py-2 rounded-xl border min-h-96 md:min-h-full">
          <h2 className="py-2 font-medium">Senaste inloggningar </h2>
          {recentSignInsLoading && (
            <div className="h-56">
              <Spinner></Spinner>
            </div>
          )}
          {recentSignInsSuccess &&
            recentSignIns?.items.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center justify-between py-2  ${
                    index !== recentSignIns.items.length - 1
                      ? 'border-bd border-gray-200'
                      : ''
                  }`}
                >
                  <div>
                    <h3 className="text-sm font-semibold">{item.email}</h3>
                    <p className="text-xs text-gray-500">{item.name}</p>
                  </div>
                  <div>
                    <p className="text-xs text-gray-500 text-end">
                      {new Date(item.createdAt).toLocaleString()}
                    </p>
                  </div>
                </div>
              );
            })}
          {recentSignInsSuccess && recentSignIns?.items.length === 0 && (
            <p className="text-sm text-gray-500 py-4 text-center">
              Ingen aktivitet hittades
            </p>
          )}
        </section>
        <section className="bg-white px-4 py-2 rounded-xl border min-h-96 md:min-h-full">
          <h2 className="py-2 font-medium">Senaste registeringar </h2>
          {recentSignUpsLoading && (
            <div className="h-56">
              <Spinner></Spinner>
            </div>
          )}
          {recentSignUpsSuccess &&
            recentSignUps?.items.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`flex items-center justify-between py-2  ${
                    index !== recentSignUps.items.length - 1
                      ? 'border-b border-gray-200'
                      : ''
                  }`}
                >
                  <div>
                    <h3 className="text-sm font-semibold">{item.email}</h3>
                    <p className="text-xs text-gray-500">{item.name}</p>
                  </div>
                  <div>
                    <p className="text-xs text-gray-500">
                      {new Date(item.createdAt).toLocaleString()}
                    </p>
                  </div>
                </div>
              );
            })}
          {recentSignUpsSuccess && recentSignUps.items.length === 0 && (
            <p className="text-sm text-gray-500 py-4 text-center">
              Ingen aktivitet hittades
            </p>
          )}
        </section>
      </section>
      <AllUsers></AllUsers>
    </div>
  );
}
