import React, { useEffect } from 'react';
import './sell.scss';

import SEO from '../../components/seo/SEO';
import { Icon, Icons } from '../../components';
import InfoText from '../../components/info-text/Infotext';
import SellCalculator from '../../components/sell-calculator/sell-calculator';

export default function Sell() {
  return (
    <>
      <SEO title="Sälj enkelt din sportutrustning hos Sportbytarboden - Sportbytarboden"></SEO>
      <section className="sell-info">
        <InfoText
          heading={'Hur fungerar det?'}
          description={
            'Med 5 enkla steg slipper du det strulet med att sälja själv'
          }
        ></InfoText>
        <div style={{ marginBottom: 40 }} className="sell-info-tips pt-8">
          <div className="tip-container">
            <div className="tip-icon tip-icon-one">1</div>
            <div className="tip-text-container">
              <h3>Samla ihop de du vill sälja</h3>
              <p>
                Samla ihop den sport och idrottsutrustning du vill sälja. Se
                till att det är helt och rent samt att vi tar emot varorna{' '}
              </p>
              <div className="tips-container">
                <div className="tips-icon">
                  <Icon icon={Icons.faLightbulb} size={'xl'} />
                </div>
                <div className="tips-text-container">
                  <h4>Vad tar vi emot till försäljning?</h4>
                  <p>
                    Utrustningen ska vara hel, rent och det får inte saknas
                    delar
                  </p>
                  <p>
                    Titta igenom våra kategorier över utrustning vi tar emot{' '}
                  </p>
                  <p>
                    Osäker om det är gör gammalt? En bra referenspunkt kan vara
                    om det fortfarande finns att köpa liknande men nytt
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="tip-container">
            <div className="tip-icon">2</div>
            <div className="tip-text-container">
              <h3>Besök våran butik</h3>
              <p>
                Ta med den utrustning som du vill sälja till våran butik och där
                kommer vår personal hjälpa er med inlämning av varorna. Du
                hittar mer information och öppettider här!{' '}
              </p>
              <div className="tips-container">
                <div className="tips-icon">
                  <Icon icon={Icons.faLightbulb} size={'xl'} />
                </div>
                <div className="tips-text-container">
                  <h4>Pris</h4>
                  <p>
                    Vet du inte vilket pris du vill sätta på varorna så hjälper
                    vår personal med att värdera varan tillsammans
                  </p>
                  <p>
                    Om du har en ny vara så kan det hjälpa om du vet
                    inköpspriset på varan
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="tip-container">
            <div className="tip-icon">3</div>
            <div className="tip-text-container">
              <h3>Försäljning av dina varor</h3>
              <p>
                Vi tar hand om den jobbiga processen att sälja dina varor och
                ser till att de får ett nytt hem. Det hjälper till att gynna
                både miljön och plånboken. Av försäljningspriset tar
                sportbytarboden 40% upp till 4000kr(dvs max 1600kr i provision).{' '}
              </p>
            </div>
          </div>
          <SellCalculator></SellCalculator>
          <div className="tip-container">
            <div className="tip-icon">4</div>
            <div className="tip-text-container">
              <h3>Pengar direkt till ditt bankkonto</h3>
              <p>
                När dina varor är sålda så gör vi en gång i månaden en
                utbetalning till alla kunder som fått varor sålda under
                föregående månad. Vi betalar nu ut till över 2.000 nöjda kunder
                varje månad.
              </p>
            </div>
          </div>
          <div className="tip-container">
            <div className="tip-icon">5</div>
            <div className="tip-text-container">
              <h3>Blev inte din vara såld?</h3>
              <p>
                Låt inget gå till spillo, vi skickar det vi kan till välgörenhet
                eller till återvinningen.
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
