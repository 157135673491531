import './faq-item.scss';
import React, { useState, forwardRef } from 'react';
import arrowdownIcon from '../../../assets/icons/arrow-down-icon.png';

const FaqItem = forwardRef(({ question, answer }, ref) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };

  return (
    <div className="faq-item" onClick={handleOpen} ref={ref}>
      <div className="faq-item_question">
        <h2>{question}</h2>
        <img
          className={`
          faq-item_icon
          ${open ? 'open' : 'closed'}
        `}
          src={arrowdownIcon}
          alt="Arrow-down"
        />
      </div>
      <div className={`faq-item_answer ${open ? 'faq-item_answer-open' : ''}`}>
        <p>{answer}</p>
      </div>
    </div>
  );
});

export default FaqItem;
