import './adminsidebar.scss';
import { useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';

import AuthApi from '../../../../api/core/auth';

import { Icon, Icons } from '../../../../components/icon/Icon';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faHouse,
  faUsers,
  faChartSimple,
  faRightFromBracket,
  faArrowUpRightFromSquare,
} from '@fortawesome/free-solid-svg-icons';

export default function AdminSidebar({}) {
  const location = useLocation();
  const [showSidebar, setShowSidebar] = useState(false);

  const handleLogout = () => {
    AuthApi.logout();
    window.location.reload();
  };

  return (
    <div
      className={`admin-sidebar ${showSidebar ? 'admin-sidebar--show' : ''}`}
    >
      <h2>Adminpanel</h2>
      <nav>
        <ul>
          <Link to={'/admin'} className={`nav-link nav-link-admin `}>
            <li
              className={`${
                location.pathname == '/admin' ? 'nav-link-admin-active' : ''
              }`}
            >
              <FontAwesomeIcon className="admin-sidebar_icon" icon={faHouse} />
              Dashboard
            </li>
          </Link>
          <Link to={'/admin/anvandare'} className={`nav-link nav-link-admin `}>
            <li
              className={`${
                location.pathname == '/admin/anvandare'
                  ? 'nav-link-admin-active'
                  : ''
              }`}
            >
              <FontAwesomeIcon className="admin-sidebar_icon" icon={faUsers} />
              Användare
            </li>
          </Link>
          <Link to={'/admin/analytics'} className={`nav-link nav-link-admin `}>
            <li
              className={`${
                location.pathname == '/admin/analytics'
                  ? 'nav-link-admin-active'
                  : ''
              }`}
            >
              <FontAwesomeIcon
                className="admin-sidebar_icon"
                icon={faChartSimple}
              />
              Analyser
            </li>
          </Link>
        </ul>
      </nav>
      <nav className="admin-sidebar_nav-bottom">
        <ul>
          <Link to={'/'} className={`nav-link nav-link-admin`}>
            <li
              className={`${
                location.pathname == '/admin/logout'
                  ? 'nav-link-admin-active'
                  : ''
              }`}
            >
              <FontAwesomeIcon
                className="admin-sidebar_icon"
                icon={faArrowUpRightFromSquare}
              />
              Till hemsida
            </li>
          </Link>
          <li onClick={handleLogout} className={`nav-link nav-link-admin`}>
            <FontAwesomeIcon
              className="admin-sidebar_icon"
              icon={faRightFromBracket}
            />
            Logga ut
          </li>
        </ul>
      </nav>
    </div>
  );
}
