import './articlecard.scss';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import useAnalytics from '../../../hooks/analytics';

export default function ArticleCard({ article }) {
  const navigate = useNavigate();
  const { eventTrackerGA } = useAnalytics({ category: 'Article card' });

  const handleOnClick = () => {
    const ID = article.sys.id;
    navigate(`/nyheter/${ID}`);
    eventTrackerGA(`Article press, ${(ID, article?.title)}`);
  };

  return (
    <article
      onClick={handleOnClick}
      className={`article-card ${
        !article.coverImage ? 'article-card--error' : ''
      }`}
    >
      <div
        style={{
          backgroundImage: `url(${
            article.coverImage
              ? `${article.coverImage.url}`
              : 'https://sbb-bucket.fra1.digitaloceanspaces.com/logos/recycle-logo-white.svg'
          })`,
        }}
        className={`article-card_img ${
          !article.coverImage ? 'article-card_img--error' : ''
        }`}
        onClick={handleOnClick}
      ></div>
      <div className="article-card_lower">
        <h2>{article.title}</h2>
        {/* <p
          className="article-card_text"
          dangerouslySetInnerHTML={{
            __html: `${article.text.slice(0, 100)}...`,
          }}
        ></p> */}
      </div>
    </article>
  );
}
