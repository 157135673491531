export function getWeekIntervalDates() {
  const today = new Date();
  const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
  const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
  const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
  const endOfWeek = new Date(today.setDate(startOfWeek.getDate() + 6)); // End of the week (Sunday)

  const startDate = startOfWeek.toISOString().split('T')[0];
  const endDate = endOfWeek.toISOString().split('T')[0];

  return { startDate, endDate };
}
