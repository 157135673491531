import React from 'react';
import './policy.css';
import InfoText from '../../components/info-text/Infotext';
import SEO from '../../components/seo/SEO';

export default function Policy() {
  return (
    <div className="policy">
      <SEO title="Policy och villkor - Sportbytarboden | Köp och sälj sportutrustning tryggt" />
      <InfoText heading={'Allmäna villkor'}></InfoText>
      <div className="policy-container section-container">
        <p>
          Sportbytarboden är en förmedlingstjänst d.v.s. vi förmedlar affären
          mellan säljare och köpare. För detta tar vi en förmedlingsavgift.
          Förmedlingsavgiften är 40% av varans pris upp till 4000 kr.
          Förmedlingsavgiften kan därmed maximalt bli 1600kr. Om andra villkor
          gäller framgår detta på avtalet.
        </p>
        <p>
          I förmedlingsuppdraget ingår uppställning av varan i butik,
          annonsering lokalt och på utvalda internetmedier.
        </p>
        <p>
          Alla säljare skall vara registrerade hos sporbytarboden.se. Säljaren
          ansvarar för att registrerade uppgifter är korrekta och uppdaterade.
          Felaktigt eller saknat kontonummer skall korrigeras inom 3 månader
          från inlämningsdatum. Sportbytarboden gör upp till tre
          utbetalningsförsök med samtidig SMS-avisering, därefter upphör vår
          skyldighet att betala ut ersättningen.
        </p>
        <p>
          Säljaren kan när som helst avbryta försäljningsuppdraget genom att
          själv hämta ut varorna mot en administrativ avgift på 100kr.
          Säljuppdraget kvarstår dock fram till dess att varorna hämtats av
          säljaren.
        </p>

        <p>
          Gods som inlämnats för försäljning, och som ej sålts, skall avhämtas
          senast 12 månader efter inlämningsdatum. Ej uthämtat gods tillfaller
          sportbytarboden.
        </p>
        <p>
          Sportbytarboden ansvarar för inlämnat gods under maximalt 12 månader.
          Under denna tid är godset försäkrat till angivet försäljningsvärde.
          Sportbytarboden ansvarar för förlust och skada under angiven tid.
        </p>
        <p>
          Sportbytarboden ansvarar inte för varans skick. Vi kan ej heller lämna
          några garantier eller återköpsrätt då varorna ägs av säljaren.
          Eventuella reklamationer skall ske skyndsamt och återköp görs endast
          om varan bevisligen varit behäftad med fel som inte kunna upptäckas
          vid normal granskning.
        </p>
        <p>
          Sportbytarboden förmedlar ej stöldgods. Säljaren försäkrar i och med
          undertecknande av kontraktet att inlämnat gods ej är stulet.
          Sportbytarboden är hos rikspolisstyrelsen registrerad handlare och
          följer polisens riktlinjer för handel med begagnat gods. Det innebär
          legitimationskontroll av säljare samt registrering av person- och
          adressuppgifter enligt lagen om handel med begagnade varor (gäller
          stöldbegärlig egendom såsom skidor och golfutrustning). Alla säljare
          måste vara minst 18 år.
        </p>
        <p>
          Säljaren ansvarar för att varans beskrivning stämmer överens med
          varans skick, ålder och funktion och intygar att lämnade uppgifter är
          korrekta.
        </p>
        <p>
          Säljaren godkänner genom att lämna varor till försäljning att
          sportbytarboden registrerar personuppgifter enligt medt
          datasyddsförordningen GDPR.
        </p>
        <h2 class="termspage__gdprtitle">
          Information om GDPR och övrig lagstiftning
        </h2>
        <p>
          Sportbytarboden hanterar lämnade personuppgifter i enlighet med
          dataskydddförordningen GDPR. Dina uppgifter sparas endast så länge det
          krävs för att vi skall kunna uppfylla vårt redovisningsansvar gentemot
          dig som kund samt i enlighet med bokföringslagen
        </p>
        <p>
          Skatteverkets krav på kassaregister (den sk ”kassa lagen”) medför att
          vi istället för kassakvitto erbjuder kontantfaktura i samband med köp.
          Därför krävs att vi registrerar namn och adress på din kontantfaktura.
          Dessa uppgifter används inte till något annat ändamål och
          sparas/hanteras i enlighet med bokföringslagen.
        </p>
      </div>
    </div>
  );
}
