import './alertbar.scss';
import React, { useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useQuery } from '@tanstack/react-query';

import { fetchAnnouncement } from '../../../../api/contentful/contentful';

export default function Alertbar() {
  const [activeAnnouncement, setActiveAnnouncement] = useState(0);
  const { data } = useQuery(['alertbar'], () =>
    fetchAnnouncement().then((res) => {
      startInterval(res.data.length - 1);
      return { items: res.data, options: res.options };
    }),
  );

  const startInterval = async (amount) => {
    setInterval(() => {
      setActiveAnnouncement((prev) => (prev === amount ? 0 : prev + 1));
    }, 5000);
  };

  return (
    <div
      className={`alertbar ${
        data?.options && data.options.disabled === 1 && 'alertbar_disabled'
      }`}
    >
      <AnimatePresence exitBeforeEnter>
        {data?.items?.length > 0 &&
          data?.items?.map((value, i) => {
            return (
              activeAnnouncement === i && (
                <motion.span
                  key={value.text}
                  initial={{ opacity: 0, y: 50 }}
                  animate={{
                    opacity: 1,
                    y: 0,
                    transition: {
                      duration: 0.2,
                      opacity: {
                        duration: 0.3,
                      },
                    },
                  }}
                  exit={{
                    opacity: 0,
                    y: 50,
                    transition: { duration: 0.3, opacity: { duration: 0.2 } },
                  }}
                >
                  {value.text}
                </motion.span>
              )
            );
          })}
      </AnimatePresence>
    </div>
  );
}
