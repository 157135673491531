import React from 'react';

const SkeletonBox = ({ width, height, className }) => {
  return (
    <div
      className={`bg-gray-200 animate-pulse ${className}`}
      style={{ width, height }}
    ></div>
  );
};

export default SkeletonBox;
