const url = `https://graphql.contentful.com/content/v1/spaces/${process.env.REACT_APP_CONTENTFUL_SPACE_ID}`;

const query = `
{
  announcementCollection {
    items {
        text
        }
  }
  websiteConfigurationCollection {
    items {
        announcementEnabled 
    }
  }
}
`;

export const fetchAnnouncement = async () => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
    },
    body: JSON.stringify({ query }),
  });

  const respJson = await response.json();
  const { data, errors } = respJson;

  if (errors) {
    console.error(errors);
  }

  return {
    data: data.announcementCollection.items,
    options: data.websiteConfigurationCollection.items[0],
  };
};

const queryWorkshopItems = `
{
  workshopCollection {
    items {
        title
        description
        price
        category
    }
  }
}
`;

export const fetchWorkshopItems = async () => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
    },
    body: JSON.stringify({ query: queryWorkshopItems }),
  });

  const respJson = await response.json();
  const { data, errors } = respJson;

  if (errors) {
    console.error(errors);
  }

  return data.workshopCollection.items;
};
const queryWorkshopPricelist = `
{
  workshopPricelistCollection {
    items {
        title
        order
        pricelistItemsCollection {
          items {
            ... on Workshop {
              sys {
                id
              }
              title
              price
              description
              category
            }
          }
        }
     }
  }
}
`;

export const fetchWorkshopPricelist = async () => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
    },
    body: JSON.stringify({ query: queryWorkshopPricelist }),
  });

  const respJson = await response.json();
  const { data, errors } = respJson;

  if (errors) {
    console.error(errors);
  }

  return data.workshopPricelistCollection.items.sort(
    (a, b) => a.order - b.order,
  );
};

const queryArticles = `
{
  articlesCollection {
    items {
        sys {
            id
        }
        title
        coverImage {
            url
        }
        category
        text {
            json
        }
    }
  }
}
`;

export const fetchAllArticles = async () => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
    },
    body: JSON.stringify({ query: queryArticles }),
  });

  const respJson = await response.json();
  const { data, errors } = respJson;

  if (errors) {
    console.error(errors);
  }

  return data.articlesCollection.items;
};

const queryArticleById = `
query($id: String!) {
  articlesCollection(where: { sys: { id: $id } }) {
    items {
      sys {
        publishedAt
      }
      title
      coverImage {
        url
      }
      category
      text {
        json
      }
    }
  }
}
`;

export const fetchArticleById = async (id) => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
    },
    body: JSON.stringify({
      query: queryArticleById,
      variables: { id },
    }),
  });

  const respJson = await response.json();
  const { data, errors } = respJson;

  if (errors) {
    console.error(errors);
  }
  return data.articlesCollection.items[0];
};

const queryWorkArticles = `
{
  articlesCollection(where: { category: "work" }) {
    items {
        sys {
            id
        }
        title
        coverImage {
            url
        }
        category
    }
  }
}
`;

export const fetchWorkArticles = async () => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
    },
    body: JSON.stringify({ query: queryWorkArticles }),
  });

  const respJson = await response.json();
  const { data, errors } = respJson;

  if (errors) {
    console.error(errors);
  }

  return data.articlesCollection.items;
};

const queryStoreInfo = `
{
  storeInfoCollection {
    items {
        address
        postcode
        email
        phone
        region
        openHoursCollection {
          items {
            ... on StoreOpenHours {
              daysInterval
              timeInterval
              exceptional
            }
          }
        }
      }
  }
  
}
`;

export const fetchStoreInfo = async () => {
  const response = await fetch(url, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN}`,
    },
    body: JSON.stringify({ query: queryStoreInfo }),
  });

  const respJson = await response.json();
  const { data, errors } = respJson;

  if (errors) {
    console.error(errors);
  }

  const { openHoursCollection, ...rest } = data.storeInfoCollection.items[0];

  const openHoursGrouped = {
    exceptional: [],
    normal: [],
  };

  openHoursCollection.items.forEach((item) => {
    if (item.exceptional) {
      openHoursGrouped.exceptional.push(item);
    } else {
      openHoursGrouped.normal.push(item);
    }
  });

  return {
    ...rest,
    openhours: openHoursGrouped,
  };
};
