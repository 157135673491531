import './index.scss';
import React, { useState, forwardRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { swedishCurrencyFormatter } from '../../../utils/format-text';
import useAuth from '../../../hooks/auth';

export const ProductPopup = forwardRef(
  ({ product, openPopup, closePopup, showAll }, ref) => {
    // General
    const [searchParams, setSearchParams] = useSearchParams();

    const [error, setError] = useState(Boolean);

    const handleModalClose = (event) => {
      closePopup();
      searchParams.get('id');
      searchParams.delete('id');
      setSearchParams(searchParams);
    };

    const handleChildElementClick = (e) => {
      e.stopPropagation();
    };

    return (
      <div
        className="product-modal"
        onClick={(e) => handleChildElementClick(e)}
        ref={ref}
      >
        <div className="absolute right-2 top-2">
          <div className="product-modal_close" onClick={handleModalClose}></div>
        </div>
        <div className="product-modal_main">
          <div
            className={`
                    product-modal_img-container
                    ${error && 'product-modal_img-container--error'}
                    `}
          >
            <img
              className="product-modal_img"
              src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${product.item_id}.jpg`}
              alt={'product-modal' + product.name}
              onError={(e) => {
                e.target.src =
                  'https://sbb-bucket.fra1.digitaloceanspaces.com/logos/recycle-logo-white.svg';
                e.target.classList.add('product-modal_img--error');
                setError(true);
              }}
            />
            {error && <p>Bild saknas</p>}
          </div>
          <div className="w-full flex flex-col py-6">
            <div className="px-4 sm:px-8 flex flex-col grow gap-6">
              <h3 className="font-semibold text-2xl">
                {product.name}{' '}
                <span className="text-gray-500">
                  {showAll && `(${product.item_number})`}
                </span>
              </h3>
              <div className="">
                <div className="border-b p-1 max-w-[300px]">
                  <div className="flex">
                    <h2 className="w-full text-sm">Beskriving</h2>
                    <p className="w-full">{product.size_colour}</p>
                  </div>
                </div>
                <div className="border-b p-1 max-w-[300px]">
                  <div className="flex">
                    <h2 className="w-full text-sm">Antal i lager</h2>
                    <p className="w-full">{product.quantity}</p>
                  </div>
                </div>
              </div>
              <p className="font-semibold text-4xl mb-6">
                {swedishCurrencyFormatter.format(product.unit_price)}
              </p>
              <button
                className="btn btn--full btn-primary mt-auto"
                onClick={handleModalClose}
              >
                Stäng
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  },
);

export default ProductPopup;
