import './userprofile.scss';
import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { useNotification } from '../../../hooks/useNotification';
import useAuth from '../../../hooks/auth';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';

import ChangePassword from './components/ChangePassword';

import { getCustomerByPersonId, updateCustomer } from '../../../api/core/user';
import { getAllCustomerProducts } from '../../../api/core/product';
import { validateAccount } from '../../../utils/validation';
import { usePopup } from '../../../hooks/popup';

const registerOptions = {
  email: {
    required: 'Epost är obligatoriskt',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Måste vara en giltig epost',
    },
  },
  phone_number: {
    required: 'Telefon är obligatoriskt',
    pattern: {
      value: /^[0-9]{3}[0-9]{3}[0-9]{4}$/,
      message: 'Måste vara ett giltig telefonnummer',
    },
  },
  first_name: { required: 'Förnamn är obligatoriskt' },
  last_name: { required: 'Efternamn är obligatoriskt' },
  address_1: { required: 'Adress är obligatoriskt' },
  address_2: {},
  city: {},
  state: {},
  zip: {
    required: 'Postnummer är obligatoriskt',
    pattern: {
      value: /^[0-9]{3} ?[0-9]{2}$/,
      message: 'Måste vara ett giltig postnummer',
    },
  },
  country: {},

  // Is handled in component
  // bank: {},
  // bankaccount: {},
};

export default function UserProfile({ setContent }) {
  // General
  const dispatch = useNotification();
  const { user } = useAuth();
  const { openPopup } = usePopup();

  // States
  const [hasInventory, setHasInventory] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors, isDirty },
    reset,
  } = useForm({
    mode: 'onBlur',
    defaultValues: () =>
      getCustomerByPersonId(user.customerId)
        .then((res) => res.data)
        .catch((error) => {
          dispatch({
            type: 'ERROR',
            message: 'Kunde inte hämta kontouppgifter',
          });
        })
        .finally(() => {
          checkUserInventory();
        }),
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (values) => updateCustomer(user.customerId, values),
    onSuccess: () => {
      dispatch({
        type: 'SUCCESS',
        message: 'Kontouppgifter sparade',
      });
    },
    onError: () => {
      dispatch({
        type: 'ERROR',
        message: 'Kunde inte spara kontouppgifter',
      });
    },
  });

  useEffect(() => {
    setContent({
      heading: 'Konto',
      text: 'Här kan du uppdatera dina kontouppgifter',
    });
  }, []);

  async function onSubmit(values) {
    mutate(values);
  }

  function checkUserInventory() {
    getAllCustomerProducts(user.customerId)
      .then((data) => {
        // Bank account is required if customer has inventory
        if (data.data.totalItems > 0) {
          setHasInventory(true);
        } else {
          setHasInventory(false);
        }
      })
      .catch((error) => {
        setHasInventory(false);
        console.log(error);
      });
  }

  return (
    <section className="user_section-container user-profile">
      <form onSubmit={handleSubmit(onSubmit)}>
        {isDirty && (
          <div className="user-profile_header flex">
            <button
              onClick={() => reset()}
              className="btn btn--small btn-functional"
            >
              Avbryt
            </button>
            <input
              type="submit"
              className="btn btn--small btn-secondary"
              value={isLoading ? 'Sparar...' : 'Spara'}
            />
          </div>
        )}
        <div className="user-profile_inputs-container">
          <div className="user-profile_input-container">
            <div className="user-profile_input-text">
              <h3>Kontaktuppgifter</h3>
              <p>Används för att logga in och vid försäljningar av varor</p>
            </div>
            <div className="user-profile_input-inputs gap-1 flex flex-col">
              <div className="flex flex-col gap-1">
                <label htmlFor="user-email" className="text-sm">
                  Epost:
                </label>
                <input
                  id="user-email"
                  type="text"
                  name="email"
                  placeholder="Epost"
                  className={errors?.email && '!border-red-500'}
                  {...register('email', registerOptions.email)}
                />
                <small className="text-red-600">
                  {errors?.email && errors.email.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-phone" className="text-sm">
                  Telefon:
                </label>
                <input
                  id="user-phone"
                  type="text"
                  name="phone"
                  placeholder="Ex. 0712345678"
                  className={errors?.phone_number && '!border-red-500'}
                  {...register('phone_number', registerOptions.phone_number)}
                />
                <small className="text-red-600">
                  {errors?.phone_number && errors.phone_number.message}
                </small>
              </div>
            </div>
          </div>
          <div className="user-profile_input-container">
            <div className="user-profile_input-text">
              <h3>Namn</h3>
            </div>
            <div className="user-profile_input-inputs">
              <div className="flex flex-col gap-1">
                <label htmlFor="user-first-name" className="text-sm">
                  Förnamn:
                </label>
                <input
                  id="user-first-name"
                  type="text"
                  name="first_name"
                  className={errors?.first_name && '!border-red-500'}
                  {...register('first_name', registerOptions.first_name)}
                />
                <small className="text-red-600">
                  {errors?.first_name && errors.first_name.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-last-name" className="text-sm">
                  Efternamn:
                </label>
                <input
                  id="user-last-name"
                  type="text"
                  name="last_name"
                  className={errors?.last_name && '!border-red-500'}
                  {...register('last_name', registerOptions.last_name)}
                />
                <small className="text-red-600">
                  {errors?.last_name && errors.last_name.message}
                </small>
              </div>
            </div>
          </div>
          <div className="user-profile_input-container">
            <div className="user-profile_input-text">
              <h3>Adress</h3>
            </div>
            <div className="user-profile_input-inputs">
              <div className="flex flex-col gap-1">
                <label htmlFor="user-address" className="text-sm">
                  Adress:
                </label>
                <input
                  id="user-address"
                  type="text"
                  name="address_1"
                  className={errors?.address_1 && '!border-red-500'}
                  {...register('address_1', registerOptions.address_1)}
                />
                <small className="text-red-600">
                  {errors?.address_1 && errors.address_1.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-address_2" className="text-sm">
                  Adress 2:
                </label>
                <input
                  id="user-address_2"
                  type="text"
                  name="address_2"
                  className={errors?.address_2 && '!border-red-500'}
                  {...register('address_2', registerOptions.address_2)}
                />
                <small className="text-red-600">
                  {errors?.address_2 && errors.address_2.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-city" className="text-sm">
                  Stad:
                </label>
                <input
                  id="user-city"
                  type="text"
                  name="address_2"
                  className={errors?.city && '!border-red-500'}
                  {...register('city', registerOptions.city)}
                />
                <small className="text-red-600">
                  {errors?.city && errors.city.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="state" className="text-sm">
                  Ort:
                </label>
                <input
                  id="state"
                  type="text"
                  name="state"
                  className={errors?.state && '!border-red-500'}
                  {...register('state', registerOptions.state)}
                />
                <small className="text-red-600">
                  {errors?.state && errors.state.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-zip" className="text-sm">
                  Postnummer:
                </label>
                <input
                  id="user-zip"
                  type="text"
                  name="zip"
                  className={errors?.zip && '!border-red-500'}
                  {...register('zip', registerOptions.zip)}
                />
                <small className="text-red-600">
                  {errors?.zip && errors.zip.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="country" className="text-sm">
                  Land:
                </label>
                <input
                  id="country"
                  type="text"
                  name="country"
                  className={errors?.country && '!border-red-500'}
                  {...register('country', registerOptions.country)}
                />
                <small className="text-red-600">
                  {errors?.country && errors.country.message}
                </small>
              </div>
            </div>
          </div>
          <div className="user-profile_input-container">
            <div className="user-profile_input-text">
              <h3>Bankuppgifer</h3>
              <p>
                Används för utbetalning vid försäljning av varor. Dessa
                uppgifter kan enbart användas för att sätta in pengar på ett
                konto och anses enligt GDPR{' '}
                <Link to="">(dataskyddsförordningen)</Link> inte vara känsliga
                personuppgifter.
              </p>
            </div>
            <div className="user-profile_input-inputs gap-1 flex flex-col">
              <div className="flex flex-col gap-1">
                <label htmlFor="user-bank" className="text-sm">
                  Bank:
                </label>
                <input
                  id="user-bank"
                  type="text"
                  name="bank"
                  className={errors?.bank && '!border-red-500'}
                  {...register('bank', {
                    ...(hasInventory && {
                      required:
                        'Bank är obligatoriskt om du har inlämnade varor',
                    }),
                  })}
                />
                <small className="text-red-600">
                  {errors?.bank && errors.bank.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-bankaccount" className="text-sm">
                  Kontonummer med clearing:
                </label>
                <input
                  id="user-bankaccount"
                  type="text"
                  name="bankaccount"
                  className={errors?.bankaccount && '!border-red-500'}
                  {...register('bankaccount', {
                    ...(hasInventory && {
                      required:
                        'Kontonummer är obligatoriskt om du har inlämnade varor',
                      validate: (value) =>
                        validateAccount(value) ||
                        'Måste vara ett giltigt kontonummer',
                    }),
                  })}
                />
                <small
                  className={
                    errors?.bankaccount ? `text-red-600` : 'text-gray-500'
                  }
                >
                  {errors?.bankaccount
                    ? errors.bankaccount.message
                    : 'För Nordea personkonto använd 3300 som clearing plus 10 siffror'}
                </small>
              </div>
            </div>
          </div>
        </div>
      </form>
      <ChangePassword></ChangePassword>
      <div className="user-profile_input-container">
        <div className="user-profile_input-text">
          <h3 className="">Ta bort din kundprofil</h3>
          <p>
            Om du tar bort din kundprofil kommer all din data att raderas. Detta
            inkluderar dina inlämnade varor och försäljningshistorik. Du kan
            inte ta bort din kundprofil om du har inlämnade varor i lager.
          </p>
        </div>
        <div className="user-profile_input-inputs gap-1 flex flex-col">
          <div className="flex flex-col gap-1 items-end">
            <button
              className="btn btn--small btn-danger btn--full"
              onClick={() =>
                openPopup('DeleteUser', { userId: user.customerId })
              }
            >
              Ta bort kundprofil
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}
