import './workshop.scss';
import React from 'react';
import { Link } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import {
  fetchWorkshopItems,
  fetchWorkshopPricelist,
} from '../../api/contentful/contentful';

import { Icon, Icons } from '../../components';
import WorkshopItem from './components/WorkshopItem';
import SkeletonBox from '../../components/loaders/SkeletonBox';
import SEO from '../../components/seo/SEO';

const categoryOrder = [
  'alpint',
  'snowboard',
  'langdskidor',
  'skridskor',
  'ovrigt',
];

export default function Workshop() {
  const { data: pricelistData, isLoading } = useQuery(
    ['workshopPricelist'],
    () => fetchWorkshopPricelist(),
  );

  function formatCategory(category) {
    switch (category) {
      case 'alpint':
        return 'Alpint';
      case 'langdskidor':
        return 'Längdskidor';
      case 'snowboard':
        return 'Snowboard';
      case 'skridskor':
        return 'Skridskor';
      case 'ovrigt':
        return 'Övrigt';
      default:
        return category;
    }
  }

  return (
    <>
      <SEO title="Verkstad - Sportbytarboden | Stockholms bästa och billigaste verkstad"></SEO>

      <section className="hero-text workshop-hero mb-8">
        <div className="hero-text_img">
          <h1>Verkstad</h1>
          <p>Du kan nu logga in för att få se alla till dina verkstadsordrar</p>
          <Link to={'/konto/verkstad'} className="btn btn-medium btn-primary">
            Se dina verkstadsordrar
            <Icon icon={Icons.faChevronRight} />
          </Link>
        </div>
      </section>

      <section className="workshop-intro">
        <p>
          Vi erbjuder både Alpin och längdservice till konkurrenskraftiga
          priser. Med moderna maskiner från Wintersteiger kan vi strukturslipa
          både Längdskidor, Alpina skidor och Snowboards
        </p>
        <p>
          Vi kan numera erbjuda motsvarigheten till de populära ”LJ02/LJ03”
          strukturerna för längdskidor. Vi erbjuder givetvis även övriga
          strukturer för exempelvis grov och blöt snö
        </p>

        <p>
          För alpina skidor används en standardmässigt en normal-fin bruten
          struktur, men vi kan erbjuda en stor mängd strukturer och kantvinklar
          beroende på önskemål.
        </p>

        <p>Vallaservice erbjuds under säsong normalt sett på en dag.</p>
      </section>
      <section className="workshop-list">
        <div className="workshop-list-box">
          <h2>Prislista</h2>
          {isLoading && <SkeletonBox width="100%" height="50px" />}
          {pricelistData &&
            pricelistData.map((item, i) => {
              return (
                <ul className="workshop-list-category" key={i + item.title}>
                  <h3>{formatCategory(item.title)}</h3>
                  {item.pricelistItemsCollection.items.map((item, i) => {
                    return <WorkshopItem item={item} key={item.title} />;
                  })}
                </ul>
              );
            })}
        </div>
        <div className="flex flex-col gap-3 w-full max-w-[1000px] mt-8">
          <div className="">
            <h2 className="text-2xl md:text-3xl">Har du frågor om serivce?</h2>
            <p>Tveka inte att kontakta oss via epost eller telefon</p>
          </div>
          <a
            href="mailto:info@sportbytarboden.se"
            target="_blank"
            rel="noreferrer"
            className="btn btn-secondary"
          >
            Kontakta oss
          </a>
        </div>
      </section>
    </>
  );
}
