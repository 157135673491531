import { create } from 'zustand';
import { persist, createJSONStorage } from 'zustand/middleware';
import { healthCheck } from '../api/core/auth';

const useLocalPersistStore = create()(
  persist(
    (set, get) => ({
      lastVisitedRoute: null,
      setLastVisitedRoute: (route) => set({ lastVisitedRoute: route }),

      userButtonOpen: false,
      setUserButtonOpen: (open) => set({ userButtonOpen: open }),

      newsletterPopup: {
        hasShown: false,
        hasSubmitted: false,
        lastShown: null,
      },
      setNewsletterPopup: ({ hasShown, hasSubmitted, lastShown }) =>
        set({ newsletterPopup: { hasShown, hasSubmitted, lastShown } }),
      setNewsletterPopupHasSubmitted: (hasSubmitted) =>
        set({ newsletterPopup: { ...get().newsletterPopup, hasSubmitted } }),

      cookiesAccepted: false,
      setCookiesAccepted: (accepted) => set({ cookiesAccepted: accepted }),

      healthCheck: 'loading',
      getHealthCheck: async () => {
        try {
          await healthCheck();
          set({ healthCheck: 'success' });
        } catch (error) {
          set({ healthCheck: 'error' });
        }
      },
    }),
    {
      name: 'local-persist-storage',
      partialize: (state) => ({
        newsletterPopup: state.newsletterPopup,
        cookiesAccepted: state.cookiesAccepted,
      }),
    },
  ),
);

export default useLocalPersistStore;
