import React from 'react';
import './infotext.scss';

export default function InfoText({ subheading, heading, description }) {
  return (
    <section className="info-text-container">
      <div className="text-container">
        <h4>{subheading}</h4>
        <h1>{heading}</h1>
        <p className="max-w-prose">{description}</p>
      </div>
    </section>
  );
}
