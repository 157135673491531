import React, { useContext, createContext, useReducer } from 'react';
import { v4 } from 'uuid';
import Notification from '../components/notification/Notification';

export const NotificationContext = createContext();

export function useNotification() {
  const dispatch = useContext(NotificationContext);

  return (props) => {
    dispatch({
      type: 'ADD_NOTIFICATION',
      payload: {
        id: v4(),
        ...props,
      },
    });
  };
}

const NotificationProvider = (props) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch (action.type) {
      case 'ADD_NOTIFICATION':
        return [...state, { ...action.payload }];
      case 'REMOVE_NOTIFICATION':
        return state.filter((el) => el.id !== action.id);
      default:
        return state;
    }
  }, []);

  return (
    <NotificationContext.Provider value={dispatch}>
      <div className="notification-wrapper">
        {state.map((notis) => {
          return (
            <Notification
              dispatch={dispatch}
              key={notis.id}
              {...notis}
            ></Notification>
          );
        })}
      </div>
      {props?.children}
    </NotificationContext.Provider>
  );
};

export default NotificationProvider;
