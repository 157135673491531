import './products.scss';
import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link } from 'react-router-dom';

import { motion } from 'framer-motion';
import config from '../../assets/config';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

import useSearchProducts from '../../hooks/search-products';
import useAnalytics from '../../hooks/analytics';
import useAuth from '../../hooks/auth';
import { usePopup } from '../../hooks/popup';
import { useInView } from 'react-intersection-observer';

import ProductAPI from '../../api/core/product';

import ProductCard from '../../components/cards/product-card/ProductCard';
import ProductFilter from './product-filter/ProductFilter';
import InfoText from '../../components/info-text/Infotext';
import { Icon, Icons } from '../../components';
import SEO from '../../components/seo/SEO';
import HealthCheck from '../../shared/health-check/health-check';

const MotionProductCard = motion(ProductCard, { forwardMotionProps: true });

export default function Products() {
  // General
  const [searchParams, setSearchParams] = useSearchParams();
  const { openPopup } = usePopup();
  const { eventTrackerGA } = useAnalytics({ category: 'Produkter' });
  const { ref, inView } = useInView({
    threshold: 1.0,
  });
  const { user } = useAuth();

  // Data
  const { data, fetchNextPage, isSuccess, isLoading, hasNextPage } =
    useSearchProducts();

  //States
  const [showProducts] = useState(true);
  const productsInView = useMemo(() => {
    if (isSuccess) {
      let x = 0;
      data.pages.forEach((page) => (x += page.data.items.length));
      return x;
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (searchParams.get('id')) handleModal(searchParams.get('id'));
  }, []);

  // useEffect(() => {
  //   if (inView && hasNextPage) {
  //     fetchNextPage();
  //   }
  // }, [inView, hasNextPage]);

  const handleProductCardClick = (productId) => {
    handleModal(productId);

    setSearchParams({
      ...Object.fromEntries([...searchParams]),
      id: productId,
    });
  };

  const handleModal = async (id) => {
    const resp = await ProductAPI.getProduct(id);
    openPopup('Product', {
      product: resp.data,
      showAll: user ? user.roles.includes('ROLE_ADMIN') : false,
    });
  };

  return (
    <>
      <SEO title="Sportutrustning - Sportbytarboden | Begagnade skidor, cyklar, hockey- och racketsportutrustning" />
      <div className="products-page">
        <div className="mb-0 sm:mb-8">
          <InfoText
            heading={'Alla produkter'}
            description={
              'Här hittar du alla produkter som är tillgängliga i butiken just nu. Hittar du inte det du söker? Kontakta oss så hjälper vi dig att hitta rätt.'
            }
          ></InfoText>
        </div>
        <HealthCheck>
          {showProducts ? (
            <div className="products-search">
              <ProductFilter></ProductFilter>
              {isSuccess && data.pages[0].data.items.length > 0 && (
                <div className="products-container">
                  <div className="products">
                    {data.pages.map((page) =>
                      page.data.items.map((product) => (
                        <MotionProductCard
                          key={product.item_id}
                          product={product}
                          onClick={() =>
                            handleProductCardClick(product.item_id)
                          }
                          initial={{ opacity: 0 }}
                          whileInView={{
                            opacity: 1,
                            transition: { duration: 0.3, delay: 0.05 },
                          }}
                          viewport={{ once: true }}
                          isAdmin={user && user.roles.includes('ROLE_ADMIN')}
                        ></MotionProductCard>
                      )),
                    )}
                  </div>
                  <div className="products-pagination mb-6">
                    <p>
                      Visar {productsInView} av {data.pages[0].data.totalItems}{' '}
                      produkter
                    </p>
                    {hasNextPage && (
                      <button
                        className="btn btn-medium btn-primary group"
                        onClick={() => {
                          eventTrackerGA('Visa fler produkter');
                          fetchNextPage();
                        }}
                        type="button"
                      >
                        Visa fler
                        <FontAwesomeIcon
                          icon={faChevronDown}
                          className="inline-block -translate-y-0.5 group-hover:animate-bounce"
                        ></FontAwesomeIcon>
                      </button>
                    )}
                  </div>
                </div>
              )}
              {isSuccess && data.pages[0].data.items.length < 1 && (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  <img
                    style={{ maxHeight: '100px' }}
                    src={config.images.recycleLogoBlack}
                    alt="recycle logo"
                  />
                  <h2>Din sökning gav inga resultat.</h2>
                </div>
              )}
            </div>
          ) : (
            <div>
              <h2>Just nu kan vi inte visa några av våra produkter</h2>
              <p>Hör gärna av dig om du har frågor</p>
            </div>
          )}
        </HealthCheck>
        <section className="flex justify-center mt-8 md:mt-16">
          <div className="flex flex-col gap-6 md:gap-10 w-full max-w-[1110px]  px-4">
            <div className="grid gap-2">
              <h2 className="text-2xl md:text-3xl">
                Sälj din utrustning, enkelt och smidigt
              </h2>
              <p className="max-w-prose">
                Sportbytarboden är ett enkelt och bekvämt sätt att sälja och
                köpa sport & idrottsutrustning. Vi hjälper dig att sälja det du
                inte behöver och sparar tillsammans på miljön
              </p>
            </div>
            <Link to="/salj" className="btn btn-secondary">
              Läs mer om att sälja
              <Icon icon={Icons.faChevronRight} />
            </Link>
          </div>
        </section>
      </div>
    </>
  );
}
