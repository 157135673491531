import authHeader from '../shared/auth-header.js';
import http from '../shared/http-common.js';
import { url } from '../../constants/endpoints.js';
import { variableMapper } from '../../utils/utils.js';

export function getSalesAnalytics(data) {
  return http.get(url.ANALYTICS_SALES_ADMIN, {
    params: { startDate: data.startDate, endDate: data.endDate },
    headers: authHeader(),
  });
}
export function getPurchaseAnalytics(data) {
  return http.get(url.ANALYTICS_PURCHASE_ADMIN, {
    params: { startDate: data.startDate, endDate: data.endDate },
    headers: authHeader(),
  });
}
export function getPaymentsAnalytics(data) {
  return http.get(url.ANALYTICS_PAYMENTS_ADMIN, {
    params: { startDate: data.startDate, endDate: data.endDate },
    headers: authHeader(),
  });
}
export function getReceivingItemsAnalytics(data) {
  return http.get(url.ANALYTICS_RECEIVING_ITEMS_ADMIN, {
    params: { startDate: data.startDate, endDate: data.endDate },
    headers: authHeader(),
  });
}
export function getReceivingsAnalytics(data) {
  return http.get(url.ANALYTICS_RECEIVINGS_ADMIN, {
    params: { startDate: data.startDate, endDate: data.endDate },
    headers: authHeader(),
  });
}
export function getUsersAnalytics(data) {
  return http.get(url.ANALYTICS_NEW_USERS_ADMIN, {
    params: { startDate: data.startDate, endDate: data.endDate },
    headers: authHeader(),
  });
}
export function getSaleItemsAnalytics(data) {
  return http.get(url.ANALYTICS_SALE_ITEMS_ADMIN, {
    params: { startDate: data.startDate, endDate: data.endDate },
    headers: authHeader(),
  });
}
// export function getInventoryAnalytics(data) {
//   return http.get(url.ANALYTICS_INVENTORY_ADMIN, {
//     params: { startDate: data.startDate, endDate: data.endDate },
//     headers: authHeader(),
//   });
// }
