import axios from 'axios';
import { logout } from '../core/auth';

const URL = process.env.REACT_APP_BASE_API_URL || 'http://localhost:8080';

export const api = axios.create({
  baseURL: URL + '/api',
  headers: {
    'Content-type': 'application/json',
  },
});

const errorHandler = (error) => {
  const statusCode = error.response?.status;

  if (statusCode === 401) {
    logout();
    return Promise.reject(error);
  }

  if (error.code === 'ERR_CANCELED') {
    return Promise.resolve();
  }

  // logging only errors that are not 401
  if (statusCode && statusCode !== 401) {
    console.error(error);
  }

  return Promise.reject(error);
};

api.interceptors.response.use(undefined, (error) => {
  return errorHandler(error);
});

export default api;
