import React, { useState } from 'react';

export default function WorkshopItem({ item }) {
  const [open, setOpen] = useState(false);

  function handleOpen() {
    setOpen(!open);
  }
  return (
    <div className="workshop-list-item">
      <div className="workshop-list-item-header">
        <div className="">
          <h4>{item.title}</h4>
          <p className="text-xs italic md:text-sm max-w-[500px]">
            {item.description}
          </p>
        </div>

        {item.price !== 0 && (
          <p className="workshop-list-item-price">{item.price}kr</p>
        )}
      </div>
    </div>
  );
}
