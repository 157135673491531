import React, { useState } from 'react';

import { useQuery } from '@tanstack/react-query';
import {
  getPaymentsAnalytics,
  getPurchaseAnalytics,
  getReceivingsAnalytics,
  getSalesAnalytics,
  getReceivingItemsAnalytics,
  getUsersAnalytics,
  getSaleItemsAnalytics,
} from '../../../../api/core/analytics';

import { LineChart } from '@mui/x-charts/LineChart';
import { swedishCurrencyFormatter } from '../../../../utils/format-text';
import SkeletonBox from '../../../../components/loaders/SkeletonBox';
import { useNotification } from '../../../../hooks/useNotification';

function Analytics() {
  const dispatch = useNotification();

  const [salesDateInterval, setSalesDateInterval] = useState({
    startDate: getWeekIntervalDates().startDate,
    endDate: getWeekIntervalDates().endDate,
    query: 'week',
  });
  const [userDateInterval, setUserDateInterval] = useState({
    startDate: getWeekIntervalDates().startDate,
    endDate: getWeekIntervalDates().endDate,
    query: 'week',
  });
  const [purchaseDateInterval, setPurchaseDateInterval] = useState({
    startDate: getWeekIntervalDates().startDate,
    endDate: getWeekIntervalDates().endDate,
    query: 'week',
  });
  const [paymentsDateInterval, setPaymentsDateInterval] = useState({
    startDate: getWeekIntervalDates().startDate,
    endDate: getWeekIntervalDates().endDate,
    query: 'week',
  });
  const [receivingItemsDateInterval, setReceivingItemsDateInterval] = useState({
    startDate: getWeekIntervalDates().startDate,
    endDate: getWeekIntervalDates().endDate,
    query: 'week',
  });
  const [receivingsDateInterval, setReceivingsDateInterval] = useState({
    startDate: getWeekIntervalDates().startDate,
    endDate: getWeekIntervalDates().endDate,
    query: 'week',
  });
  const [saleItemsDateInterval, setSaleItemsDateInterval] = useState({
    startDate: getWeekIntervalDates().startDate,
    endDate: getWeekIntervalDates().endDate,
    query: 'week',
  });

  const {
    data: salesData,
    isLoading: salesLoading,
    isSuccess: salesSuccess,
  } = useQuery(['salesAnalytics', salesDateInterval], () =>
    getSalesAnalytics({
      startDate: salesDateInterval.startDate,
      endDate: salesDateInterval.endDate,
    })
      .then((res) => res.data)
      .catch((err) =>
        dispatch({
          type: 'error',
          message: 'Kunde inte hämta försäljningsdata',
        }),
      ),
  );

  const {
    data: purchaseData,
    isLoading: purchaseLoading,
    isSuccess: purchaseSuccess,
  } = useQuery(['purchaseAnalytics', purchaseDateInterval], () =>
    getPurchaseAnalytics({
      startDate: purchaseDateInterval.startDate,
      endDate: purchaseDateInterval.endDate,
    })
      .then((res) => res.data)
      .catch((err) =>
        dispatch({
          type: 'error',
          message: 'Kunde inte hämta försäljningsdata',
        }),
      ),
  );

  const {
    data: paymentsData,
    isLoading: paymentsLoading,
    isSuccess: paymentsSuccess,
  } = useQuery(['paymentsAnalytics', paymentsDateInterval], () =>
    getPaymentsAnalytics({
      startDate: paymentsDateInterval.startDate,
      endDate: paymentsDateInterval.endDate,
    })
      .then((res) => res.data)
      .catch((err) =>
        dispatch({
          type: 'error',
          message: 'Kunde inte hämta försäljningsdata',
        }),
      ),
  );

  const {
    data: receivingItemsData,
    isLoading: receivingItemsLoading,
    isSuccess: receivingItemsSuccess,
  } = useQuery(['receivingItemsAnalytics', receivingItemsDateInterval], () =>
    getReceivingItemsAnalytics({
      startDate: receivingItemsDateInterval.startDate,
      endDate: receivingItemsDateInterval.endDate,
    })
      .then((res) => res.data)
      .catch((err) =>
        dispatch({
          type: 'error',
          message: 'Kunde inte hämta försäljningsdata',
        }),
      ),
  );

  const {
    data: receivingsData,
    isLoading: receivingsLoading,
    isSuccess: receivingsSuccess,
  } = useQuery(['receivingsAnalytics', receivingsDateInterval], () =>
    getReceivingsAnalytics({
      startDate: receivingsDateInterval.startDate,
      endDate: receivingsDateInterval.endDate,
    })
      .then((res) => res.data)
      .catch((err) =>
        dispatch({
          type: 'error',
          message: 'Kunde inte hämta försäljningsdata',
        }),
      ),
  );

  const {
    data: userData,
    isLoading: userLoading,
    isSuccess: userSuccess,
  } = useQuery(['userAnalytics', userDateInterval], () =>
    getUsersAnalytics({
      startDate: userDateInterval.startDate,
      endDate: userDateInterval.endDate,
    })
      .then((res) => res.data)
      .catch((err) =>
        dispatch({
          type: 'error',
          message: 'Kunde inte hämta försäljningsdata',
        }),
      ),
  );

  const {
    data: saleItemsData,
    isLoading: saleItemsLoading,
    isSuccess: saleItemsSuccess,
  } = useQuery(['saleItemsAnalytics', saleItemsDateInterval], () =>
    getSaleItemsAnalytics({
      startDate: saleItemsDateInterval.startDate,
      endDate: saleItemsDateInterval.endDate,
    })
      .then((res) => res.data)
      .catch((err) =>
        dispatch({
          type: 'error',
          message: 'Kunde inte hämta försäljningsdata',
        }),
      ),
  );

  function formatDataXAxis(data) {
    const dates = [];
    data.forEach((item) => {
      dates.push(item.timestamp);
    });

    return dates;
  }

  function formatDataYAxis(data) {
    const sales = [];
    data.forEach((item) => {
      sales.push(Math.ceil(item.total_amount));
    });

    return sales;
  }

  function formatInThousands(value) {
    return swedishCurrencyFormatter.format(value / 1000) + ' TKR';
  }

  function getWeekIntervalDates() {
    const today = new Date();
    const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
    const diff = today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
    const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
    const endOfWeek = new Date(today.setDate(startOfWeek.getDate() + 6)); // End of the week (Sunday)

    const startDate = startOfWeek.toISOString().split('T')[0];
    const endDate = endOfWeek.toISOString().split('T')[0];

    return { startDate, endDate };
  }

  return (
    <section className="max-w-screen-xl">
      <h1 className="">Analyser</h1>
      <div className="grid grid-cols-2 gap-2">
        <div className="bg-white rounded-lg border w-full p-4">
          <div className="flex  justify-between mb-2">
            <div className="">
              <h3 className="text-md font-semibold">Försäljning</h3>
              {!salesLoading && salesSuccess ? (
                <p>{swedishCurrencyFormatter.format(salesData?.totalAmount)}</p>
              ) : (
                <SkeletonBox height={24} className={'rounded min-w-48'} />
              )}
            </div>
            <div className="flex gap-1 items-center">
              <button
                className={`btn  btn--xs ${
                  salesDateInterval.query === 'year'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const currentYear = new Date().getFullYear();
                  const startDate = `${currentYear}-01-01`;
                  const endDate = `${currentYear}-12-31`;

                  setSalesDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'year',
                  });
                }}
              >
                År
              </button>
              <button
                className={`btn btn--xs ${
                  salesDateInterval.query === 'month'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const currentYear = today.getFullYear();
                  const currentMonth = today.getMonth() + 1; // Months are 0-indexed in JS

                  const startDate = `${currentYear}-${currentMonth
                    .toString()
                    .padStart(2, '0')}-01`;
                  const endDate = new Date(currentYear, currentMonth, 0)
                    .toISOString()
                    .split('T')[0]; // Last day of the month

                  setSalesDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'month',
                  });
                }}
              >
                Månad
              </button>{' '}
              <button
                className={`btn btn--xs ${
                  salesDateInterval.query === 'week'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
                  const diff =
                    today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
                  const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
                  const endOfWeek = new Date(
                    today.setDate(startOfWeek.getDate() + 6),
                  ); // End of the week (Sunday)

                  const startDate = startOfWeek.toISOString().split('T')[0];
                  const endDate = endOfWeek.toISOString().split('T')[0];

                  setSalesDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'week',
                  });
                }}
              >
                Vecka
              </button>
              <button
                className={`btn btn--xs ${
                  salesDateInterval.query === 'day'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const startDate = new Date(
                    new Date().setDate(new Date().getDate() - 1),
                  )
                    .toISOString()
                    .split('T')[0];
                  const endDate = new Date(
                    new Date().setDate(new Date().getDate() - 0),
                  )
                    .toISOString()
                    .split('T')[0];

                  setSalesDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'day',
                  });
                }}
              >
                Dag
              </button>
            </div>
          </div>
          {!salesLoading ? (
            <LineChart
              xAxis={[
                {
                  data: formatDataXAxis(salesData.data),
                  scaleType: 'point',
                },
              ]}
              yAxis={[
                {
                  tickNumber: 10,
                  valueFormatter: (value) => {
                    return swedishCurrencyFormatter.format(value);
                  },
                },
              ]}
              series={[
                {
                  data: formatDataYAxis(salesData.data),
                },
              ]}
              height={300}
              sx={{
                '& .MuiChartsAxis-line': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tick': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tickLabel': {
                  fill: '#8c8c8c',
                },
              }}
              //   margin={{ left: 35, right: 20, top: 15, bottom: 30 }}
              margin={{ left: 84, right: 35, top: 15, bottom: 30 }}
            />
          ) : (
            <SkeletonBox height={300} className={'rounded'} />
          )}
        </div>
        <div className="bg-white rounded-lg border w-full p-4">
          <div className="flex  justify-between mb-2">
            <div className="">
              <h3 className="text-md font-semibold">Antal köp</h3>
              {!purchaseLoading && purchaseSuccess ? (
                <p>{purchaseData?.totalAmount} st</p>
              ) : (
                <SkeletonBox height={24} className={'rounded min-w-48'} />
              )}
            </div>
            <div className="flex gap-1 items-center">
              <button
                className={`btn  btn--xs ${
                  purchaseDateInterval.query === 'year'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const currentYear = new Date().getFullYear();
                  const startDate = `${currentYear}-01-01`;
                  const endDate = `${currentYear}-12-31`;

                  setPurchaseDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'year',
                  });
                }}
              >
                År
              </button>
              <button
                className={`btn btn--xs ${
                  purchaseDateInterval.query === 'month'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const currentYear = today.getFullYear();
                  const currentMonth = today.getMonth() + 1; // Months are 0-indexed in JS

                  const startDate = `${currentYear}-${currentMonth
                    .toString()
                    .padStart(2, '0')}-01`;
                  const endDate = new Date(currentYear, currentMonth, 0)
                    .toISOString()
                    .split('T')[0]; // Last day of the month

                  setPurchaseDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'month',
                  });
                }}
              >
                Månad
              </button>{' '}
              <button
                className={`btn btn--xs ${
                  purchaseDateInterval.query === 'week'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
                  const diff =
                    today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
                  const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
                  const endOfWeek = new Date(
                    today.setDate(startOfWeek.getDate() + 6),
                  ); // End of the week (Sunday)

                  const startDate = startOfWeek.toISOString().split('T')[0];
                  const endDate = endOfWeek.toISOString().split('T')[0];

                  setPurchaseDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'week',
                  });
                }}
              >
                Vecka
              </button>
              <button
                className={`btn btn--xs ${
                  purchaseDateInterval.query === 'day'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const startDate = new Date(
                    new Date().setDate(new Date().getDate() - 1),
                  )
                    .toISOString()
                    .split('T')[0];
                  const endDate = new Date(
                    new Date().setDate(new Date().getDate() - 0),
                  )
                    .toISOString()
                    .split('T')[0];

                  setPurchaseDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'day',
                  });
                }}
              >
                Dag
              </button>
            </div>
          </div>
          {!purchaseLoading ? (
            <LineChart
              xAxis={[
                {
                  data: formatDataXAxis(purchaseData.data),
                  scaleType: 'point',
                },
              ]}
              yAxis={[
                {
                  tickNumber: 10,
                },
              ]}
              series={[
                {
                  data: formatDataYAxis(purchaseData.data),
                },
              ]}
              height={300}
              sx={{
                '& .MuiChartsAxis-line': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tick': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tickLabel': {
                  fill: '#8c8c8c',
                },
              }}
              //   margin={{ left: 35, right: 20, top: 15, bottom: 30 }}
              margin={{ left: 64, right: 35, top: 15, bottom: 30 }}
            />
          ) : (
            <SkeletonBox height={300} className={'rounded'} />
          )}
        </div>
        <div className="bg-white rounded-lg border w-full p-4">
          <div className="flex  justify-between mb-2">
            <div className="">
              <h3 className="text-md font-semibold">Utbetalningar</h3>
              {!paymentsLoading && paymentsSuccess ? (
                <p>
                  {swedishCurrencyFormatter.format(paymentsData?.totalAmount)}
                </p>
              ) : (
                <SkeletonBox height={24} className={'rounded min-w-48'} />
              )}
            </div>
            <div className="flex gap-1 items-center">
              <button
                className={`btn  btn--xs ${
                  paymentsDateInterval.query === 'year'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const currentYear = new Date().getFullYear();
                  const startDate = `${currentYear}-01-01`;
                  const endDate = `${currentYear}-12-31`;

                  setPaymentsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'year',
                  });
                }}
              >
                År
              </button>
              <button
                className={`btn btn--xs ${
                  paymentsDateInterval.query === 'month'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const currentYear = today.getFullYear();
                  const currentMonth = today.getMonth() + 1; // Months are 0-indexed in JS

                  const startDate = `${currentYear}-${currentMonth
                    .toString()
                    .padStart(2, '0')}-01`;
                  const endDate = new Date(currentYear, currentMonth, 0)
                    .toISOString()
                    .split('T')[0]; // Last day of the month

                  setPaymentsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'month',
                  });
                }}
              >
                Månad
              </button>{' '}
              <button
                className={`btn btn--xs ${
                  paymentsDateInterval.query === 'week'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
                  const diff =
                    today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
                  const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
                  const endOfWeek = new Date(
                    today.setDate(startOfWeek.getDate() + 6),
                  ); // End of the week (Sunday)

                  const startDate = startOfWeek.toISOString().split('T')[0];
                  const endDate = endOfWeek.toISOString().split('T')[0];

                  setPaymentsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'week',
                  });
                }}
              >
                Vecka
              </button>
              <button
                className={`btn btn--xs ${
                  paymentsDateInterval.query === 'day'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const startDate = new Date(
                    new Date().setDate(new Date().getDate() - 1),
                  )
                    .toISOString()
                    .split('T')[0];
                  const endDate = new Date(
                    new Date().setDate(new Date().getDate() - 0),
                  )
                    .toISOString()
                    .split('T')[0];

                  setPaymentsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'day',
                  });
                }}
              >
                Dag
              </button>
            </div>
          </div>
          {!paymentsLoading ? (
            <LineChart
              xAxis={[
                {
                  data: formatDataXAxis(paymentsData.data),
                  scaleType: 'point',
                },
              ]}
              yAxis={[
                {
                  tickNumber: 3,
                  valueFormatter: (value) => {
                    // return value / 1000 + '.00 TKR';
                    return swedishCurrencyFormatter.format(value);
                  },
                },
              ]}
              series={[
                {
                  data: formatDataYAxis(paymentsData.data),
                },
              ]}
              height={300}
              sx={{
                '& .MuiChartsAxis-line': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tick': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tickLabel': {
                  fill: '#8c8c8c',
                },
              }}
              //   margin={{ left: 35, right: 20, top: 15, bottom: 30 }}
              margin={{ left: 96, right: 35, top: 15, bottom: 30 }}
            />
          ) : (
            <SkeletonBox height={300} className={'rounded'} />
          )}
        </div>
        <div className="bg-white rounded-lg border w-full p-4">
          <div className="flex  justify-between mb-2">
            <div className="">
              <h3 className="text-md font-semibold">Inlämnade varor</h3>
              {!receivingItemsLoading && receivingItemsSuccess ? (
                <p>{receivingItemsData?.totalAmount} st</p>
              ) : (
                <SkeletonBox height={24} className={'rounded min-w-48'} />
              )}
            </div>
            <div className="flex gap-1 items-center">
              <button
                className={`btn  btn--xs ${
                  receivingItemsDateInterval.query === 'year'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const currentYear = new Date().getFullYear();
                  const startDate = `${currentYear}-01-01`;
                  const endDate = `${currentYear}-12-31`;

                  setReceivingItemsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'year',
                  });
                }}
              >
                År
              </button>
              <button
                className={`btn btn--xs ${
                  receivingItemsDateInterval.query === 'month'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const currentYear = today.getFullYear();
                  const currentMonth = today.getMonth() + 1; // Months are 0-indexed in JS

                  const startDate = `${currentYear}-${currentMonth
                    .toString()
                    .padStart(2, '0')}-01`;
                  const endDate = new Date(currentYear, currentMonth, 0)
                    .toISOString()
                    .split('T')[0]; // Last day of the month

                  setReceivingItemsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'month',
                  });
                }}
              >
                Månad
              </button>{' '}
              <button
                className={`btn btn--xs ${
                  receivingItemsDateInterval.query === 'week'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
                  const diff =
                    today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
                  const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
                  const endOfWeek = new Date(
                    today.setDate(startOfWeek.getDate() + 6),
                  ); // End of the week (Sunday)

                  const startDate = startOfWeek.toISOString().split('T')[0];
                  const endDate = endOfWeek.toISOString().split('T')[0];

                  setReceivingItemsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'week',
                  });
                }}
              >
                Vecka
              </button>
              <button
                className={`btn btn--xs ${
                  receivingItemsDateInterval.query === 'day'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const startDate = new Date(
                    new Date().setDate(new Date().getDate() - 1),
                  )
                    .toISOString()
                    .split('T')[0];
                  const endDate = new Date(
                    new Date().setDate(new Date().getDate() - 0),
                  )
                    .toISOString()
                    .split('T')[0];

                  setReceivingItemsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'day',
                  });
                }}
              >
                Dag
              </button>
            </div>
          </div>
          {!receivingItemsLoading ? (
            <LineChart
              xAxis={[
                {
                  data: formatDataXAxis(receivingItemsData.data),
                  scaleType: 'point',
                },
              ]}
              yAxis={[
                {
                  tickNumber: 3,
                },
              ]}
              series={[
                {
                  data: formatDataYAxis(receivingItemsData.data),
                },
              ]}
              height={300}
              sx={{
                '& .MuiChartsAxis-line': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tick': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tickLabel': {
                  fill: '#8c8c8c',
                },
              }}
              //   margin={{ left: 35, right: 20, top: 15, bottom: 30 }}
              margin={{ left: 64, right: 35, top: 15, bottom: 30 }}
            />
          ) : (
            <SkeletonBox height={300} className={'rounded'} />
          )}
        </div>
        <div className="bg-white rounded-lg border w-full p-4">
          <div className="flex  justify-between mb-2">
            <div className="">
              <h3 className="text-md font-semibold">Inlämningar</h3>
              {!receivingsLoading && receivingsSuccess ? (
                <p>{receivingsData?.totalAmount} st</p>
              ) : (
                <SkeletonBox height={24} className={'rounded min-w-48'} />
              )}
            </div>
            <div className="flex gap-1 items-center">
              <button
                className={`btn  btn--xs ${
                  receivingsDateInterval.query === 'year'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const currentYear = new Date().getFullYear();
                  const startDate = `${currentYear}-01-01`;
                  const endDate = `${currentYear}-12-31`;

                  setReceivingsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'year',
                  });
                }}
              >
                År
              </button>
              <button
                className={`btn btn--xs ${
                  receivingsDateInterval.query === 'month'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const currentYear = today.getFullYear();
                  const currentMonth = today.getMonth() + 1; // Months are 0-indexed in JS

                  const startDate = `${currentYear}-${currentMonth
                    .toString()
                    .padStart(2, '0')}-01`;
                  const endDate = new Date(currentYear, currentMonth, 0)
                    .toISOString()
                    .split('T')[0]; // Last day of the month

                  setReceivingsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'month',
                  });
                }}
              >
                Månad
              </button>{' '}
              <button
                className={`btn btn--xs ${
                  receivingsDateInterval.query === 'week'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
                  const diff =
                    today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
                  const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
                  const endOfWeek = new Date(
                    today.setDate(startOfWeek.getDate() + 6),
                  ); // End of the week (Sunday)

                  const startDate = startOfWeek.toISOString().split('T')[0];
                  const endDate = endOfWeek.toISOString().split('T')[0];

                  setReceivingsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'week',
                  });
                }}
              >
                Vecka
              </button>
              <button
                className={`btn btn--xs ${
                  receivingsDateInterval.query === 'day'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const startDate = new Date(
                    new Date().setDate(new Date().getDate() - 1),
                  )
                    .toISOString()
                    .split('T')[0];
                  const endDate = new Date(
                    new Date().setDate(new Date().getDate() - 0),
                  )
                    .toISOString()
                    .split('T')[0];

                  setReceivingsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'day',
                  });
                }}
              >
                Dag
              </button>
            </div>
          </div>
          {!receivingsLoading ? (
            <LineChart
              xAxis={[
                {
                  data: formatDataXAxis(receivingsData.data),
                  scaleType: 'point',
                },
              ]}
              yAxis={[
                {
                  tickNumber: 3,
                },
              ]}
              series={[
                {
                  data: formatDataYAxis(receivingsData.data),
                },
              ]}
              height={300}
              sx={{
                '& .MuiChartsAxis-line': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tick': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tickLabel': {
                  fill: '#8c8c8c',
                },
              }}
              //   margin={{ left: 35, right: 20, top: 15, bottom: 30 }}
              margin={{ left: 64, right: 35, top: 15, bottom: 30 }}
            />
          ) : (
            <SkeletonBox height={300} className={'rounded'} />
          )}
        </div>
        <div className="bg-white rounded-lg border w-full p-4">
          <div className="flex  justify-between mb-2">
            <div className="">
              <h3 className="text-md font-semibold">Nya användare</h3>
              {!userLoading && userSuccess ? (
                <p>{userData?.totalAmount} st</p>
              ) : (
                <SkeletonBox height={24} className={'rounded min-w-48'} />
              )}
            </div>
            <div className="flex gap-1 items-center">
              <button
                className={`btn  btn--xs ${
                  userDateInterval.query === 'year'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const currentYear = new Date().getFullYear();
                  const startDate = `${currentYear}-01-01`;
                  const endDate = `${currentYear}-12-31`;

                  setUserDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'year',
                  });
                }}
              >
                År
              </button>
              <button
                className={`btn btn--xs ${
                  userDateInterval.query === 'month'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const currentYear = today.getFullYear();
                  const currentMonth = today.getMonth() + 1; // Months are 0-indexed in JS

                  const startDate = `${currentYear}-${currentMonth
                    .toString()
                    .padStart(2, '0')}-01`;
                  const endDate = new Date(currentYear, currentMonth, 0)
                    .toISOString()
                    .split('T')[0]; // Last day of the month

                  setUserDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'month',
                  });
                }}
              >
                Månad
              </button>{' '}
              <button
                className={`btn btn--xs ${
                  userDateInterval.query === 'week'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
                  const diff =
                    today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
                  const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
                  const endOfWeek = new Date(
                    today.setDate(startOfWeek.getDate() + 6),
                  ); // End of the week (Sunday)

                  const startDate = startOfWeek.toISOString().split('T')[0];
                  const endDate = endOfWeek.toISOString().split('T')[0];

                  setUserDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'week',
                  });
                }}
              >
                Vecka
              </button>
              <button
                className={`btn btn--xs ${
                  userDateInterval.query === 'day'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const startDate = new Date(
                    new Date().setDate(new Date().getDate() - 1),
                  )
                    .toISOString()
                    .split('T')[0];
                  const endDate = new Date(
                    new Date().setDate(new Date().getDate() - 0),
                  )
                    .toISOString()
                    .split('T')[0];

                  setUserDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'day',
                  });
                }}
              >
                Dag
              </button>
            </div>
          </div>
          {!userLoading ? (
            <LineChart
              xAxis={[
                {
                  data: formatDataXAxis(userData.data),
                  scaleType: 'point',
                },
              ]}
              yAxis={[
                {
                  tickNumber: 1,
                  min: 0,
                },
              ]}
              series={[
                {
                  data: formatDataYAxis(userData.data),
                },
              ]}
              height={300}
              sx={{
                '& .MuiChartsAxis-line': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tick': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tickLabel': {
                  fill: '#8c8c8c',
                },
              }}
              //   margin={{ left: 35, right: 20, top: 15, bottom: 30 }}
              margin={{ left: 64, right: 35, top: 15, bottom: 30 }}
            />
          ) : (
            <SkeletonBox height={300} className={'rounded'} />
          )}
        </div>
        <div className="bg-white rounded-lg border w-full p-4">
          <div className="flex  justify-between mb-2">
            <div className="">
              <h3 className="text-md font-semibold">Sålda varor</h3>
              {!saleItemsLoading && saleItemsSuccess ? (
                <p>{saleItemsData?.totalAmount} st</p>
              ) : (
                <SkeletonBox height={24} className={'rounded min-w-48'} />
              )}
            </div>
            <div className="flex gap-1 items-center">
              <button
                className={`btn  btn--xs ${
                  saleItemsDateInterval.query === 'year'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const currentYear = new Date().getFullYear();
                  const startDate = `${currentYear}-01-01`;
                  const endDate = `${currentYear}-12-31`;

                  setSaleItemsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'year',
                  });
                }}
              >
                År
              </button>
              <button
                className={`btn btn--xs ${
                  saleItemsDateInterval.query === 'month'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const currentYear = today.getFullYear();
                  const currentMonth = today.getMonth() + 1; // Months are 0-indexed in JS

                  const startDate = `${currentYear}-${currentMonth
                    .toString()
                    .padStart(2, '0')}-01`;
                  const endDate = new Date(currentYear, currentMonth, 0)
                    .toISOString()
                    .split('T')[0]; // Last day of the month

                  setSaleItemsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'month',
                  });
                }}
              >
                Månad
              </button>{' '}
              <button
                className={`btn btn--xs ${
                  saleItemsDateInterval.query === 'week'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const today = new Date();
                  const dayOfWeek = today.getDay(); // Get the day of the week (0-6, Sun-Sat)
                  const diff =
                    today.getDate() - dayOfWeek + (dayOfWeek === 0 ? -6 : 1); // Adjust for Monday start
                  const startOfWeek = new Date(today.setDate(diff)); // Start of the week (Monday)
                  const endOfWeek = new Date(
                    today.setDate(startOfWeek.getDate() + 6),
                  ); // End of the week (Sunday)

                  const startDate = startOfWeek.toISOString().split('T')[0];
                  const endDate = endOfWeek.toISOString().split('T')[0];

                  setSaleItemsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'week',
                  });
                }}
              >
                Vecka
              </button>
              <button
                className={`btn btn--xs ${
                  saleItemsDateInterval.query === 'day'
                    ? 'btn-admin'
                    : 'btn-functional'
                }`}
                onClick={() => {
                  const startDate = new Date(
                    new Date().setDate(new Date().getDate() - 1),
                  )
                    .toISOString()
                    .split('T')[0];
                  const endDate = new Date(
                    new Date().setDate(new Date().getDate() - 0),
                  )
                    .toISOString()
                    .split('T')[0];

                  setSaleItemsDateInterval({
                    startDate: startDate,
                    endDate: endDate,
                    query: 'day',
                  });
                }}
              >
                Dag
              </button>
            </div>
          </div>
          {!saleItemsLoading ? (
            <LineChart
              xAxis={[
                {
                  data: formatDataXAxis(saleItemsData.data),
                  scaleType: 'point',
                },
              ]}
              yAxis={[
                {
                  tickNumber: 1,
                  min: 0,
                },
              ]}
              series={[
                {
                  data: formatDataYAxis(saleItemsData.data),
                },
              ]}
              height={300}
              sx={{
                '& .MuiChartsAxis-line': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tick': {
                  // display: 'none',
                },
                '& .MuiChartsAxis-tickLabel': {
                  fill: '#8c8c8c',
                },
              }}
              //   margin={{ left: 35, right: 20, top: 15, bottom: 30 }}
              margin={{ left: 64, right: 35, top: 15, bottom: 30 }}
            />
          ) : (
            <SkeletonBox height={300} className={'rounded'} />
          )}
        </div>
      </div>
    </section>
  );
}

export default Analytics;
