import React, { forwardRef } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Icon, Icons } from '../../icon/Icon';

export const SignupSuccessPopup = forwardRef(({ closePopup }, ref) => {
  const [searchParams, setSearchParams] = useSearchParams();

  function removeParamAndClose() {
    closePopup();
    searchParams.delete('signup_success');
    setSearchParams(searchParams);
  }

  return (
    <div className="">
      <button
        tabIndex={0}
        className="btn btn-icon popup-btn__close"
        onClick={removeParamAndClose}
      >
        <Icon icon={Icons.faXmark} size={40} color={'#000'}></Icon>
      </button>

      <div className="p-4 sm:p-8 mt-8 grid gap-6">
        <h2 className="text-2xl font-bold text-center">
          Tack för din registrering!
        </h2>
        <div class="success-checkmark">
          <div class="check-icon">
            <span class="icon-line line-tip"></span>
            <span class="icon-line line-long"></span>
            <div class="icon-circle"></div>
            <div class="icon-fix"></div>
          </div>
        </div>
        <div className="grid gap-2">
          <a href="/konto" className="btn btn-primary btn--full">
            Gå till ditt konto
          </a>
          <button
            className="btn btn-functional btn--full"
            onClick={removeParamAndClose}
          >
            Stäng
          </button>
        </div>
      </div>
    </div>
  );
});

export default SignupSuccessPopup;
