import React, { useEffect } from 'react';
import useLocalPersistStore from '../../store/local-persist.store';

function GeneralFetching({ children }) {
  const { getHealthCheck } = useLocalPersistStore();

  useEffect(() => {
    getHealthCheck();
  }, []);

  return children;
}

export default GeneralFetching;
