import './productcard.scss';
import React, { useState, forwardRef } from 'react';
import useAnalytics from '../../../hooks/analytics';

export const ProductCard = forwardRef(({ product, onClick, isAdmin }, ref) => {
  const { eventTrackerGA } = useAnalytics({ category: 'Produkt' });

  const [error, setError] = useState(Boolean);

  return (
    <div
      className="product-card relative"
      onClick={() => {
        onClick();
        eventTrackerGA(
          'Öppna produkt',
          `Name: ${product.name}, ID: ${product.item_id}`,
        );
      }}
      ref={ref}
    >
      {/* {!product.item_is_used && (
        <div className="absolute -right-1.5 -top-1.5 py-1 px-3 bg-green-500 z-10 rounded-full text-sm text-white">
          <span>Ny vara</span>
        </div>
      )} */}
      <div className="product-card_img-container mb-1">
        <img
          className="product-card_img"
          src={`${process.env.REACT_APP_BASE_IMAGE_URL}/${product.item_id}.jpg`}
          alt={'Image ' + product.name}
          onError={(e) => {
            e.target.src =
              'https://sbb-bucket.fra1.digitaloceanspaces.com/logos/recycle-logo-white.svg';
            e.target.classList.add('product-card_img--error');
            setError(true);
          }}
        />
        {error && <p>Bild saknas</p>}
      </div>
      <h4 className="font-semibold">{product.name}</h4>
      <p className="mb-1">{product.size_colour}</p>
      <p className="font-semibold">{product.unit_price} kr</p>
      {isAdmin && <p>Id: {product.item_number}</p>}
    </div>
  );
});

export default ProductCard;
