export const config = {
  images: {
    recycleLogoBlack:
      'https://sbb-bucket.fra1.digitaloceanspaces.com/logos/recycle-logo-new.svg',
    recycleLogoWhite:
      'https://sbb-bucket.fra1.digitaloceanspaces.com/logos/recycle-logo-white.svg',
  },
};

export default config;
