import React, { useState, useEffect } from 'react';
import './notification.scss';

export default function Notification(props) {
  const [exit, setExit] = useState(false);
  const [width, setWidth] = useState(0);
  const [intervalID, setIntervalID] = useState(null);

  const handleStartTimer = () => {
    const id = setInterval(() => {
      setWidth((prev) => {
        if (prev < 100) {
          return prev + 0.5;
        }

        return prev;
      });
    }, 40);

    setIntervalID(id);
  };

  const handlePauseTimer = () => {
    clearInterval(intervalID);
  };
  const handleCloseNotification = () => {
    setExit(true);
    handlePauseTimer();
    setTimeout(() => {
      props.dispatch({
        type: 'REMOVE_NOTIFICATION',
        id: props.id,
      });
    }, 800);
  };

  useEffect(() => {
    if (width === 100) {
      handleCloseNotification();
    }
  }, [width]);

  useEffect(() => {
    handleStartTimer();
  }, []);

  return (
    <div
      onMouseEnter={handlePauseTimer}
      onMouseLeave={handleStartTimer}
      className={`notification-item ${
        props.type === 'SUCCESS'
          ? 'notification-item--success'
          : 'notification-item--error'
      } ${exit ? 'notification-item--exit' : ''}`}
    >
      <p>{props.message}</p>
      <div
        className={`notification-item_bar`}
        style={{ width: `${width}%` }}
      ></div>
    </div>
  );
}
