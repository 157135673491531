import './userworkshop.scss';
import React, { useEffect, useState } from 'react';

import useAuth from '../../../hooks/auth.js';
import { useQuery } from '@tanstack/react-query';

import PaginationMUI from '@mui/material/Pagination';

import UserApi from '../../../api/core/user.js';
import { getPaginationSiblings } from '../../../utils/utils.js';
import config from '../../../assets/config.js';

export default function UserWorkshop({ setContent }) {
  // General
  const paginationSiblings = getPaginationSiblings();
  const { user } = useAuth();

  // States
  const [page, setPage] = useState(0);

  // Data
  const { data, isLoading, isSuccess } = useQuery(
    ['userServiceOrders', page],
    () =>
      UserApi.getServiceOrdersFromCustomerId(user.customerId).then(
        (res) => res.data,
      ),
  );

  useEffect(
    () =>
      setContent({
        heading: 'Verkstadsservice',
        text: 'Om du har lämnat in varor för service i verkstaden kan du se information om de här',
      }),
    [],
  );

  const handlePaginationChange = async (e, value) => {
    // Page starts at 0 and pagination starts at 1
    setPage(value - 1);
  };

  return (
    <section className="user_section-container user-workshop">
      {isSuccess && data.items.length === 0 && (
        <div className="h-72 flex justify-center flex-col items-center gap-4">
          <div className="h-1/2  max-h-24">
            <img
              src={config.images.recycleLogoBlack}
              alt=""
              className="object-cover h-full"
            />
          </div>
          <p className="max-w-64 text-center">
            Vi hittade inga verkstadsordrar kopplat till ditt konto
          </p>
        </div>
      )}
      {isLoading && (
        <div className="h-72 flex justify-center flex-col items-center gap-4">
          <div className="h-1/2 max-h-24">
            <img
              src={config.images.recycleLogoBlack}
              alt=""
              className="object-cover h-full	"
            />
          </div>
          <p className="max-w-64 text-center">Laddar verkstadsordrar...</p>
        </div>
      )}
      {isSuccess && data.items.length > 0 && (
        <ServiceTable data={data}></ServiceTable>
      )}
      {data?.totalPages > 1 && (
        <div className="pagination-container">
          <PaginationMUI
            count={data.totalPages}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
            siblingCount={paginationSiblings}
          ></PaginationMUI>
        </div>
      )}
    </section>
  );
}

function ServiceTable({ data }) {
  return (
    <div className="user-table_container">
      <table className="user-table">
        <thead>
          <tr>
            <th className="user-table_sticky-cell">Vara</th>
            <th>Inlämnad</th>
            <th>Klar att hämta</th>
            <th>Status</th>
            <th>Uthämtningsdatum</th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((item) => {
            const itemReady = new Date(item.date_ready) < new Date();
            return (
              <tr>
                <td className="user-table_bold user-table_sticky-cell">
                  {item.item_name}
                </td>
                <td>{item.date_in.substr(0, 10)}</td>
                <td className="user-table_bold">{item.date_ready}</td>
                <td>
                  <span
                    className={`
                       user-table_tag 
                        ${
                          item.returned
                            ? 'user-table_tag--active'
                            : itemReady
                            ? 'user-table_tag--active'
                            : 'user-table_tag--pending'
                        }
                    `}
                  >
                    {item.returned
                      ? 'Uthämtade'
                      : itemReady
                      ? 'Klara att hämta'
                      : 'Ej klar'}
                  </span>
                </td>
                <td>
                  {item.returned
                    ? item.return_date
                    : itemReady
                    ? 'Ej uthämtade'
                    : '-'}
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
