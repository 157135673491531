import { useEffect, useState, useRef } from 'react';

import useScrollOnDrag from 'react-scroll-ondrag';
import useNotification from '../../../hooks/useNotification';
import useAuth from '../../../hooks/auth';
import useWindowSize from '../../../hooks/useWindowSize';
import { useQuery } from '@tanstack/react-query';

import { getUserPayments } from '../../../api/core/user';
import { getPaginationSiblings } from '../../../utils/utils';

import PaginationMUI from '@mui/material/Pagination';
import config from '../../../assets/config';

const swedishFormatter = new Intl.NumberFormat('sv-SE', {
  style: 'currency',
  currency: 'SEK',
});

export default function UserPayouts({ setContent }) {
  const dispatch = useNotification();
  const [height, width] = useWindowSize();
  const { user } = useAuth();
  const paginationSiblings = getPaginationSiblings(width);

  const [page, setPage] = useState(0);

  const { data, isLoading, isSuccess } = useQuery(
    ['accountPayments', page],
    () => getUserPayments(user.customerId, page).then((res) => res.data),
  );

  useEffect(() => {
    setContent({
      heading: 'Utbetalningar',
      text: 'Om du har fått varor sålda så kan du se dina utbetalningar här',
    });
  }, []);

  const handlePaginationChange = async (e, value) => {
    // Page starts at 0 and pagination starts at 1
    setPage(value - 1);
  };

  return (
    <section className="user_section-container user-payments">
      {isSuccess && data.items.length === 0 && (
        <div className="h-72 flex justify-center flex-col items-center gap-4">
          <div className="h-1/2  max-h-24">
            <img
              src={config.images.recycleLogoBlack}
              alt=""
              className="object-cover h-full"
            />
          </div>
          <p className="max-w-64 text-center">
            Vi hittar inga utbetalningar kopplat till ditt konto
          </p>
        </div>
      )}
      {isLoading && (
        <div className="h-72 flex justify-center flex-col items-center gap-4">
          <div className="h-1/2 max-h-24">
            <img
              src={config.images.recycleLogoBlack}
              alt=""
              className="object-cover h-full "
            />
          </div>
          <p className="max-w-64 text-center">Laddar utbetalningar...</p>
        </div>
      )}
      {isSuccess && data.items.length > 0 && (
        <PaymentTable data={data}></PaymentTable>
      )}
      {isSuccess && data.totalPages > 1 && (
        <div className="pagination-container">
          <PaginationMUI
            count={data.totalPages}
            variant="outlined"
            shape="rounded"
            onChange={handlePaginationChange}
            siblingCount={paginationSiblings}
            size={width < 500 ? 'small' : ''}
            page={page + 1}
          ></PaginationMUI>
        </div>
      )}
    </section>
  );
}

function PaymentTable({ data, sortData }) {
  const tableRef = useRef();
  const { events } = useScrollOnDrag(tableRef);

  return (
    <div className="user-table_container" ref={tableRef} {...events}>
      <table className="user-table">
        <thead>
          <tr>
            <th className="user-table_sticky-cell">Datum</th>
            <th>Summa</th>
            <th>Antal varor</th>
            <th>Kontonummer</th>
          </tr>
        </thead>
        <tbody>
          {data.items.map((item) => {
            return (
              <tr key={item.item_id}>
                <td className="user-table_bold user-table_sticky-cell">
                  {item.transaction_date}
                </td>
                <td>{swedishFormatter.format(item.total_amount)}</td>
                <td>{item.total_items}</td>
                <td>{item.receiver_account}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
