import React, { useEffect, Suspense } from 'react';
import { Routes, Route, useLocation } from 'react-router-dom';
import { useJwt } from 'react-jwt';

import { logout } from '../../api/core/auth';
import useLocalPersistStore from '../../store/local-persist.store.js';
import { usePopup } from '../../hooks/popup.jsx';

import ProtectedRoute from '../../components/protected-route/ProtectedRoute';

import Homepage from '../../pages/homepage/Homepage';
import Header from '../../components/layout/header/Header';
import Footer from '../../components/layout/footer/Footer';
import Store from '../../pages/store/Store';
import Faq from '../../pages/faq/Faq.jsx';
import Products from '../../pages/products/Products';
import Workshop from '../../pages/workshop/Workshop';
import Sell from '../../pages/sell/Sell';
import About from '../../pages/about/About';
import Policy from '../../pages/policy/Policy';
import Login from '../../pages/login/Login';
import Admin from '../../pages/admin/Admin';
import User from '../../pages/account/Account';
import Signup from '../../pages/signup/Signup';
import OverlayButton from '../../components/overlay-button/OverlayButton';
import Articles from '../../pages/articles/Articles';
import ResetPassword from '../../pages/login/reset-password/ResetPassword';
import GetPassword from '../../pages/login/get-password/GetPassword';
import useAuth from '../../hooks/auth.js';
import ErrorLayout from '../../components/layout/error-layout.js';
import RequestCustomerDetails from '../../pages/login/request-customer-details/RequestCustomerDetails.jsx';

function Layout() {
  // General
  const location = useLocation();
  const { user } = useAuth();
  const { isExpired } = useJwt(user?.accessToken ?? '');
  const isAdminPath = location.pathname.includes('/admin');
  const { openPopup } = usePopup();

  const { newsletterPopup, setNewsletterPopup, cookiesAccepted } =
    useLocalPersistStore();

  // Router change
  useEffect(() => {
    if (isExpired && user?.accessToken) {
      logout();
    }
  }, [location.pathname]);

  useEffect(() => {
    if (!cookiesAccepted) {
      return;
    }

    if (
      (newsletterPopup.hasShown && !newsletterPopup.hasSubmitted) ||
      !newsletterPopup.hasShown
    ) {
      const THREE_DAYS = 3 * 24 * 60 * 60 * 1000;

      if (
        !newsletterPopup.lastShown ||
        new Date() - new Date(newsletterPopup.lastShown) > THREE_DAYS
      ) {
        setTimeout(() => {
          openPopup('Newsletter');
          setNewsletterPopup({
            hasShown: true,
            hasSubmitted: false,
            lastShown: new Date().toISOString(),
          });
        }, 5000);
      }
    }
  }, [cookiesAccepted]);

  return (
    <div className={`App`}>
      {!isAdminPath && <Header></Header>}
      {user?.roles?.includes('ROLE_ADMIN') && !isAdminPath && (
        <OverlayButton></OverlayButton>
      )}

      <main className={isAdminPath ? `` : 'mb-12'}>
        <Suspense fallback={<div>Loading</div>}></Suspense>
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route path="/butik" element={<Store />} />
          <Route path="/produkter" element={<Products />} />
          <Route path="/produkter/:id" element={<Products />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/verkstad" element={<Workshop />} />
          <Route path="/salj" element={<Sell />} />
          <Route path="/om" element={<About />} />
          <Route path="/villkor" element={<Policy />} />
          <Route path="/nyheter/*" element={<Articles />} />
          <Route
            element={<ProtectedRoute isAllowed={!user} redirectPath="/konto" />}
          >
            <Route path="/logga-in" element={<Login />} />
            <Route path="/logga-in/aterstall" element={<ResetPassword />} />
            <Route path="/logga-in/begar" element={<GetPassword />} />
            <Route
              path="/logga-in/kundnummer"
              element={<RequestCustomerDetails />}
            />
            <Route path="/registera" element={<Signup />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAllowed={
                  user &&
                  (user.roles.includes('ROLE_USER') ||
                    user.roles.includes('ROLE_ADMIN'))
                }
                redirectPath="/logga-in"
              />
            }
          >
            <Route path="/konto/*" element={<User />} />
          </Route>
          <Route
            element={
              <ProtectedRoute
                isAllowed={!!user && user.roles.includes('ROLE_ADMIN')}
                redirectPath="/"
              />
            }
          >
            <Route path="/admin/*" element={<Admin />} />
          </Route>
          <Route path="/*" element={<ErrorLayout></ErrorLayout>} />
        </Routes>
      </main>
      {!isAdminPath && <Footer></Footer>}
    </div>
  );
}

export default Layout;
