import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useAnalytics } from '../../hooks/analytics';

const GATrackingId = 'G-4S7QTH0C54';

export function Analytics(props) {
  const location = useLocation();
  const { pageViewGA, pageViewHotjar, analyticsInitialized } = useAnalytics({
    GATrackingId,
  });

  useEffect(() => {
    if (analyticsInitialized) {
      pageViewGA(location.pathname);
      pageViewHotjar(location.pathname);
    }
  }, [location]);

  return props.children;
}

export default Analytics;
