import './adduser.scss';
import React, { useState, useRef, forwardRef } from 'react';

import { useNotification } from '../../../hooks/useNotification';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import { debounce } from 'lodash';
import { capitalizeFirstLetter } from '../../../utils/format-text';

import { AiOutlineCloseCircle } from 'react-icons/ai';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';

import { getAllCustomers } from '../../../api/core/user';
import { createUser, getAllUsers } from '../../../api/core/admin';
import { userRegisterOptions } from '../../../constants/form';
import { validateAccount } from '../../../utils/validation';

const userRoles = ['user', 'admin'];

export const AddUser = forwardRef(({ closePopup }, ref) => {
  // General
  const dispatch = useNotification();

  const [passwordShown, setPasswordShown] = useState(false);
  const [customers, setCustomers] = useState([]);
  const [customer, setCustomer] = useState();
  const [selectedRoles, setSelectedRoles] = useState([userRoles[0]]);

  const inputRef = useRef();

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
    setValue,
    getValues,
  } = useForm({
    mode: 'onBlur',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (values) => createUser(values),
    onSuccess: () => {
      dispatch({
        type: 'SUCCESS',
        message: 'Konto skapat',
      });
      closePopup();
    },
    onError: (error) => {
      dispatch({
        type: 'ERROR',
        message: error.response.data.message || 'Kunde inte skapa konto',
      });
    },
  });

  function onSubmit(data) {
    mutate(data);
  }

  const handleCustomerSearch = debounce(async (e) => {
    console.log(e.target.value);
    if (e.target.value.length < 1) return setCustomers([]);
    setCustomer();
    const query = e.target.value;

    try {
      const searchResult = await getAllCustomers(query);
      console.log(searchResult);
      setCustomers(searchResult.data);
    } catch (error) {}
  }, 350);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const handleResetSearchField = () => {
    inputRef.current.value = '';
    setCustomers([]);
    setCustomer();
    setValue('person_id', '');
  };

  const handleUserRoleChange = async (role) => {
    const isRoleSelected = selectedRoles.includes(role);
    const updatedRoles = isRoleSelected
      ? selectedRoles.filter((r) => r !== role)
      : [...selectedRoles, role];

    setSelectedRoles(updatedRoles);
    setValue('roles', updatedRoles);
  };

  const handleCustomerChoosen = async (customer) => {
    try {
      const checkExisiting = await getAllUsers({
        query: customer.person_id,
      });

      if (checkExisiting.data.totalItems > 0) {
        return dispatch({
          type: 'ERROR',
          message: 'Finns redan ett konto kopplat till den kunden!',
        });
      }

      setCustomer(customer);
      setValue('person_id', customer.person_id);

      setCustomers([]);
    } catch (error) {
      dispatch({
        type: 'ERROR',
        message: error.response.data.message,
      });
    }
  };

  return (
    <section className="admin-adduser admin-block">
      <div className="admin-adduser__header mb-4">
        <h2 className="text-xl sm:text-2xl">Lägg till användare</h2>
      </div>
      <form autoComplete="off" onSubmit={handleSubmit(onSubmit)}>
        <div className="input-label-container">
          <label htmlFor="roles-user-admin">Roller:</label>
          <input type="hidden" {...register('roles')} value={selectedRoles} />
          <div className="add-user-roles">
            {userRoles.map((role, i) => {
              const isActive = selectedRoles.includes(role);
              return (
                <div
                  className={`
                add-user-roles__item
                ${isActive && 'add-user-roles__item--active'}
                `}
                  key={i}
                  onClick={() => handleUserRoleChange(role)}
                >
                  <span>{capitalizeFirstLetter(role)}</span>
                </div>
              );
            })}
          </div>
        </div>

        <div className="">
          <div className="flex flex-col gap-1">
            <label htmlFor="user-password" className="text-sm">
              Lösenord:
            </label>
            <div className="relative w-full">
              <input
                id="user-password"
                type={passwordShown ? 'text' : 'password'}
                name="password"
                placeholder="Minst 8 tecken"
                autoComplete="false"
                className={`w-full ${
                  errors?.password ? '!border-red-500' : ''
                }`}
                {...register('password', userRegisterOptions.password)}
              />
              {!passwordShown ? (
                <AiFillEye
                  className={'admin-adduser_password-icon'}
                  onClick={togglePassword}
                ></AiFillEye>
              ) : (
                <AiFillEyeInvisible
                  className={'admin-adduser_password-icon'}
                  onClick={togglePassword}
                ></AiFillEyeInvisible>
              )}
            </div>
            <small className="text-red-600">
              {errors?.password && errors.password.message}
            </small>
          </div>
          <div className="flex flex-col gap-1">
            <label htmlFor="user-password-repeat" className="text-sm">
              Upprepa lösenord:
            </label>
            <input
              id="user-password-repeat"
              type={passwordShown ? 'text' : 'password'}
              name="passwordRepeat"
              autoComplete="false"
              className={`rounded ${
                errors?.passwordRepeat ? '!border-red-500' : ''
              }`}
              {...register(
                'passwordRepeat',
                userRegisterOptions.passwordRepeat,
              )}
            />
            <small className="text-red-600">
              {errors?.passwordRepeat && errors.passwordRepeat.message}
            </small>
          </div>
        </div>

        <div
          className={`admin-adduser_customer-search ${
            customer ? 'admin-adduser_customer-search--active' : ''
          }`}
        >
          <div className="input-label-container">
            <label htmlFor="customer-search-query">
              Koppla till befintlig kund
            </label>
            <div className="input-dropdown-container">
              <input
                // onBlur={() => setCustomers([])}
                // onFocus={handleCustomerSearch}
                ref={inputRef}
                type="text"
                placeholder="Sök på kundens namn, epost eller kundnummer"
                name="query"
                id="customer-search-query"
                value={
                  customer?.first_name &&
                  `${customer.first_name} ${customer?.last_name}`
                }
                onChange={handleCustomerSearch}
                className={`
                            admin-input 
                            admin-adduser_customer-search-input
                            ${
                              customers &&
                              !customer &&
                              'admin-adduser_customer-search-input--active'
                            }
                        `}
              />
              {inputRef.current?.value.length > 0 && (
                <AiOutlineCloseCircle
                  onClick={handleResetSearchField}
                  className="admin-adduser_customer-search-input-icon"
                />
              )}
              {customers.length > 0 && (
                <div className="admin-adduser_dropdown">
                  <ul>
                    {customers.map((customer) => {
                      return (
                        <li
                          onClick={() => handleCustomerChoosen(customer)}
                          className="admin-adduser_dropdown-item"
                        >
                          <span className="admin-adduser_dropdown-item--name">
                            {`${customer.first_name} ${customer.last_name}`}
                          </span>
                          &#8226;
                          <span className="">{`${customer.person_id}`}</span>
                          &#8226;
                          <span>{customer.email}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>
        {/* {user?.person_id && (
          <div className="admin-input_container mb-2">
            <label htmlFor="admin-addressInput">Kund nummer:</label>
            <p>{user.person_id}</p>
          </div>
        )} */}

        {!getValues('person_id') && (
          <>
            <div className="">
              <h2 className="text-sm">Eller skapa en ny kund</h2>
            </div>
            <div className="">
              <div className="flex flex-col gap-1">
                <label htmlFor="user-email" className="text-sm">
                  Epost:
                </label>
                <input
                  id="user-email"
                  type="text"
                  name="email"
                  placeholder="Epost"
                  className={`rounded ${
                    errors?.email ? '!border-red-500' : ''
                  }`}
                  {...register('email', userRegisterOptions.email)}
                />
                <small className="text-red-600">
                  {errors?.email && errors.email.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-phone" className="text-sm">
                  Telefon:
                </label>
                <input
                  id="user-phone"
                  type="text"
                  name="phone"
                  placeholder="Ex. 0712345678"
                  className={errors?.phone_number && '!border-red-500'}
                  {...register(
                    'phone_number',
                    userRegisterOptions.phone_number,
                  )}
                />
                <small className="text-red-600">
                  {errors?.phone_number && errors.phone_number.message}
                </small>
              </div>
            </div>
            <div className="">
              <div className="flex flex-col gap-1">
                <label htmlFor="user-first-name" className="text-sm">
                  Förnamn:
                </label>
                <input
                  id="user-first-name"
                  type="text"
                  name="first_name"
                  className={errors?.first_name && '!border-red-500'}
                  {...register('first_name', userRegisterOptions.first_name)}
                />
                <small className="text-red-600">
                  {errors?.first_name && errors.first_name.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-last-name" className="text-sm">
                  Efternamn:
                </label>
                <input
                  id="user-last-name"
                  type="text"
                  name="last_name"
                  className={errors?.last_name && '!border-red-500'}
                  {...register('last_name', userRegisterOptions.last_name)}
                />
                <small className="text-red-600">
                  {errors?.last_name && errors.last_name.message}
                </small>
              </div>
            </div>

            <div className="">
              <div className="flex flex-col gap-1">
                <label htmlFor="user-address" className="text-sm">
                  Adress:
                </label>
                <input
                  id="user-address"
                  type="text"
                  name="address_1"
                  className={errors?.address_1 && '!border-red-500'}
                  {...register('address_1', userRegisterOptions.address_1)}
                />
                <small className="text-red-600">
                  {errors?.address_1 && errors.address_1.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-address_2" className="text-sm">
                  Adress 2:
                </label>
                <input
                  id="user-address_2"
                  type="text"
                  name="address_2"
                  className={errors?.address_2 && '!border-red-500'}
                  {...register('address_2', userRegisterOptions.address_2)}
                />
                <small className="text-red-600">
                  {errors?.address_2 && errors.address_2.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-city" className="text-sm">
                  Stad:
                </label>
                <input
                  id="user-city"
                  type="text"
                  name="address_2"
                  className={errors?.city && '!border-red-500'}
                  {...register('city', userRegisterOptions.city)}
                />
                <small className="text-red-600">
                  {errors?.city && errors.city.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="state" className="text-sm">
                  Ort:
                </label>
                <input
                  id="state"
                  type="text"
                  name="state"
                  className={errors?.state && '!border-red-500'}
                  {...register('state', userRegisterOptions.state)}
                />
                <small className="text-red-600">
                  {errors?.state && errors.state.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-zip" className="text-sm">
                  Postnummer:
                </label>
                <input
                  id="user-zip"
                  type="text"
                  name="zip"
                  className={errors?.zip && '!border-red-500'}
                  {...register('zip', userRegisterOptions.zip)}
                />
                <small className="text-red-600">
                  {errors?.zip && errors.zip.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="country" className="text-sm">
                  Land:
                </label>
                <input
                  id="country"
                  type="text"
                  name="country"
                  className={errors?.country && '!border-red-500'}
                  {...register('country', userRegisterOptions.country)}
                />
                <small className="text-red-600">
                  {errors?.country && errors.country.message}
                </small>
              </div>
            </div>
            <div className="">
              <div className="flex flex-col gap-1">
                <label htmlFor="user-bank" className="text-sm">
                  Bank:
                </label>
                <input
                  id="user-bank"
                  type="text"
                  name="bank"
                  className={errors?.bank && '!border-red-500'}
                  {...register('bank', userRegisterOptions.bank)}
                />
                <small className="text-red-600">
                  {errors?.bank && errors.bank.message}
                </small>
              </div>
              <div className="flex flex-col gap-1">
                <label htmlFor="user-bankaccount" className="text-sm">
                  Kontonummer med clearing:
                </label>
                <input
                  id="user-bankaccount"
                  type="text"
                  name="bankaccount"
                  className={errors?.bankaccount && '!border-red-500'}
                  {...register('bankaccount', {
                    validate: (value) =>
                      validateAccount(value) ||
                      'Måste vara ett giltigt kontonummer',
                  })}
                />
                <small
                  className={
                    errors?.bankaccount ? `text-red-600` : 'text-gray-500'
                  }
                >
                  {errors?.bankaccount
                    ? errors.bankaccount.message
                    : 'För Nordea personkonto använd 3300 som clearing plus 10 siffror'}
                </small>
              </div>
            </div>
          </>
        )}

        <div className="flex flex-col gap-2">
          <input
            type="submit"
            value={isLoading ? 'Laddar...' : 'Skapa användare'}
            className="btn btn-admin btn--full"
            disabled={isLoading}
          />
          <button
            disabled={isLoading}
            onClick={() => closePopup()}
            type="button"
            className="btn btn-functional btn--full"
          >
            Avbryt
          </button>
        </div>
      </form>
    </section>
  );
});

export default AddUser;
