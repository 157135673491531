import React, { forwardRef } from 'react';

import { useNotification } from '../../../hooks/useNotification';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import useAnalytics from '../../../hooks/analytics';

import { subscribeToNewsletter } from '../../../api/core/user';

import Spinner from '../../loaders/spinner-2/spinner';
import { Icon, Icons } from '../../icon/Icon';
import useLocalPersistStore from '../../../store/local-persist.store';

export const NewsletterPopup = forwardRef(({ closePopup }, ref) => {
  const dispatch = useNotification();
  const { eventTrackerGA } = useAnalytics({ category: 'Footer' });
  const { setNewsletterPopupHasSubmitted } = useLocalPersistStore();

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ email }) => subscribeToNewsletter(email),
    onSuccess: () => {
      closePopup();
      setNewsletterPopupHasSubmitted(true);
      dispatch({
        type: 'SUCCESS',
        message: 'Du är nu registrerad!',
      });
    },
  });

  const onSubmit = async (values) => {
    if (isLoading) return;
    mutate({ email: values.email });
    eventTrackerGA('Prenumerera på nyhetsbrev');
  };

  return (
    <div className="">
      <button
        className="btn btn-icon popup-btn__close"
        onClick={() => {
          eventTrackerGA('Stäng nyhetsbrev');
          closePopup();
        }}
      >
        <Icon icon={Icons.faXmark} size={40} color={'#000'}></Icon>
      </button>
      <div className="rounded-t-xl overflow-hidden max-h-96">
        <img
          src="https://images.unsplash.com/photo-1596473536124-397c469c8506?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
          alt=""
          className="object-cover"
        />
      </div>
      <div className="p-4 sm:p-8">
        <div className="footer_newsletter">
          <h2 className="!text-xl xs:!text-3xl sm:!text-4xl   !font-semibold">
            Ta del av de senaste nyheterna
          </h2>
          <p className="!text-black !text-sm sm:!text-base">
            Prenumerera på vårt nyhetsbrev för att vara först med att ta del av
            de senaste nyheterna. Du kan vara lugn, vi spammar inte!
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="input-label-container"
          >
            <label htmlFor="email-list_email" className="hidden">
              Epost:
            </label>
            <div className="footer_newsletter-input">
              <input
                type="text"
                name="email"
                id="email-list_email"
                {...register('email', {
                  required: 'Epost kan inte vara tomt.',
                  pattern: {
                    value: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                    message: 'Epost adressen är ogiltig!',
                  },
                })}
                placeholder="Epost"
                className={errors?.email ? 'input--error' : ''}
              ></input>
              <button
                className="footer_newsletter-submit btn btn-primary"
                type="submit"
              >
                Prenumerera{isLoading && <Spinner></Spinner>}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
});

export default NewsletterPopup;
