import authHeader from '../shared/auth-header.js';
import http from '../shared/http-common.js';
import { url } from '../../constants/endpoints.js';
import { variableMapper } from '../../utils/utils.js';

export function getAllUsers(query) {
  const queryParams = {
    ...query,
  };
  return http.get(url.ADMIN_GET_ALL_USERS, {
    params: { ...queryParams },
    headers: authHeader(),
  });
}

export function getUserById(id) {
  return http.get(variableMapper(url.ADMIN_GET_USER_BY_ID, 'id', id), {
    headers: authHeader(),
  });
}

export function createUser(data) {
  return http.post(url.ADMIN_CREATE_USER, data, {
    headers: authHeader(),
  });
}

export function getUserStatistics(data) {
  const query = {
    startDate: data.startDate,
    endDate: data.endDate,
  };
  return http.get(url.ANALYTICS_NEW_USERS_ADMIN, {
    params: { ...query },
    headers: authHeader(),
  });
}

export function getInventoryStatistics(data) {
  const query = {
    startDate: data.startDate,
    endDate: data.endDate,
  };
  return http.get(url.ANALYTICS_INVENTORY_ADMIN, {
    params: { ...query },
    headers: authHeader(),
  });
}

export function getUserSignIns(page = 0) {
  return http.get(url.ADMIN_GET_ALL_SIGNINS, {
    params: {
      page,
    },
    headers: authHeader(),
  });
}

export function getUserSignUps(page = 0) {
  return http.get(url.ADMIN_GET_ALL_SIGNUPS, {
    params: {
      page,
    },
    headers: authHeader(),
  });
}

export function getUserAudit(id) {
  return http.get(variableMapper(url.ADMIN_GET_USER_AUDIT, 'id', id), {
    headers: authHeader(),
  });
}

export function getUserQuickAnalytics(id) {
  return http.get(variableMapper(url.ADMIN_GET_USER_ANALYTICS, 'id', id), {
    headers: authHeader(),
  });
}

export default {
  getAllUsers,
  getUserById,
  createUser,
  getUserStatistics,
  getInventoryStatistics,
  getUserSignIns,
  getUserSignUps,
  getUserAudit,
  getUserQuickAnalytics,
};
