import { useState } from 'react';
import { useNotification } from '../../../../hooks/useNotification';
import { requestPasswordReset } from '../../../../api/core/auth';
import { Link, useNavigate } from 'react-router-dom';
import useAnalytics from '../../../../hooks/analytics';

export default function RequestEmailReset() {
  // General
  const navigate = useNavigate();
  const dispatch = useNotification();
  const { eventTrackerGA } = useAnalytics({
    category: 'Request password reset',
  });

  // States
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({});
  const [success, setSuccess] = useState(false);

  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const forgotPasswordResp = await requestPasswordReset(
        values.emailOrPhone,
      );
      setSuccess(forgotPasswordResp.data.message);
      setTimeout(() => navigate('/logga-in'), 30000);
    } catch (error) {
      dispatch({
        type: 'ERROR',
        message: error.response.data.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const handleOnChange = (e) => {
    const value = e.target.value;
    setValues({ ...values, [e.target.name]: value });
  };

  const inputs = [
    {
      id: 1,
      name: 'emailOrPhone',
      type: 'text',
      placeholder: 'Kundnummer',
      label: 'Kundnummer:',
      // errorMessage: 'Måste vara en giltig e-post eller telefonnummer',
      required: true,
      // pattern: '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$|',
    },
  ];

  return (
    <>
      {!success ? (
        <>
          <form
            className="login-card_form gap-1.5"
            onSubmit={handleForgotPasswordSubmit}
          >
            {inputs.map((input) => {
              return (
                <input
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={handleOnChange}
                  className="mb-1"
                />
              );
            })}
            <input
              type="submit"
              value={loading ? 'Laddar...' : 'Begär återställningslänk'}
              className="btn btn-secondary btn--full"
            />
            <button
              onClick={() => navigate(-1)}
              className="btn btn-functional btn--full"
              type="button"
            >
              Tillbaka
            </button>
          </form>
          <p className="text-sm mb-6 mt-2">
            Är du redan kund i butiken?{' '}
            <Link
              to={'/logga-in/begar'}
              onClick={() =>
                eventTrackerGA(
                  'Create account already customer - Reset password',
                  'Loginpage',
                )
              }
              className="underline"
            >
              Begär inloggningsuppgifter
            </Link>
          </p>
        </>
      ) : (
        <div className="p-4 grid gap-6 w-[90%]">
          <h3
            style={{
              fontSize: '16px',
              marginTop: '0.5rem',
              textAlign: 'center',
            }}
          >
            {success}
          </h3>
          <div className="grid gap-1.5 w-full">
            <a href="/logga-in" className="btn btn-secondary btn--full">
              Logga in
            </a>
            <button
              onClick={() => navigate(-1)}
              className="btn btn-functional btn--full"
              type="button"
            >
              Tillbaka
            </button>
          </div>
        </div>
      )}
    </>
  );
}
