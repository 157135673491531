import { useSearchParams } from 'react-router-dom';
import RequestEmailReset from './components/ReqestEmailReset';
import ChangePassword from './components/ChangePassword';
import SEO from '../../../components/seo/SEO';

export default function ResetPassword() {
  const [searchParams] = useSearchParams();

  const token = searchParams.get('token');

  return (
    <div className="login">
      <SEO title="Glömt lösenord - Sportbytarboden | Återställ ditt konto för köp och försäljning" />

      <section className="login-card">
        <div className="login-card_header flex flex-col items-center gap-2">
          <h1>Återställ ditt lösenord</h1>
          <small className="max-w-96 text-center">
            {!token
              ? 'Har du glömt ditt lösenord eller tappat åtkomsten till ditt konto, då kan du få en återställningslänk till din epost.'
              : 'Vänligen ange ett nytt lösenord för att få åtkomst till ditt konto'}
          </small>
        </div>
        {token ? (
          <ChangePassword token={token}></ChangePassword>
        ) : (
          <RequestEmailReset></RequestEmailReset>
        )}
      </section>
    </div>
  );
}
