import './App.scss';
import React, { useState } from 'react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

import useAuth from './hooks/auth';

import Layout from './shared/layout/layout';
import PopupProvider from './hooks/popup';
import NotificationProvider from './hooks/useNotification';

import { UserContext } from './hooks/auth';
import Analytics from './shared/analytics/analytics';
import DrawerProvider from './hooks/drawer';
import Cookies from './components/cookie-banner/cookies';
import GeneralFetching from './shared/general/general';

const client = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
    },
  },
});

function App() {
  const { getCurrentUser } = useAuth();
  const [user, setUser] = useState(getCurrentUser());

  return (
    <QueryClientProvider client={client}>
      <UserContext.Provider value={[user, setUser]}>
        <GeneralFetching>
          <Analytics>
            <NotificationProvider>
              <PopupProvider>
                <DrawerProvider>
                  <Layout></Layout>
                  <Cookies></Cookies>
                </DrawerProvider>
              </PopupProvider>
            </NotificationProvider>
          </Analytics>
        </GeneralFetching>
      </UserContext.Provider>
    </QueryClientProvider>
  );
}

export default App;
