import './work.scss';
import React, { useEffect } from 'react';

import ArticleCard from '../../../components/cards/article-card/ArticleCard';
import SkeletonBox from '../../../components/loaders/SkeletonBox';

import { useQuery } from '@tanstack/react-query';
import { fetchWorkArticles } from '../../../api/contentful/contentful';
import SEO from '../../../components/seo/SEO';

export default function Work({ setBreadCrumbs }) {
  const { data, isSuccess, isLoading } = useQuery(
    ['articlesWork'],
    fetchWorkArticles,
  );

  useEffect(() => {
    setBreadCrumbs([
      {
        name: 'Nyheter',
        href: '/nyheter',
      },
      {
        name: 'Jobba',
        href: '/nyheter/jobba',
      },
    ]);
  }, [setBreadCrumbs]);

  return (
    <section className="work">
      <SEO title="Jobb och karriär - Sportbytarboden | Tips och artiklar om sportbranschen" />

      <div className="max-w-3xl">
        <h1>Jobba hos Sportbytarboden</h1>
        <p>
          Vi söker dig som är idrottsintresserad och har mycket god kunskap om
          alpin skidåkning eller längdåkning. Det är definitivt ett plus om du
          har tränat / tävlat på klubbnivå.
        </p>
        <p>
          Vi erbjuder timanställning med flexibla arbetsvillkor. Vi söker både
          personal för arbete vardagar samt extrapersonal på eftermiddagarna och
          helger under vintersäsongen September-Mars.
        </p>
        <p>
          Skriv några rader om dig själv. Vad du sysslar med just nu och vad du
          har för idrottsbakgrund. Bifoga också CV!
        </p>
      </div>

      <div className="work-articles">
        <h2>Lediga tjänster </h2>
        {isLoading && (
          <div className="work-articles-grid">
            <SkeletonBox
              width={'100%'}
              height={'300px'}
              className={'rounded-xl'}
            ></SkeletonBox>
            <SkeletonBox
              width={'100%'}
              height={'300px'}
              className={'rounded-xl'}
            ></SkeletonBox>
            <SkeletonBox
              width={'100%'}
              height={'300px'}
              className={'rounded-xl'}
            ></SkeletonBox>
          </div>
        )}
        {data?.length > 0 && (
          <div className="work-articles-grid">
            {data.map((item) => {
              return <ArticleCard article={item}></ArticleCard>;
            })}
          </div>
        )}
        {data?.length === 0 && isSuccess && (
          <div>
            <h3>Inga lediga tjänster finns just nu</h3>
          </div>
        )}
      </div>
    </section>
  );
}
