import './spinner.scss';

export function Spinner(props) {
  return (
    <div className="loader-spinner">
      <div className="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </div>
  );
}

export default Spinner;
