import { useState } from 'react';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { useInfiniteQuery, useQuery } from '@tanstack/react-query';

import { debounce } from 'lodash';

import ProductApi from '../api/core/product';
import useNotification from './useNotification';

export default function useSearchProducts() {
  // General
  const [searchParams, setSearchParams] = useSearchParams();
  const dispatch = useNotification();
  const location = useLocation();
  const navigate = useNavigate();

  // Products
  const {
    data,
    isSuccess,
    isLoading,
    isFetchingNextPage,
    isError,
    hasNextPage,
    fetchNextPage,
    refetch,
  } = useInfiniteQuery(
    ['products', searchParams],
    async ({ pageParam = 0 }) => searchProducts(pageParam),
    {
      getNextPageParam: (lastPage, allPages) => {
        const maxPages = lastPage.data.totalPages;
        const nextPage = allPages.length;
        return nextPage < maxPages ? nextPage : undefined;
      },
    },
  );

  // Filters
  const {
    data: filters,
    isLoading: isLoadingFilters,
    isSuccess: isSuccessFilters,
  } = useQuery(['productFilters', 1], () => getFilters());

  // Helpers
  async function searchProducts(pageParam) {
    try {
      return await ProductApi.searchProducts(
        Object.fromEntries([...searchParams]),
        pageParam,
      );
    } catch (error) {
      dispatch({
        type: 'ERROR',
        message: error.response.data.message,
      });
    }
  }

  async function getFilters() {
    try {
      const resp = await ProductApi.getCategories();
      return resp.data;
    } catch (error) {
      dispatch({
        type: 'ERROR',
        message: error.response.data.message,
      });
    }
  }

  const handleProductSearch = debounce((e) => {
    e.preventDefault();

    // if (e.target.name === 'name' && e.target.value.length < 1) {
    //   const newValues = {
    //     ...Object.fromEntries([...searchParams]),
    //   };
    //   delete newValues.name;
    //   let searchQueries = '';

    //   for (const [key, value] of Object.entries(newValues)) {
    //     searchQueries += `${key}=${value}&`;
    //   }

    //   if (!location.pathname.includes('produkter')) {
    //     navigate(`/produkter?${searchQueries.slice(0, -1)}`);
    //   } else {
    //     setSearchParams(newValues);
    //     setTimeout(() => refetch(), 0);
    //   }
    //   return;
    // }

    const newValues = {
      ...Object.fromEntries([...searchParams]),
      [e.target.name]: e.target.value,
    };

    let searchQueries = '';

    for (const [key, value] of Object.entries(newValues)) {
      searchQueries += `${key}=${value}&`;
    }

    if (!location.pathname.includes('produkter')) {
      navigate(`/produkter?${searchQueries.slice(0, -1)}`);
    } else {
      setSearchParams(newValues);
      setTimeout(() => refetch(), 0);
    }
  }, 500);

  return {
    data,
    isSuccess,
    isError,
    isLoading,
    isFetchingNextPage,
    hasNextPage,
    fetchNextPage,
    refetch,
    filters,
    isLoadingFilters,
    isSuccessFilters,
    handleProductSearch,
  };
}
