import http from '../shared/http-common';
import { url } from '../../constants/endpoints';
import authHeader from '../shared/auth-header.js';

export async function login(loginDetail, password) {
  return http
    .post('/auth/signin', {
      loginDetail,
      password,
    })
    .then((response) => {
      if (response.data.accessToken) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    });
}

export function logout() {
  localStorage.removeItem('user');
  window.location.reload(false);
}

export async function signup(data) {
  return http.post('/auth/signup', data).then((response) => {
    if (response.data.accessToken) {
      localStorage.setItem('user', JSON.stringify(response.data));
    }
    return response.data;
  });
}

// GET INITIAL PASSWORD
export function signupExistingCustomer(data) {
  return http.post(url.AUTH_SIGNUP_EXISTING_CUSTOMER, data);
}

export function requestPasswordReset(emailOrphone) {
  return http.put(url.AUTH_REQUEST_RESET_PASSWORD, {
    loginDetail: emailOrphone,
  });
}

export function resetPasswordFromRequest(token, password) {
  return http.put(
    url.AUTH_RESET_PASSWORD_FROM_LINK,
    {
      token: token,
      password: password,
    },
    {
      headers: authHeader(),
    },
  );
}

export function changePassword(id, data) {
  const queryParams = {
    id: id,
  };
  return http.put(url.AUTH_RESET_PASSWORD, data, {
    params: { ...queryParams },
    headers: authHeader(),
  });
}

export function requestCustomerId(data) {
  return http.post(url.AUTH_REQUEST_CUSTOMER_ID, data);
}

export function healthCheck() {
  return http.get(url.HEALTH_CHECK);
}

export default {
  logout,
  login,
  signup,
  signupExistingCustomer,
  requestPasswordReset,
  changePassword,
};
