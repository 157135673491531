import './store.scss';
import React, { useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';

import { useQuery } from '@tanstack/react-query';
import { fetchStoreInfo } from '../../api/contentful/contentful';
import { scrollTo } from '../../utils/utils';

import InfoText from '../../components/info-text/Infotext';
import { Icon, Icons } from '../../components';
import SEO from '../../components/seo/SEO';

export default function Store() {
  const location = useLocation();
  const { data: storeInfo } = useQuery(['storeinfo'], fetchStoreInfo);

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        scrollTo(location.hash);
      }, 100);
    }
  }, [location.hash]);

  return (
    <>
      <SEO title="Butiken - Sportbytarboden | Köp och sälj begagnad sportutrustning i Stockholm" />
      <section className="store-hero hero-text">
        <div className="hero-text_img">
          <h1>Handla enkelt och klimatsmart</h1>
          <p>Det ska vara enkelt att handla både hållbart och sparsamt</p>
          <Link to={'/salj'} className="btn btn-medium btn-primary">
            Läs mer om att sälja
            <Icon icon={Icons.faChevronRight} />
          </Link>
        </div>
      </section>

      <section className="store-opening-hours_wrapper" id="oppettider">
        <div
          className="store-opening-hours_container"
          style={{
            backgroundColor:
              storeInfo?.openhours?.exceptional.length < 1 && 'transparent',
          }}
        >
          <h1>Öppettider</h1>
          <div className="store-opening-hours">
            {storeInfo?.openhours &&
              storeInfo.openhours.normal.map((openHour) => {
                return (
                  <div
                    className="store-opening-hour"
                    key={openHour.daysInterval}
                  >
                    <h2>{openHour.daysInterval}</h2>
                    <p>{openHour.timeInterval}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      {storeInfo?.openhours?.exceptional?.length > 0 && (
        <section className="store-special-hours-container">
          <h2>Avvikande öppettider</h2>
          <div className="store-special-hours">
            {storeInfo.openhours.exceptional.map((openHour) => {
              return (
                <div key={openHour.daysInterval}>
                  <h3>{openHour.daysInterval}</h3>
                  <p>{openHour.timeInterval}</p>
                </div>
              );
            })}
          </div>
        </section>
      )}
      <section className="store-info-section" id="kontakt">
        <div className="store-info-box">
          <div className={`store-info-map overflow-hidden  `}>
            <iframe
              title="test"
              width="100%"
              height="100%"
              referrerpolicy="no-referrer-when-downgrade"
              loading="lazy"
              src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyC_s73vdxG63dbdrVJwk5qfI1dEJc3jVdU&center=59.4389,18.0478&zoom=13&maptype=satellite&q=sportbytarboden`}
            ></iframe>
          </div>
          <div className="info-textbox">
            <h2>Hitta till oss</h2>
            <div className="info-textbox_item">
              <Icon icon={Icons.faLocationDot} size={'lg'}></Icon>

              <div className="info-textbox_item-text">
                <span>{storeInfo?.address}</span>
                <br />
                <span>{storeInfo?.postcode + ', ' + storeInfo?.region}</span>
              </div>
            </div>
            <div className="info-textbox_item">
              <Icon icon={Icons.faPhone} size={'lg'}></Icon>

              <div className="info-textbox_item-text">
                <span>{storeInfo?.phone}</span>
              </div>
            </div>
            <div className="info-textbox_item">
              <Icon icon={Icons.faEnvelope} size={'lg'}></Icon>

              <div className="info-textbox_item-text">
                <span>{storeInfo?.email}</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="py-12">
        <InfoText
          subheading="Våra kunder"
          heading="Över 2000 nöjda kunder i månaden"
          description="Sedan 2009 har över 30.000 kunder använt vår förmedlingstjänst. Under högsäsong betalar vi ut ersättning till över 2.000 kunder i månaden"
        ></InfoText>
      </div>
    </>
  );
}
