import '../articles.scss';
import React, { useEffect } from 'react';
import ArticleCard from '../../../components/cards/article-card/ArticleCard';
import { fetchAllArticles } from '../../../api/contentful/contentful';
import { useQuery } from '@tanstack/react-query';
import useAnalytics from '../../../hooks/analytics';
import SEO from '../../../components/seo/SEO';

export default function ArticlesCollection(props) {
  const { setBreadCrumbs } = props;
  const { eventTrackerGA } = useAnalytics({ category: 'Alla artiklar' });

  // Data
  const { data } = useQuery(['articles'], () => fetchAllArticles());

  useEffect(() => {
    setBreadCrumbs([
      {
        name: 'Nyheter',
        href: '/nyheter',
      },
    ]);
  }, [setBreadCrumbs]);

  return (
    <section className="articles-section flex flex-col gap-12">
      <SEO title="Artiklar och guider - Sportbytarboden | Tips om sportutrustning, skidor, cyklar och mer" />
      <div className="articles-grid">
        {data &&
          data.map((item) => {
            return <ArticleCard article={item}></ArticleCard>;
          })}
      </div>
      {/* {data?.totalPages > 1 && (
  <div className="pagination-container">
    <PaginationMUI
      count={articles.totalPages}
      variant="outlined"
      shape="rounded"
      onChange={handlePaginationChange}
      siblingCount={paginationSiblings}
    ></PaginationMUI>
  </div>
)} */}
      <div className="flex flex-col gap-3">
        <h2 className="text-2xl">Hittar du inte det du söker?</h2>
        <a
          href="mailto:info@sportbytarboden.se"
          target="_blank"
          rel="noreferrer"
          className="btn btn-secondary"
          onClick={() => eventTrackerGA('Kontakta oss')}
        >
          Kontakta oss
        </a>
      </div>
    </section>
  );
}
