import './footer.scss';
import React, { useState, useEffect } from 'react';

import { useNotification } from '../../../hooks/useNotification';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';

import { subscribeToNewsletter } from '../../../api/core/user';

import { Link } from 'react-router-dom';
import { Icon, Icons } from '../../icon/Icon';
import Spinner from '../../loaders/spinner-2/spinner';
import useAnalytics from '../../../hooks/analytics';

export default function Footer() {
  const dispatch = useNotification();
  const { eventTrackerGA } = useAnalytics({ category: 'Footer' });

  // Form
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    mode: 'onBlur',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ email }) => subscribeToNewsletter(email),
    onSuccess: () => {
      dispatch({
        type: 'SUCCESS',
        message: 'Du är nu registrerad!',
      });
    },
  });

  const onSubmit = async (values) => {
    if (isLoading) return;
    mutate({ email: values.email });
    eventTrackerGA('Prenumerera på nyhetsbrev');
  };

  return (
    <footer className="footer">
      <div className="footer_inner">
        <div className="footer_newsletter">
          <h2>Ta del av de senaste nyheterna</h2>
          <p>
            Prenumerera på vårt nyhetsbrev för att vara först med att ta del av
            de senaste nyheterna. Du kan vara lugn, vi spammar inte!
          </p>
          <form
            onSubmit={handleSubmit(onSubmit)}
            className="input-label-container"
          >
            <label htmlFor="email-list_email" className="hidden">
              Epost:
            </label>
            <div className="footer_newsletter-input">
              <input
                type="text"
                name="email"
                id="email-list_email"
                {...register('email', {
                  required: 'Epost kan inte vara tomt.',
                  pattern: {
                    value: /^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i,
                    message: 'Epost adressen är ogiltig!',
                  },
                })}
                placeholder="Epost"
                className={errors?.email ? 'input--error' : ''}
              ></input>
              <button
                className="footer_newsletter-submit btn btn-primary"
                type="submit"
              >
                Prenumerera{isLoading && <Spinner></Spinner>}
              </button>
            </div>
            <small className="text-red-600">
              {errors?.email && errors.email.message}
            </small>
          </form>
        </div>
        <div className="footer_linklist-container">
          <nav className="footer_linklist-innercontainer">
            <h3>Sportbytarboden</h3>
            <ul className="footer_linklist">
              <li className="footer_linklist-item">
                <Link to={'/om'}>Om oss</Link>
              </li>
              <li className="footer_linklist-item">
                <Link to={'/salj'}>Så säljer du</Link>
              </li>
              <li className="footer_linklist-item">
                <Link to={'/faq'}>Vanliga frågor</Link>
              </li>
              <li className="footer_linklist-item">
                <Link to={'/villkor'}>Sälj- och köpvillkor</Link>
              </li>
            </ul>
          </nav>
          <nav className="footer_linklist-innercontainer">
            <h3>Butiken</h3>
            <ul className="footer_linklist">
              <li className="footer_linklist-item">
                <Link to={'/nyheter'}>Nyheter</Link>
              </li>
              <li className="footer_linklist-item">
                <Link to={'/butik#oppettider'}>Öppettider</Link>
              </li>
              <li className="footer_linklist-item">
                <Link to={'/butik#kontakt'}>Kontakta oss</Link>
              </li>
              <li className="footer_linklist-item">
                <Link to={'/nyheter/jobba'}>Jobba hos oss</Link>
              </li>
            </ul>
          </nav>
          <nav className="footer_linklist-innercontainer">
            <h3>Följ oss</h3>
            <ul className="footer_linklist footer_linklist-icons">
              <a
                href="https://www.facebook.com/sportbytarboden"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li className="footer_linklist-item">
                  <Icon icon={Icons.faInstagram}></Icon>
                </li>
              </a>
              <a
                href="https://www.instagram.com/sportbytarboden"
                target="_blank"
                rel="noopener noreferrer"
              >
                <li className="footer_linklist-item ">
                  <Icon icon={Icons.faFacebook}></Icon>
                </li>
              </a>
            </ul>
          </nav>
        </div>
      </div>
    </footer>
  );
}
