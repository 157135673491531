import './admin.scss';
import React, { useState } from 'react';
import { Routes, Route } from 'react-router-dom';

import AdminSidebar from './components/admin-sidebar/AdminSidebar';
import AdminUsers from './pages/users/Users';
import AdminDashboard from './pages/dashboard/Dashboard';
import AdminMobileNav from './components/admin-mobile-nav/AdminMobileNav';
import Analytics from './pages/analytics/Analytics';

export default function Admin() {
  return (
    <div className="admin">
      <AdminSidebar></AdminSidebar>
      <AdminMobileNav></AdminMobileNav>
      <div className="admin-section w-full">
        <Routes>
          <Route path="/" element={<AdminDashboard />} />
          <Route path="/anvandare" element={<AdminUsers />} />
          <Route path="/analytics" element={<Analytics />} />
          <Route path="/*" element={<AdminDashboard />} />
        </Routes>
      </div>
    </div>
  );
}
