import { useState, useEffect, useContext, createContext } from 'react';
import { useLocation } from 'react-router-dom';
import { useJwt } from 'react-jwt';
import AuthAPI from '../api/core/auth.js';
import { useNavigate } from 'react-router-dom';
import { useNotification } from './useNotification.jsx';

export const UserContext = createContext('user');

const useAuth = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useNotification();

  const [user, setUser] = useContext(UserContext);
  const { isExpired } = useJwt(user?.accessToken);

  const [isLoading, setIsLoading] = useState(true);

  // Router change
  useEffect(() => {
    window.scroll(0, 0);

    if (isExpired && user?.accessToken) {
      AuthAPI.logout();
    }
  }, [location.pathname]);

  function login(loginDetail, password) {
    AuthAPI.login(loginDetail, password)
      .then((user) => {
        setUser(user);
        navigateAfterLogin(user);
      })
      .catch((error) => {
        dispatch({
          type: 'ERROR',
          message: error.response.data.message,
        });
      });
  }

  async function signupNewCustomer(data) {
    return AuthAPI.signup(data).then((user) => {
      navigate(`/?signup_success=true`);
      setUser(user);
      return user;
    });
  }

  function navigateAfterLogin(user) {
    if (user && user.roles.includes('ROLE_ADMIN')) navigate('/admin');
    else if (user) navigate('/konto');
  }

  function getCurrentUser() {
    return JSON.parse(localStorage.getItem('user'));
  }

  return { user, isLoading, login, setUser, getCurrentUser, signupNewCustomer };
};

export default useAuth;
