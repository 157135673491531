import React, { forwardRef } from 'react';
import './categorycard.scss';
import { useNavigate } from 'react-router-dom';

const CategoryCard = forwardRef(({ category }, ref) => {
  const navigate = useNavigate();
  const imagePath = require(`../../../assets/imgs/category-imgs/${category.category.toLowerCase()}.jpg`);

  return (
    <div
      style={{
        backgroundImage: `url(${imagePath})`,
      }}
      className={`category-card ${category.category.toLowerCase()}`}
      onClick={() => navigate(`/produkter?category=${category.category}`)}
      ref={ref}
    >
      <h3>{category.category}</h3>
    </div>
  );
});

export default CategoryCard;
