import './homepage.scss';
import React, { useEffect } from 'react';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import { motion } from 'framer-motion';
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

import { useQuery } from '@tanstack/react-query';
import useAnalytics from '../../hooks/analytics';
import { usePopup } from '../../hooks/popup';

import { fetchAllArticles } from '../../api/contentful/contentful';
import ProductApi from '../../api/core/product';

import Hero from '../../components/hero/Hero';
import AlreadyCustomer from './components/already-customer/AlreadyCustomer';
import { Icon, Icons } from '../../components';
import CategoryCard from '../../components/cards/category-card/CategoryCard';
import ArticleCard from '../../components/cards/article-card/ArticleCard';
import SEO from '../../components/seo/SEO';

const variants = {
  initial: {
    opacity: 0,
  },
  inView: {
    opacity: 1,
    transition: { duration: 1 },
  },
};

gsap.registerPlugin(ScrollTrigger);

export default function Homepage() {
  // General
  const { eventTrackerGA } = useAnalytics({ category: 'Homepage' });
  const [searchParams] = useSearchParams();
  const { openPopup } = usePopup();

  // Data
  const { data: articles } = useQuery(['articles'], () => fetchAllArticles());
  const { data: categories } = useQuery(['categories'], () =>
    ProductApi.getCategories().then((res) => res.data),
  );

  useEffect(() => {
    if (searchParams.get('signup_success')) {
      setTimeout(() => {
        openPopup('SignupSuccess');
      }, 100);
    }
  }, [searchParams]);

  return (
    <div className="homepage">
      <SEO title="Sportbytarboden - Köp och sälj begagnad sportutrustning | Skidor, längdskidor, cyklar, hockey, racketsport & mer" />
      <Hero></Hero>
      <section className="category-section">
        <h2 className="text-xl">Populära kategorier</h2>
        <motion.div
          className="categories-container "
          initial={'initial'}
          whileInView={'inView'}
          transition={{ staggerChildren: 0.1 }}
          viewport={{ once: true }}
        >
          {categories &&
            categories.map((category, i) => {
              return (
                <CategoryCard
                  key={category.id}
                  category={category}
                  role="presentation"
                  variants={variants}
                  custom={i}
                ></CategoryCard>
              );
            })}
        </motion.div>
      </section>
      <section className="grid grid-cols-1 sm:grid-cols-2 gap-8 max-w-[1200px] mt-4">
        <div className="rounded-xl overflow-hidden relative order-2 sm:order-1">
          <div className="absolute left-0 top-0 w-full h-full bg-black/15  z-10"></div>
          <img
            src="https://images.unsplash.com/photo-1596473536124-397c469c8506?q=80&w=2940&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className="object-cover w-full h-full"
          />
        </div>
        <div className="flex sm:items-center sm:justify-center sm:order-2">
          <motion.div className="grid gap-4 max-w-md">
            <motion.h1 className="text-gray-500 text-xl lg:text-2xl font-medium">
              Vad är vi?
            </motion.h1>
            <motion.h2 className="text-4xl lg:text-5xl font-bold max-w-md leading-tight">
              En mötesplats för köpare och säljare
            </motion.h2>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
              exit={{ opacity: 0 }}
              className="max-w-prose"
            >
              I vår butik kan du både köpa och sälja sport och
              idrottsutrustning, ny som begagnad. Vi säljer varorna åt dig medan
              du kan luta dig tillbaka och slippa strulet att sälja själv.
            </motion.p>
            <Link
              to={'/produkter'}
              className="btn btn-medium btn-primary"
              onClick={() => eventTrackerGA('See alla produkter - Homepage')}
            >
              Se alla produkter
              <Icon icon={Icons.faChevronRight} />
            </Link>
          </motion.div>
        </div>
      </section>
      <section className="grid grid-cols-1 sm:grid-cols-2 gap-8 max-w-[1200px] mt-4">
        <div className="rounded-xl overflow-hidden relative order-2 sm:order-1">
          <div className="absolute left-0 top-0 w-full h-full bg-black/05  z-10"></div>
          <img
            src="https://images.unsplash.com/photo-1507534192483-69914c0692d7?q=80&w=2874&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
            alt=""
            className="object-cover w-full h-full"
          />
        </div>
        <div className="flex sm:items-center sm:justify-center sm:order-1">
          <motion.div className="grid gap-4 max-w-md">
            <motion.h1 className="text-gray-500 text-xl lg:text-2xl font-medium">
              Vad gör vi?
            </motion.h1>
            <motion.h2 className="text-4xl lg:text-5xl font-bold max-w-md leading-tight">
              Förmedlar sport och idrottsutrustning
            </motion.h2>
            <motion.p
              initial={{ opacity: 0 }}
              animate={{ opacity: 1, transition: { delay: 0.2 } }}
              exit={{ opacity: 0 }}
              className="max-w-prose"
            >
              Med vår låga provison och vårt automatiserade system för
              utbetalningar gör vi det enklare att sälja och köpa sport &
              idrottsutrustning.
            </motion.p>
            <Link
              to={'/salj'}
              className="btn btn-medium btn-primary"
              onClick={() => eventTrackerGA('Läs mer om att sälja - Homepage')}
            >
              Läs mer om att sälja
              <Icon icon={Icons.faChevronRight} />
            </Link>
          </motion.div>
        </div>
      </section>

      <section className="articles-home">
        <h2 className="text-3xl">Nyheter</h2>
        <div className="articles-container">
          {articles &&
            articles.map((article, i) => {
              return <ArticleCard key={i} article={article}></ArticleCard>;
            })}
        </div>
      </section>
      <AlreadyCustomer></AlreadyCustomer>
    </div>
  );
}
