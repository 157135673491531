import './health-check.scss';
import SEO from '../../components/seo/SEO';

import useStore from '../../store/local-persist.store';

export function HealthCheck({ children }) {
  const { healthCheck } = useStore();

  if (healthCheck === 'error') {
    return (
      <div className="w-full flex justify-center items-center min-h-24">
        <SEO title="Anslutningsproblem - Sportbytarboden"></SEO>
        <h1>Något gick fel med vår anslutning. Vänligen försök igen senare.</h1>
      </div>
    );
  }

  return children;
}

export default HealthCheck;
