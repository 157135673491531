import React, { useState } from 'react';

import useAuth from '../../../../hooks/auth';
import { useForm } from 'react-hook-form';
import { useMutation } from '@tanstack/react-query';
import { useNotification } from '../../../../hooks/useNotification';

import { changePassword } from '../../../../api/core/auth';

import { Icon, Icons } from '../../../../components';
import { AiFillEyeInvisible, AiFillEye } from 'react-icons/ai';
import { userRegisterOptions } from '../../../../constants/form';

const ChangePassword = () => {
  // General
  const { user } = useAuth();
  const dispatch = useNotification();

  const [passwordShown, setPasswordShown] = useState(false);
  const [newPasswordShown, setNewPasswordShown] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    mode: 'onBlur',
  });

  const { mutate, isLoading } = useMutation({
    mutationFn: (values) =>
      changePassword(user.customerId, {
        newPassword: values.password,
        oldPassword: values.passwordOld,
      }),
    onSuccess: () => {
      reset();
      dispatch({
        type: 'SUCCESS',
        message: 'Kontouppgifter sparade',
      });
    },
    onError: () => {
      dispatch({
        type: 'ERROR',
        message: 'Kunde inte spara lösenord',
      });
    },
  });

  function onSubmit(data) {
    mutate(data);
  }

  return (
    <form
      className="user-profile_input-container border-t pt-8"
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="user-profile_input-text">
        <h3>Lösenord</h3>
        <p>Byt ditt lösenord här</p>
      </div>
      <div className="user-profile_input-inputs flex flex-col gap-1.5">
        <div className="flex flex-col gap-1 !mb-0">
          <label htmlFor="user-password-old" className="text-sm hidden">
            Ditt nuvarande lösenord:
          </label>
          <div className="relative !mb-0">
            <input
              id="user-password-old"
              type={passwordShown ? 'text' : 'password'}
              name="passwordOld"
              placeholder="Nuvarande lösenord"
              autoComplete="false"
              className={`!w-full ${
                errors?.passwordOld ? '!border-red-500' : ''
              }`}
              {...register('passwordOld', {
                required: 'Fält är obligatoriskt',
              })}
            />
            {!passwordShown ? (
              <AiFillEye
                className={'admin-adduser_password-icon'}
                onClick={() => setPasswordShown(!passwordShown)}
              ></AiFillEye>
            ) : (
              <AiFillEyeInvisible
                className={'admin-adduser_password-icon'}
                onClick={() => setPasswordShown(!passwordShown)}
              ></AiFillEyeInvisible>
            )}
          </div>
          <small className="text-red-600">
            {errors?.passwordOld && errors.passwordOld.message}
          </small>
        </div>
        <div className="flex flex-col gap-1 !mb-0">
          <label htmlFor="user-password-new" className="text-sm hidden">
            Nytt lösenord:
          </label>
          <div className="relative !mb-0">
            <input
              id="user-password-new"
              type={newPasswordShown ? 'text' : 'password'}
              name="password"
              placeholder="Nytt lösenord"
              autoComplete="false"
              className={`!w-full ${
                errors?.passwordNew ? '!border-red-500' : ''
              }`}
              {...register('password', userRegisterOptions.password)}
            />
            {!newPasswordShown ? (
              <AiFillEye
                className={'admin-adduser_password-icon'}
                onClick={() => setNewPasswordShown(!newPasswordShown)}
              ></AiFillEye>
            ) : (
              <AiFillEyeInvisible
                className={'admin-adduser_password-icon'}
                onClick={() => setNewPasswordShown(!newPasswordShown)}
              ></AiFillEyeInvisible>
            )}
          </div>
          <small className="text-red-600">
            {errors?.passwordNew && errors.passwordNew.message}
          </small>
        </div>
        {/* <div className="flex flex-col gap-1 !mb-0">
          <label htmlFor="user-password-new-repeat" className="text-sm hidden">
            Upprepa nytt lösenord:
          </label>
          <div className="relative sm:w-[80%] !mb-0">
            <input
              id="user-password-new-repeat"
              type={passwordShown ? 'text' : 'password'}
              name="passwordNewRepeat"
              placeholder="Upprepa nytt lösenord"
              autoComplete="false"
              className={`!w-full ${
                errors?.passwordNewRepeat ? '!border-red-500' : ''
              }`}
              {...register(
                'passwordNewRepeat',
                userRegisterOptions.passwordRepeat,
              )}
            />
            {!passwordShown ? (
              <AiFillEye
                className={'admin-adduser_password-icon'}
                onClick={() => setPasswordShown(!passwordShown)}
              ></AiFillEye>
            ) : (
              <AiFillEyeInvisible
                className={'admin-adduser_password-icon'}
                onClick={() => setPasswordShown(!passwordShown)}
              ></AiFillEyeInvisible>
            )}
          </div>
          <small className="text-red-600">
            {errors?.passwordNewRepeat && errors.passwordNewRepeat.message}
          </small>
        </div> */}

        <input
          type="submit"
          className="btn btn--small btn-secondary btn--full !rounded"
          value={'Uppdatera lösenord'}
        ></input>
      </div>
    </form>
  );
};

export default ChangePassword;
