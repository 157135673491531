import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { useMutation } from '@tanstack/react-query';
import useAnalytics from '../../../hooks/analytics';
import { useNotification } from '../../../hooks/useNotification';

import { signupExistingCustomer } from '../../../api/core/auth';
import SEO from '../../../components/seo/SEO';

const inputs = [
  {
    id: 1,
    name: 'signupDetail',
    type: 'text',
    placeholder: 'Kundnummer',
    label: 'Kundnummer:',
    required: true,
  },
];

export default function GetPassword() {
  const navigate = useNavigate();
  const dispatch = useNotification();
  const { eventTrackerGA } = useAnalytics({
    category: 'Request password reset',
  });

  const [values, setValues] = useState({});

  const { mutate, isLoading, isSuccess } = useMutation({
    mutationFn: (emailOrPhone) => signupExistingCustomer(emailOrPhone),
    onSuccess: () => {
      setTimeout(() => navigate('/logga-in'), 30000);
    },
    onError: (error) => {
      dispatch({
        type: 'ERROR',
        message: error.response.data.message,
      });
    },
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    mutate(values);
  };

  const handleOnChange = (e) => {
    const value = e.target.value;

    setValues({ ...values, [e.target.name]: value });
  };

  return (
    <div className="login">
      <SEO title="Få tillgång till ditt konto - Sportbytarboden | För befintliga kunder i butiken" />
      <section className="login-card">
        <div className="login-card_header flex flex-col items-center gap-2">
          <h1 className="">Begär åtkomst till ditt konto</h1>
          <small className="max-w-[90%] text-center">
            Ange ditt kundnummer så skickar vi en länk till din e-post som är
            kopplat till kontot.
          </small>
        </div>
        {!isSuccess ? (
          <form className="login-card_form gap-1.5" onSubmit={handleSubmit}>
            {inputs.map((input) => {
              return (
                <input
                  className="mb-1"
                  key={input.id}
                  {...input}
                  value={values[input.name]}
                  onChange={handleOnChange}
                />
              );
            })}
            <input
              type="submit"
              className="btn btn-secondary btn--full"
              value={isLoading ? 'Laddar...' : 'Begär åtkomst'}
            />
            <button
              onClick={() => navigate(-1)}
              className="btn btn-functional btn--full"
              type="button"
            >
              Tillbaka
            </button>
            <p className="text-sm mb-6 mt-2">
              Har du glömt ditt kundnummer?{' '}
              <Link
                to={'/logga-in/kundnummer'}
                onClick={() =>
                  eventTrackerGA(
                    'Create account already customer - Reset password',
                    'Loginpage',
                  )
                }
                className="underline"
              >
                Klicka här för att efterfråga det
              </Link>
            </p>
          </form>
        ) : (
          <div className="p-4 grid gap-6 w-full">
            <h3 className="text-center">
              Om kontot har en epost kopplat till sig skickar vi en länk.
            </h3>
            <div className="grid gap-1.5 w-full">
              <a href="/logga-in" className="btn btn-secondary btn--full">
                Logga in
              </a>
              <button
                onClick={() => navigate(-1)}
                className="btn btn-functional btn--full"
                type="button"
              >
                Tillbaka
              </button>
            </div>
          </div>
        )}
      </section>
    </div>
  );
}
